module.exports = {

// ZOOM CONFIGURATIONS

/**
 * Array containing all the zoom levels for the automatic mode before adjustment to screen size.
 * This default will be automatically modified according to screen size in init.js.
 *
 * @type {Array}  The array should contain an object with information about all the automatic zoom levels.
 *
 *                @viewZoom {number} viewZoom  Integer number of zoom level which can be accessed by zooming back and forth in the Zoomable User Interface.
 *                                             Values should be: 0, 1, 2, ... n         (i. e. start with zero, not one)
 *                @property {number} internal  Actual size of the zoomed grid for the current viewZoom. Used to calculate resolutions for each viewZoom.
 *                                             Unlike gridZoom in openLayers, this may also contain fractional numbers.
 *                @property {meta} meta        Number of the meta zoom level. Used to display the right tiles and labels.
 */
  autoZoom_default: [
    {viewZoom: 0,  internalZoom: 2.2, meta: 1},
    {viewZoom: 1,  internalZoom: 2.3, meta: 2},
    {viewZoom: 2,  internalZoom: 2.5, meta: 2},
    {viewZoom: 3,  internalZoom: 2.7, meta: 2},
    {viewZoom: 4,  internalZoom: 3.1, meta: 3},
    {viewZoom: 5,  internalZoom: 4.2, meta: 3},
    {viewZoom: 6,  internalZoom: 5.0, meta: 4},
    {viewZoom: 7,  internalZoom: 5.5, meta: 4},
    {viewZoom: 8,  internalZoom: 6.2, meta: 4},
    {viewZoom: 9,  internalZoom: 6.9, meta: 5},
    {viewZoom: 10, internalZoom: 7.4, meta: 5},
    {viewZoom: 11, internalZoom: 8.0, meta: 5},
    {viewZoom: 12, internalZoom: 8.6, meta: 6},
    {viewZoom: 13, internalZoom: 9.2, meta: 6},
    {viewZoom: 14, internalZoom: 10.0, meta: 6},
    {viewZoom: 15, internalZoom: 11.0, meta: 7},
    {viewZoom: 16, internalZoom: 12.0, meta: 7},
    {viewZoom: 17, internalZoom: 13.0, meta: 7}
  ],

  /**
   * Array containing adjusted metaZoom values for the minified auto zoom mode, overwriting values from the default auto zoom mode.
   * The minified autozoom mode is an automatic zoom with with EARLIER toggles between the taxonomic levels. (Suitable to look at larger taxa, e. g. insects).
   *
   * Only viewZoom and new metaZoom need to be given, the internal zoom will be adapted from the normal autoZoom.
   * The easiest way to build the array is to copy autoZoom_default, remove internalZoom attributes and change metaZoom as required. (Rows without changes to meta might be removed).
   *
   * @type {Array}  The array should contain an object with information about the automatic zoom levels used to overwrite default zoom.
   *
   *                @viewZoom {number} viewZoom  Integer number of zoom level which can be accessed by zooming back and forth in the Zoomable User Interface.
   *                                             Values should be: 0, 1, 2, ... n         (i. e. start with zero, not one)
   *                                             All values might be given explicitly (0, 1, 2, ... n ) for clarity or .

   *                @property {meta} meta        Number of the meta zoom level. Used to display the right tiles and labels.
   */
  autoZoom_leftShift: [
    {viewZoom: 0,  meta: 1},
    {viewZoom: 1,  meta: 2},
    {viewZoom: 2,  meta: 2},
    {viewZoom: 3,  meta: 3},
    {viewZoom: 4,  meta: 3},
    {viewZoom: 5,  meta: 4},
    {viewZoom: 6,  meta: 4},
    {viewZoom: 7,  meta: 4},
    {viewZoom: 8,  meta: 5},
    {viewZoom: 9,  meta: 5},
    {viewZoom: 10, meta: 5},
    {viewZoom: 11, meta: 6},
    {viewZoom: 12, meta: 6},
    {viewZoom: 13, meta: 6},
    {viewZoom: 14,  meta: 7},
    {viewZoom: 15,  meta: 7},
    {viewZoom: 16,  meta: 7},
    {viewZoom: 17,  meta: 7}
  ],

  /**
   * Array containing adjusted metaZoom values for the maxified auto zoom mode, overwriting values from the default auto zoom mode.
   * The maxified autozoom mode is an automatic zoom with with LATER toggles between the taxonomic levels. (Suitable to look at small taxa, e. g. monotypic genera).
   *
   * See autoZoom_min for a detailed description.
   */
  autoZoom_rightShift: [
    {viewZoom: 1,  meta: 1},
    {viewZoom: 2,  meta: 2},
    {viewZoom: 3,  meta: 2},
    {viewZoom: 4,  meta: 2},
    {viewZoom: 5,  meta: 2},
    {viewZoom: 6,  meta: 3},
    {viewZoom: 7,  meta: 3},
    {viewZoom: 8,  meta: 4},
    {viewZoom: 9,  meta: 4},
    {viewZoom: 10, meta: 5},
    {viewZoom: 11, meta: 5},
    {viewZoom: 12, meta: 5},
    {viewZoom: 13, meta: 6},
    {viewZoom: 14, meta: 6},
    {viewZoom: 15, meta: 7},
    {viewZoom: 16, meta: 7},
    {viewZoom: 17, meta: 7}
  ],

  /**
   * Array containing zoom range for left-shifted (minified) auto zoom mode.
   *
   * @type {Array}  The array should contain an object with information about the start range for each taxonomic (meta zoom) level.
   *
   *                @meta {number} meta               Numerically encoded taxonomic rank of the detail zoom layer.
   *                @property {number} min_view_zoom  ViewZoom at which the corresponding layer starts.
   *                                                  Supported formats: "auto", "auto+1", "3"
   *                                                                      numbers referring to viewZoom level.
   *                                                                      "auto"  referring to the viewZoom level of the normal (middle) automatic zoom mode.
   *                                                                      First value should be 0 (or 'auto' -> evaluated to zero).
   *
   *   The zoom ranges are derived from min_viewZoom only but must not overlap.
   *   To skip meta zoom levels, just delete their lines from the object.
   */
  autoZoom_leftShift2: [
    { meta: 1, min_viewZoom: '0' },
    { meta: 2, min_viewZoom: 'auto' },
    { meta: 3, min_viewZoom: 'auto-1' },
    { meta: 4, min_viewZoom: 'auto-1' },
    { meta: 5, min_viewZoom: 'auto-2' },
    { meta: 6, min_viewZoom: 'auto-2' },
    { meta: 7, min_viewZoom: 'auto-2' },
  ],


  /**
   * Array containing zoom range for right-shifted (maxified) auto zoom mode.
   *
   * @type {Array}  The array should contain an object with information about the start range for each taxonomic (meta zoom) level.
   *
   *                @meta {number} meta               Numerically encoded taxonomic rank of the detail zoom layer.
   *                @property {number} min_view_zoom  ViewZoom at which the corresponding layer starts.
   *                                                  Supported formats: "auto", "auto+1", "3"
   *                                                                      numbers referring to viewZoom level.
   *                                                                      "auto"  referring to the viewZoom level of the normal (middle) automatic zoom mode.
   *                                                                       First value should be 0 (or 'auto' -> evaluated to zero).
   *
   *   The zoom ranges are derived from min_viewZoom only but must not overlap.
   *   To skip meta zoom levels, just delete their lines from the object.
   */
    autoZoom_rightShift2: [
    { meta: 1, min_viewZoom: '0'   },
    { meta: 2, min_viewZoom: 'auto+2' },
    { meta: 3, min_viewZoom: 'auto+2' },
    { meta: 4, min_viewZoom: 'auto+2' },
    { meta: 5, min_viewZoom: 'auto+2' },
    { meta: 6, min_viewZoom: 'auto' },
    { meta: 7, min_viewZoom: 'auto' },
  ],


  /**
   * Array containing zoom range for overview & detail zoom layers (i. e. zooming in or out without change of taxonomic levels)
   *
   * @type {Array}  The array should contain an object with information about the zoom ranges for all the detail zoom modes (differentiated by their detail zoom).
   *
   *                @meta {number} meta_y             Numerically encoded taxonomic rank of the detail zoom layer.
   *                @property {number} min_view_zoom  Minimum viewZoom level to which the layer can be zoomed out for getting an overview.
   *                                                  Supported formats: "auto", "auto+1", "3"
   *                                                                      numbers referring to viewZoom level.
   *                                                                      "auto"  referring to the smallest viewZoom level (i. e. showing a fullscreen view of the entire layer)
   *                @property {number} max_view_zoom  Maximum viewZoom level to which the layer can be zoomed in for viewing details.
   *                                                  Supported formats: "auto", "auto+1", "3"
   *                                                                      numbers referring to viewZoom level.
   *                                                                      "auto" referring to the highest viewZoom in autoZoom where this layer (given by meta) occurs.
   */
  detailZoom_default: [
    { meta: 1, min_viewZoom: 'auto', max_viewZoom: 'auto+4' },
    { meta: 2, min_viewZoom: 'auto', max_viewZoom: 'auto+2' },
    { meta: 3, min_viewZoom: 'auto', max_viewZoom: 'auto+2' },
    { meta: 4, min_viewZoom: 'auto', max_viewZoom: 'auto+2' },
    { meta: 5, min_viewZoom: 'auto', max_viewZoom: 'auto+2' },
    { meta: 6, min_viewZoom: 'auto', max_viewZoom: 'auto' },
    { meta: 7, min_viewZoom: 'auto', max_viewZoom: 'auto' },
  ],

/** getestet u. a. mit:
  detailZoom_default: [
    { meta: 3, min_viewZoom: 'auto-3', max_viewZoom: 'auto+2' },
    { meta: 4, min_viewZoom: 'auto+5', max_viewZoom: '4' },
    { meta: 5, min_viewZoom: '+2', max_viewZoom: 'auto-2' },
    { meta: 6, min_viewZoom: 'auto', max_viewZoom: 'auto-1' },
  ],
**/
  /** Entwurf einer Schaltflächenlogik
  buttons: [
    { meta: 7, logic: [
          { viewZoom: 9, buttons: 'species_normal'},
          { viewZoom: 11, buttons: 'species_detail' }
       ]
    },
    { meta: 6, logic: [
          { viewZoom: 9, buttons: 'genus_normal'},
          { viewZoom: 11, buttons: 'genus_detail' }
       ]
    },
  ],
  **/

//  maxZoom: 16,


/* URL LOOK-UPs */

/**
 * Mapping the relation between meta zoom, tile paths and properties of UI elements
 * @property {meta} meta        Number of the meta zoom level.
 * @property {meta} path        Subdirectory path to tiles (both raster and vector).
 * @property {meta} btn_name    Name of UI elements associated with meta zoom level.
 */
  taxRank: [
    { meta: 1,   path: 'domain',           btn_name: 'btn_domain',    btn_colour: 'rgb(196,0,49)'},
    { meta: 2,   path: 'phylum',           btn_name: 'btn_phylum',    btn_colour: 'rgb(138,0,129)'},
    { meta: 3,   path: 'class',            btn_name: 'btn_class',     btn_colour: 'rgb(0,72,180)'},
    { meta: 4,   path: 'order',            btn_name: 'btn_order',     btn_colour: 'rgb(0,148,125)' },
    { meta: 5,   path: 'family',           btn_name: 'btn_family',    btn_colour: 'rgb(123,226,0)'},
    { meta: 5.2, path: 'subfamily',        btn_name: '',              btn_colour: ''},
    { meta: 6,   path: 'genus',            btn_name: 'btn_genus',     btn_colour: 'rgb(255,185,0)'},
    { meta: 7,   path: 'species',          btn_name: 'btn_species',   btn_colour: 'rgb(245,225,204)'}
  ],


  /**
   * Mapping the relation between meta zoom and number
   * @property {meta} meta        Number of the meta zoom level.
   * @property {meta} path        Subdirectory path to tiles (raster only).
   */
  perspectives_default : [
    { name: "def",     path: 'Standard' },
    { name: "scheme",  path: 'leer' }
  ],

   perspectives_extra: [
     { name: "range",  path: 'Karte' },
     { name: "test",   path: 'test' }
   ],

   perspectives_species: [
     { name: "Blatt_dor",   path: "Blatt_dor"   },
     { name: "Blatt_ven",   path: "Blatt_ven"   },
     { name: "BlattBasis",  path: "BlattBasis"  },
     { name: "Flo_fro",     path: "Flo_fro"     },
     { name: "Flo_lat",     path: "Flo_lat"     },
     { name: "FloFem_fro",  path: "FloFem_fro"  },
     { name: "FloFem_lat",  path: "FloFem_lat"  },
     { name: "FloMale_fro", path: "FloMale_fro" },
     { name: "FloMale_lat", path: "FloMale_lat" },
     { name: "Fruit_cut",   path: "Fruit_cut"   },
     { name: "Fruit_fro",   path: "Fruit_fro"   },
     { name: "Fruit_lat",   path: "Fruit_lat"   },
     { name: "Gynoecium",   path: "Gynoecium"   },
     { name: "Haare",       path: "Haare"       },
     { name: "Habitat",     path: "Habitat"     },
     { name: "Habitus",     path: "Habitus"     },
     { name: "Holz",        path: "Holz"        },
     { name: "InfFem_fro",  path: "InfFem_fro"  },
     { name: "InfFem_lat",  path: "InfFem_lat"  },
     { name: "Inflo_fro",   path: "Inflo_fro"   },
     { name: "Inflo_lat",   path: "Inflo_lat"   },
     { name: "Inflo_ven",   path: "Inflo_ven"   },
     { name: "Infrukt",     path: "Infrukt"     },
     { name: "Keimling",    path: "Keimling"    },
     { name: "Knospe",      path: "Knospe"      },
     { name: "Milchsaft",   path: "Milchsaft"   },
     { name: "Nektarien",   path: "Nektarien"   },
     { name: "Nutzung",     path: "Nutzung"     },
     { name: "Pollen",      path: "Pollen"      },
     { name: "Pollinat",    path: "Pollinat"    },
     { name: "Samen",       path: "Samen"       },
     { name: "SproSta",     path: "SproSta"     },
     { name: "Teilhab",     path: "Teilhab"     },
     { name: "Wurzel",      path: "Wurzel"      },
     { name: "Zweig",       path: "Zweig"       }
   ],



/* z-layer to use for vector tiles to retrieve species ID on click on raster tiles */
  lookup: [
    { meta: 1, z: 3 },
    { meta: 2, z: 4 },
    { meta: 3, z: 5 },
    { meta: 4, z: 5 },
    { meta: 5, z: 7 },
    { meta: 6, z: 9 },
    { meta: 7, z: 11 }
  ],


/* global error tile to use for empty raster tiles */
errorTile_px: "error/empty2.png",





/* START, FULLSCREEN & SUBSCREEN ZOOM CONFIGURATION */
  /*
    @type {number}  If there are zoom levels to show below fullscreen size, the size of the view window will be reduced
                    for each step according to this value. This will be ignored if fullScreenMeta is equal to the smallest taxonomic rank available.
   */
  subScreenStep: 0.2,


  /* @type {number}
      Smallest and largest internal zoom level to take into account for screen dependent zoom level adjustments.
      Tile layers that may occur in this range should be provided for all corresponding gridZoom levels.
   */
  smallest_fullscreen: 0.5,
  largest_fullscreen: 5.4,    // fullScreenZoom 4K UHD (3840×2160) -> 4.03



  /* AUTOMATIC CONTROL */
  /*
    @type {meta}  Taxonomic level at which to start the map view.
                  If this is the same as fullScreenMeta, the map will start at fullscreen size.

                  (No effect if forceView is used).
   */
  startMeta: 2,


  /*
    @type {meta}  Taxonomic level to show at fullscreen size.
                  If this is not the smallest available taxonomic rank, the map view will automatically adjust so that smaller zoom levels are displayed smaller than fullscreen size.

                  (No effect if forceView is used).
   */
  fullScreenMeta: 2,



  /* CUSTOM CONTROL */
  /*
  @type {boolean}  Whether or not to activate force view for more fine-grained control over the map view.
                   If this is active, startZoom, fullScreenZoom  & subScreenlevels will not be calculated from the meta zoom levels (i. e. startMeta and fullScreenMeta will be ignored).

                   Instead the given view Zoom levels will be used. The meta zoom for the viewZoom level can be seen from autoZoom_default.
                   This can be used, for instance, if the map is to start in the meta zoom level that is used in fullscreen, but not in fullscreen.
   */
  forceView: 1,

  /**
   * If forceView is active (set to 1), the viewZoom levels given here will be used for the map view.
   * This needs to be designed with autoZoom_default in mind and is probably somewhat more complex than automatic control.
   *
   * @type {Object}   contains viewZoom levels
   *                  (no effect if forceView is not used)
   */
  forceViewOptions: {
      startZoom: 2,             // View zoom at which to start the map view.
      fullScreenZoom: 2,        // View zoom to show at fullscreen size.
  },



  /* @type {colour}
     Colour for inactive area
   */
  btn_colour_inactive: '#AAAAAA',



/** RASTER TILE SERVER PROPERTIES
    You had better not change this unless you really know what you are doing. (Unless you change the modification of the tile server, these values should not be modified.)
**/

/* @type {number}
    Size of a single quadratic raster tile at the tile server. Standard size on map tile servers is 256 (=256x256px tiles).
 */
  tileSize: 256,

  /*
    @type {Array}   Extent of the map grid which is used to load tiles from the server. See openlayers.docx for more information.
                    Typically looks like [0, -y, x, 0], where x, y are in map coordinate units and |x| > |y|.

                    If you want to change the maximum extent of the view, change contentExtent instead.
                    NOTE: Not tested for maps, where |x| < |y|. Might require change in fullscreenZoom calculation.
   */
  gridExtent: [0, -7200, 12800, 0],


  /*
    @type {Array}  Resolutions for the grid layer of the map. See openlayers.docx for more information.
                   Use 'auto' for automatic configuration based on gridExtent. (This should be changed only for testing purposes if you change the modification of the tile server.)

                   If you want to change custom zoom steps (viewResolutions), change internalZoom in autozoom_default instead, which is used to calculate viewResolutions.
   */
  gridResolutions: 'auto',
  // gridResolutions_neu2: [50, 25, 12.5, 6.25, 3.125, 1.5625, 0.78125, 0.390625, 0.1953125, 0.09765625, 0.048828125, 0.024414063, 0.012207031, 0.006103516, 0.003051758],
  // gridResolutions_neu: [64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625, 0.03125, 0.015625, 0.0078125, 0.00390625],
  // gridResolutions_alt: [8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625, 0.03125, 0.015625, 0.0078125, 0.00390625],


/** MAP PROPERTIES
**/
  /*
    @type {Array}   Actual extent of the map in map coordinates. This may actually be changed if there's some black space at the loaded gridExtent as retrieved from the tile server.
   */
  contentExtent: [0, -7200, 12800, 0],
  // lifegate2021.2:  contentExtent: [0, -7200, 12800, 0],
  // Lifegate2021:    contentExtent: [0, -16384, 16384, 0],
  // Lifegate2020:    contentExtent: [0, -910, 1880, 0],

/**
 * @type {Number}   Header height in pixels.
 *                  Upper part of the user interface that does not contain the zoomable map (needed for calculation of fullscreen zoom).
 */
  headerHeight: 80,

  /**
   * @type {Number}   Toolbar width in pixels.
   *                  Left (or right) part of the user interface that does not contain the zoomable map (needed for calculation of fullscreen zoom).
   *                  Should be 0 if transparent and just overlaying (instead of covering) the zoomable map.
   */
  toolbarWidth: 0,


/**
 * [Preload of labels in pixel around viewport]
 * @type {Number}
 */
  buffer: 600,

/**
 * Maximum cache size for features before garbage collection is performed.
 * @type {Number}
 */
  cache: 10,


/**
 *  Properties to calculate the size of vector labels (i. e. the taxa) as a function of zoom level.
 *    f(x, iZoom) = coeff * (2^exp2*iZoom) * x
 *  With x being the size of a text label invariant of the zoom level (should be provided in a database of vector labels).
 */
  fsize: {
    exp2: 0.96,     // change between different zoom levels
    coeff: 0.03    // linear scaling factor
  },

  /*
  Minimum font size in pixels  for labels to be displayed.
   */
  fsize_min: 7,

  /*
  Minimum font size in pixels with higher tolerance (e.g. to show also Archaea at domain level)
   */
  fsize_min_tolerantLevel: 1,

  /*
  Threshold at which to use higher tolerance (e.g. highLevel = 2: use higher tolerance at meta 1, 2)
   */
  tolerantLevel: 1,


/*
 * Preferred fonts for text labels.
 */
  fstyle: 'Source Sans Pro, Calibri, sans-serif',

  /**
   * Whether the map should reload when the window is resized
   */
  resize: 1,

  /**
   * Whether debug functions should be available
   */
  debug: 1,

}
