// Funktionen aus anderen Javascript-Dateien
var def = require('./def');
var pre  = require('./pre');


export function urlReplace(x, y, z, url) {
  var modifiedUrl = url.replace('{x}', x).replace('{y}', y).replace('{z}', z);
  return modifiedUrl;
}


export function urlDisassemble(url, tile_server) {
  // Remove tile_server from URL if present
  let localPath;
  if(tile_server) {
    localPath = url.split(tile_server)[1];
  } else {
    localPath = url;
  }

  // Get file extension
  // ~› https://stackoverflow.com/a/29825619
  let localPath_extArr  = localPath.split(".");         // Split at .
  let file_ext          = localPath_extArr.pop();       // get last occurence (".jpg")
      localPath         = localPath_extArr.join(".");   // get all other occurences (my.file.name)


  // Disassemble URL
    // URL-Format: TILE_SERVER/meta/perspective/z/x/y.jpg
    //       z. B. tiles_px/class/Karte/2/1/3.jpg
  let localPathComponents = localPath.split("/");

  let metaPath    = localPathComponents[0];
  let perspective = localPathComponents[1];
  let z           = localPathComponents[2];
  let x           = localPathComponents[3];
  let y           = localPathComponents[4];

  return {
    tile_server:  tile_server,
    meta:         metaPath,
    perspective:  perspective,
    z:            z,
    x:            x,
    y:            y,
    file_ext:     file_ext
  };

}

export function findErrorUrl(urlObject) {
  // Read properties from Url Object
  let tile_server     = urlObject["tile_server"];
  let meta            = urlObject["meta"];
  let old_perspective = urlObject["perspective"];
  let z               = urlObject["z"];
  let x               = urlObject["x"];
  let y               = urlObject["y"];
  let file_ext        = urlObject["file_ext"];


  let new_perspective = "leer";   // Fallback URL: empty tiles
  let errorUrl;

  // Case I: Tile not available in selected perspective (-> fallback tile from schematic layer)
  if(old_perspective != new_perspective) {
    errorUrl = `${tile_server}${meta}/${new_perspective}/${z}/${x}/${y}.${file_ext}`;

  // Case II: Tile not available globally (-> empty tile)
  } else {
    let globalErrorUrl = def.errorTile_px;
    errorUrl = `${tile_server}${globalErrorUrl}`;
  }

  return errorUrl;
}

/** gridZoom (gerundet) bzw. internalZoom (ungerundet)
    != viewZoom, die Zoomstufe im aktuellen Kontext **/
export function getGridZoomForResolution(resolution, mode) {
  var res0 = pre.gridResolutions[0]
  var gridZoom = Math.log(res0/resolution) / Math.log(2);
    if(mode != "unrounded") { gridZoom = parseInt(gridZoom+0.5); }   // +0.5, weil gerundet wird

  return gridZoom;
}

// interner order gridZoom
export function getResolutionForZoom(zoom) {
  var b = pre.resExponent;
  var resolution = Math.pow(2, b - zoom);

  return resolution;
}

export function getMetaForViewZoom(viewZoom, logic) {
      viewZoom = Math.round(viewZoom);                                                     // z. B. 1
  var metaZoom = (logic.find(element => element['viewZoom'] == viewZoom))['meta'];
  var metaName = (def.taxRank.find(element => element['meta'] == metaZoom))['path'];         //       'class'
  var metaButton = (def.taxRank.find(element => element['meta'] == metaZoom))['btn_name'];   //       'btn_class'
  var metaBtnCol = (def.taxRank.find(element => element['meta'] == metaZoom))['btn_colour']; //       '#AA0000'
  var meta = { 'level': metaZoom, 'path': metaName, 'btn_name': metaButton, 'btn_colour': metaBtnCol };  //  { level: 3, path: 'class', btn_name: 'btn_class', btn_colour: '#AA0000' }

  return meta;
}


export function getViewZoomForMeta(metaZoom, logic) {
      metaZoom       = Math.round(metaZoom);                                                               // z. B. 3
  var metaName       = (def.taxRank.find(element => element['meta'] == metaZoom))['path'];                 //       'class'
  var viewZoom_min   = (logic.find(element => element['meta'] == metaZoom))['viewZoom'];                   //       1
  var viewZoom_max   = (logic.slice().reverse().find(element => element['meta'] == metaZoom))['viewZoom']; //       2
  var viewZoom_range = { 'range_min': viewZoom_min, 'range_max': viewZoom_max, 'path': metaName }          //       { range_min: 1, range_max: 2, path: 'class' }

  return viewZoom_range;  // hier werden diskrete viewZoom-Ebenen ohne Überschneidung zurückgegeben
}

/**
 * [getFullAutoZoomForMeta description]
 * @param  {[type]} metaZoom      [description]
 * @param  {[type]} logic_auto    [description]
 * @param  {[type]} logic_autoMin [description]
 * @param  {[type]} logic_autoMax [description]
 * @return {[type]}               [description]
 */
export function getFullAutoZoomForMeta(metaZoom, logic_auto, logic_autoMin, logic_autoMax) {
      metaZoom           = Math.round(metaZoom);
  var range_auto         = getViewZoomForMeta(metaZoom, logic_auto);
  var range_autoMin      = getViewZoomForMeta(metaZoom, logic_autoMin);
  var range_autoMax      = getViewZoomForMeta(metaZoom, logic_autoMax);

  var range_totalMin = Math.min(range_auto["range_min"], range_autoMin["range_min"], range_autoMax["range_min"]);
  var range_totalMax = Math.max(range_auto["range_max"], range_autoMin["range_max"], range_autoMax["range_max"]);
  var path           = range_auto['path'];

  // maximalen Zoombereich finden (einschließlich aller drei Autozoomstufen)
  var viewZoom_range = { 'range_min': range_totalMin, 'range_max': range_totalMax, 'path': path }


  // jedem Viewzoom einen Autozoom zuordnen
  // Priorität: auto > auto_min > auto_max
  let viewZoom_levels = [];
  for(let v = range_totalMin; v <= range_totalMax; v++) {

    let current_logic;
    if(v >= range_auto["range_min"] && v <= range_auto["range_max"]) {
      current_logic = "auto";
    } else if(v >= range_autoMin["range_min"] && v <= range_autoMin["range_max"]) {
      current_logic = "auto_min";
    } else if(v >= range_autoMax["range_min"] && v <= range_autoMax["range_max"]) {
      current_logic = "auto_max";
    }

    viewZoom_levels.push({ 'viewZoom': v, 'logic': current_logic });
  }

  var fullAutoZoom = { 'range': viewZoom_range,
                       'levels': viewZoom_levels }

  return fullAutoZoom;
}



export function getInternalZoomForMeta(metaZoom, logic) {
      metaZoom           = Math.round(metaZoom);                                                                                // z. B. 3
  var metaName           = (def.taxRank.find(element => element['meta'] == metaZoom))['path'];                                  // 'class'

  // alt: var internalZoom_min   = (logic.find(element => element['meta'] == metaZoom))['internalZoom'];

  // interne Zoombereiche (------|-----|-----)
  var minIndex           = logic.findIndex(element => element['meta'] == metaZoom);
  var internalZoom_min   = logic[minIndex]['internalZoom'];                                                                       // 3.03
  var maxIndex           = (logic.length-1) - logic.slice().reverse().findIndex(element => element['meta'] == metaZoom);         // Index des ersten von hinten (reverse()) passenden metaZooms; Index von vorn gezählt ((length-1)-reverse())
  var internalZoom_max   = maxIndex+1 <= (logic.length-1) ? logic[maxIndex+1]['internalZoom'] : logic[maxIndex]['internalZoom']; // 4.5 (Beginn der neuen Zoomebene oder aber Maximum des ViewZooms generell) - MINIMUM DES NÄCHSTEN META-ZOOMS (interner Umschalter zwischen Meta)

  // Zoombereiche des Sliders (---)----)-----)
  var slider_min        = minIndex-1 >= 0 ? logic[minIndex-1]['internalZoom'] : logic[minIndex]['internalZoom'];
  var slider_max        = logic[maxIndex]['internalZoom'];                                                                       // 3.8 (letzter direkt zugänglicher Zoom des Metazooms) - MAXIMUM DIESES META-ZOOMS (Anzeige des Zoomsliders)

  var internalZoom_range = { 'range_min': internalZoom_min, 'range_max': internalZoom_max, 'slider_min': slider_min, 'slider_max': slider_max, 'path': metaName }                    // { range_min: 3.03, range_max: 4.5, path: 'class' }


  return internalZoom_range; // hier werden kontinuierliche internalZoom-Werte übergeben
                             // d. h. min[meta] = max[meta+1]
                             // d. h. geschlossenes/offenes Intervall [range_min, range_max); außer wenn maximaler viewZoom erreicht, dann [range_min, range_max]
}

export function getColourForMeta(metaZoom) {
  var colour;
    if (metaZoom == undefined) {
      colour = def.btn_colour_inactive;
    } else {
      colour = (def.taxRank.find(element => element['meta'] == metaZoom))['btn_colour'];
    }
  return colour;
}

export function getPathForMeta(metaZoom) {
  var path = (def.taxRank.find(element => element['meta'] == metaZoom))['path'];
  return path;
}

export function getMetaForPath(path) {
  var meta = (def.taxRank.find(element => element['path'] == path.trim()))['meta'];
  return meta;
}

export function getLookupForMeta(metaZoom) {
  var lookup = def.lookup.find(element => element['meta'] == metaZoom)['z'];
  return lookup;
}

export function getPathForPerspective(perspective_name) {
  // alle Perspektiven finden
  var p_default = def.perspectives_default;
  var p_extra   = def.perspectives_extra;
  var p_species = def.perspectives_species;
  var p_all = [...p_default, ...p_extra, ...p_species];

  // Pfad ausgeben
  var perspective_path = p_all.find(element => element['name'] == perspective_name)['path'];
  return perspective_path;
}
/**
 * Wert aus einem Array von Objekten finden. Abstrakte Hilfsfunktion sollte nur benutzt werden, wenn keine explizierte zur Verfügung steht.
 *
 * @param  arrayOfObjects  Array von Objekten, z. B.:
 *      [
 *        {viewZoom: 0,  internalZoom: 2.2, meta: 1},
 *        {viewZoom: 1,  internalZoom: 2.3, meta: 1}
 *      ]
 *
 * @param  x_name, x_value, y_name  Suchparameter
 *      name        value
 *  x   'meta'      2
 *  y   'internal'  ???
 *
 *  Zurückgegeben wird der erste passende Wert oder "undefined", wenn es keinen passenden Wert gibt.
 *  Für das Beispiel oben: (objekt, "viewZoom", 0, "meta") -> 1; (objekt, "meta", 1, "viewZoom") -> 0.
 */
export function getYForX(arrayOfObjects, x_name, x_value, y_name) {             //  (objekt, "viewZoom", 0, "meta")
  var foundLine = arrayOfObjects.find(element => element[x_name] == x_value);   //  {viewZoom: 0,  internalZoom: 2.2, meta: 1}
    if(foundLine === undefined) { return undefined; }

  var y_value   = foundLine[y_name];                                            //  1
    return y_value;
}


/**
 * Einfache Funktion zur Abschätzung der Textbreite (z. B. um Offsets ausgehend von einer Schriftgröße=Höhe zu berechnen)
 * @param  {String} input [eingegebener Text]
 * @return {Float}        [Textbreite als Vielfaches der Texthöhe]
 */
export function textWidth(input) {

  var small_char = (input.match(/i|I|l/g)||[]).length;
  var large_char = (input.match(/m|M|w|W/g)||[]).length;

  const text_height_width_ratio = 0.5;
  var length_modified = input.length - small_char * 0.7 + large_char *0.5;
  var width = length_modified*text_height_width_ratio;

  return width;
}


/**
 * Gibt die erste Schnittmenge eines längeren Strings (string2) und eines kürzeren Strings (string1) aus.
 * @param  {[string]} string1 Kürzere Zeichenfolge
 * @param  {[string]} string2 Längere Zeichenfolge, die kürzere enthält.
 * @return {[string]}         Schnittmenge
 *
 * Verwendet für Vollname und Kurzname, um längeren Namensbestandteil von Vollname für Formatierung zu finden:
 *    string 1: Galanthus nivalis
 *    string 2: Galanthus nivalis var. hortensis
 *    Ausgabe:  var. hortensis
 */
export function stringIntersection(string1, string2) {
    if(!string2) { return ""; }

    let intersection;
    let start_index  = string2.indexOf(string1);      // _G_alanthus nivalis  var. hortensis
    let middle_index = string1.length;                //   Galanthus nivalis_ var. hortensis
    let end_index    = string2.length;                //   Galanthus nivalis  var. hortensis_

    if(start_index !== -1) {
      intersection = string2.substring(middle_index-start_index, end_index);  // var. hortensis
      return intersection;
    } else {
      return "";
    }
  }



/**
 * Aus einem Array mit mehreren Logiken eine Logik durch ihren Namen finden
 * @param  {[type]} name   Name der Logik
 * @param  {[type]} logics Logik-Array
 * @return {[type]}        Einzellogik (mit Optionen)
 */
export function findLogic(name, logics) {
  var logicWithOptions = logics.find(element => element['name'] == name)
  return logicWithOptions;
}

/**
 * Namen einer Logik gewinnen. "Umkehrfunktion" zu findLogic.
 * @param  {[logicWithoutOptions]}      logicWithoutOptions      Logik mit unbekanntem Namen
 * @param  {[object]}                   logics                   Mögliche Logiken mit Angabe eines Namens.
 * @return {[type]}                     Name der Logik
 */
export function findLogicName(logicWithoutOptions, logics) {
  for(let i = 0, len=logics.length; i < len; i++) {
    let current_logic = logics[i];
    if(JSON.stringify(logicWithoutOptions) === JSON.stringify(current_logic["logic"])) {     // -> https://stackoverflow.com/a/1144249
      return current_logic["name"];
    }
  }
  return undefined;
}


/**
 * Find all possible meta zoom levels from a given logic.
 * @param  {[type]} logic Logik (ohne Optionen)
 * @return {[type]}       [description]
 */
export function getUniqueMetas(logic) {
  var uniqueMetas = logic.map(element => element.meta)                                          // mögliche MetaZoom-Werte finden
                               .filter((value, index, self) => self.indexOf(value) === index);  // distinct values from object key
                                                                                                // -> https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript

  return uniqueMetas;
}


/**
 * Find all possible meta zoom paths from a given logic.
 * @param  {[type]} logic Logik (ohne Optionen)
 * @return {[type]}       Pfade
 */
export function getUniquePaths(logic) {
  var uniquePaths = [];
  var uniqueMetas = getUniqueMetas(logic);

  uniqueMetas.forEach(function (curr_meta) {
    var curr_path = getPathForMeta(curr_meta);
    uniquePaths.push(curr_path);
  });

  return uniquePaths;
}



/**
 * Find maximum global meta zoom level
 * @return {[Number]} meta zoom level
 */
export function maxGlobalMeta() {
    let meta = def.taxRank;
        meta.sort((a, b) => (a.meta < b.meta) ? 1 : -1);

    let highestMeta = meta[0]["meta"];
    return highestMeta;
}

/**
 * Find maximum global meta zoom level
 * @return {[Number]} meta zoom level
 */
export function minGlobalMeta() {
    let meta = def.taxRank;
        meta.sort((a, b) => (a.meta > b.meta) ? 1 : -1);

    let lowestMeta = meta[0]["meta"];
    return lowestMeta;
}

/**
 * Calculate font size in pixels.
 * @param  {double} fsize_db Font size from database (zoom-independent)
 * @param  {double} zoom     Current unrounded gridZoom ("internal Zoom")
 * @return {double}          Fontsize in pixels (zoom-dependent)
 */
export function fsizeToPx(fsize_db, zoom) {
  var fsize_px = def.fsize['coeff'] * Math.pow(2, def.fsize['exp2']*zoom) * fsize_db;
  return fsize_px;
}

/**
 * Calculate font size in database units (relative).
 * @param  {double} fsize_db Font size in pixels (zoom-dependent).
 * @param  {double} zoom     Current unrounded gridZoom ("internal Zoom")
 * @return {double}          Fontsize in database units (zoom-independent).
 */
export function fsizeToDB(fsize_px, zoom) {
  var fsize_db = fsize_px / (def.fsize['coeff'] * Math.pow(2, def.fsize['exp2']*zoom));
  return fsize_db;
}

/**
 * Round to nearest multiple of a selected number.
 * EXAMPLES: util.round(4.321, 0.01) -> 4.32
 *           util.round(4.321, 0.1)  -> 4.3
 *           util.round(4.321, 1)    -> 4
 *           util.round(4.321, 2)    -> 4
 *           util.round(4.321, 5)    -> 5
 *           util.round(-4.321, 5)   -> -5
 *           util.round(4.321, 10)   -> 0
 *           util.round(4.321, 0)    -> 4.321
 * @param  {double} value      Number, that should be rounded.
 * @param  {double} factor     Number to whose multiples should be rounded to (use 0 to go without rounding).
 * @return {[type]} [description]
 */
export function round(value, factor) {
  if(factor == 0) return value;   // use 0 to go without rounding
  var rounded = Math.round(value * (1/factor)) * factor;
  return rounded;
}


/** Check Array for duplicates **/
// -> https://stackoverflow.com/a/7376645
export function arrayHasDuplicates(array) {
   return (new Set(array)).size !== array.length;
}


/* Check if array is numerically sorted */
export function arrayIsSorted(array) {
  // -> https://stackoverflow.com/a/52106727
  return array.slice(1).every((item, i) => array[i] <= item)
}

/**
 * Sleep function: use like this: await sleep(duration)
 * from https://stackoverflow.com/a/39914235
 * @param  {[type]} duration   in milliseconds
 * @return {[type]}            returns promis
 */
export function sleep(duration) {
  return new Promise(resolve => setTimeout(resolve, duration));
}


/**
 * Checks whether the user's OS is Android
 * @return {Boolean}    0...is not Android, 1... is Android
 */
export function isAndroid() {
  // -> https://stackoverflow.com/questions/47798279/jquery-mobile-how-to-detect-if-mobile-virtual-keyboard-is-opened
  // see here also to check for other mobile operating systems

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if(/android/i.test(userAgent)) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Checks whether the user's OS is iOS
 * @return {Boolean}    0...is not iOS, 1... is iOS
 */
export function isIOS() {
  // -> http://stackoverflow.com/a/9039885/177710
  // -> https://stackoverflow.com/questions/47798279/jquery-mobile-how-to-detect-if-mobile-virtual-keyboard-is-opened
  // see here also to check for other mobile operating systems

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 1;
  } else {
    return 0;
  }
}
