var def = require('./def');
var util = require('./util');
var link = require('./link');
var main = require('./map');
  var map        = main.map;
  var zoomLogics = main.zoomLogics;
var $ = require("jquery");

let data = require('./tour_data.json');

/*
let tourPoints = ["#@Tracheophyta",
                  "#@Unikonta",
                  "#@Entognatha"];

let titles = ["Tracheophyta", "Unikonta", "Entognatha"];
*/

function findAllTours(lang) {
  // console.log("Versuche alle Touren zu finden ...");

  if(!lang) {
    lang = "en";
  }

  let tourList = [];
  for (let i = 0; i < data.length; i++) {
    current_tour = data[i];
    current_tour_obj = {
        name:        current_tour["tour_info"]["name_" + lang],
        value:       current_tour["tour_id"],
        description: current_tour["tour_info"]["info_"+lang],
        autoplay:    current_tour["tour_info"]["autoplay"]
      };

    tourList.push(current_tour_obj);
  }

  return tourList;
}


var running_tour;           // [GLOBAL]: Zustand der aktuellen Tour
var global_autoplay = 0;    // [GLOBAL]: aktuelles Autoplay oder nicht
async function takeTour(tour_id, lang) {
  // console.log("take tour...");

      running_tour = getTour(data, tour_id);
  let local_autoplay = running_tour["autoplay"];

  // let location = await link.findAddress(tourPoint);
  let locations = await findLocations(running_tour.urls);      // z. B.   ["#@Tracheophyta", "#@Unikonta",  "#@Entognatha"];
      running_tour["locations"] = locations;                   // z. B.   [{ "hash": { "path": "phylum", "viewZoom": 2, "coord_x": 1597.61, "coord_y": -2776.1 },  "options": {}  }, ... ]
      running_tour["max_steps"] = locations.length;            // z. B.   3
      running_tour["current_step"] = -1;
      running_tour["lang"]         = lang;

  // console.log("running_tour", running_tour);

  if(global_autoplay || local_autoplay) {   // Unterscheidung nur hier von Relevanz, in startAutoplay wird global_autoplay = 1 gesetzt
    startAutoplay();                        // gesamte Tour
  } else {
    // console.log("Go to first step...")
    nextStep();                         // erster Schritt
  }
  // flyTo(location);
}




function getTour(json, tour_id) {
  // console.log("json", json);
  let selected_tour = json.find(tour => tour.tour_id == tour_id);
  let urls          = selected_tour.tour_points.map(point => point.url);
  let titles        = selected_tour.tour_points.map(point => point.title);
  let text_de       = selected_tour.tour_points.map(point => point.text_de);
  let text_en       = selected_tour.tour_points.map(point => point.text_en);
  let autoplay      = selected_tour.tour_info.autoplay;

  return {
            urls: urls,
            titles: titles,
            text_de: text_de,
            text_en: text_en,
            autoplay: autoplay
          }
}

/**
 * Aus einem Array von URLs ein Objekt mit Ortsangaben und Zoomoptionen erstellen
 * @param  {[type]} urls       Array mit URLs (Taxa oder Koordinaten)     ["#@Tracheophyta", "#@Unikonta", "#@Entognatha"];
 * @return {[type]} locations Array mit Hash und Optionen                 [{ "hash": { "path": "phylum", "viewZoom": 2, "coord_x": 1597.61, "coord_y": -2776.1 },  "options": {}  }, ... ]
 **/
async function findLocations(urls) {
  return new Promise(async function(resolve, reject) {
    // console.log("findLocations");
    let locations = new Array(urls.length);
    for (let i = 0; i < urls.length; i++) {
      locations[i] = await link.findAddress(urls[i]);
    }
    resolve(locations);
  });
}



async function flyTo(location) {
  return new Promise(async function(resolve, reject) {
    let view   = map.getView();

    let coords = location["hash"];
    let zoom   = coords["viewZoom"];
    let x      = coords["coord_x"];
    let y      = coords["coord_y"];

    async function callback(complete) {
      resolve(complete);
    }

    let done = 0;
      view.animate(
        {
          center: [x, y],
          duration: 1000
        },
        {
          zoom: zoom,
          duration: 1500
        }, callback
      );
  })
}

function showInfo(title, text) {
  $('#tourPointTitle').text(title);
  $('#tourPointText').text(text);
}

function fadeInfo(mode) {
  if(mode=="in") {
    $('#tourBox').fadeIn();
  } else {
    $('#tourBox').fadeOut();
  }
}

function fadeControl(mode) {
  if(mode=="in") {
    $('#tourController').fadeIn();
  } else {
    $('#tourController').fadeOut();
  }
}

function close() {
  let running_tour = [];
  fadeInfo("out");
  showInfo("", "");
  fadeControl("out");
  fadeInfo("out");
}

function open() {
  unendTour();
  if(! $('#tourController').is(":visible")) {
    fadeControl("in");
  }
}


async function nextStep() {
  stopAutoplay();   // falls Autoplay aktiv, diesen stoppen

  // Attribute der Tour
  let lang         = running_tour["lang"];
  let max_steps    = running_tour["max_steps"];
  let locations    = running_tour["locations"];
  let titles       = running_tour["titles"];
  let text         = running_tour["text_"+lang];


  let current_step = running_tour["current_step"] + 1;
  if(current_step < max_steps) {
    running_tour["current_step"] = current_step;

    // Aktuellen Schritt der Tour ermitteln
    let current_location      = locations[current_step];
    let current_title         = titles[current_step];
    let current_text          = text[current_step];


    if(current_step > 0) {
      fadeInfo("out");
    }

    updateButtons(current_step, max_steps);
      if(!stepMatcher(current_step)) return;    // abbrechen, falls durch Nutzerinteraktion bereits weiternavigiert
    await flyTo(current_location);
    await util.sleep(800);
      if(!stepMatcher(current_step)) return;
    showInfo(current_title, current_text);
      if(!stepMatcher(current_step)) return;
    fadeInfo("in");

    if(current_step == max_steps - 1) {
      endTour();
    }

  }

}


async function prevStep() {
  stopAutoplay();   // falls Autoplay aktiv, diesen stoppen
  unendTour();

  // Attribute der Tour
  let lang         = running_tour["lang"];
  let max_steps    = running_tour["max_steps"];
  let locations    = running_tour["locations"];
  let titles       = running_tour["titles"];
  let text         = running_tour["text_"+lang];

  let current_step = running_tour["current_step"] - 1;
  if(current_step >= 0) {
    running_tour["current_step"] = current_step;

    // Aktuellen Schritt der Tour ermitteln
    let current_location      = locations[current_step];
    let current_title         = titles[current_step];
    let current_text          = text[current_step];


    fadeInfo("out");
    updateButtons(current_step, max_steps);
      if(!stepMatcher(current_step)) return;    // abbrechen, falls durch Nutzerinteraktion bereits weiternavigiert
    await flyTo(current_location);
    await util.sleep(800);
      if(!stepMatcher(current_step)) return;
    showInfo(current_title, current_text);
      if(!stepMatcher(current_step)) return;
    fadeInfo("in");

    }
}



function toggleAutoplay() {
  let button = "#tour_autoplay";
  let active = $(button).hasClass("active");
  if(active) {              // deaktivieren
    stopAutoplay();
  } else {                  // aktivieren
    startAutoplay();
  }

}

function stopAutoplay() {
  let button = "#tour_autoplay";
  global_autoplay = 0;
  deactivateButton(button);
  $("#autoplay_pause").hide();
  $("#autoplay_play").show();

}

function startAutoplay() {
  let button = "#tour_autoplay";
  global_autoplay = 1;
  activateButton(button);
  $("#autoplay_play").hide();
  $("#autoplay_pause").show();
  autoplay();
}





/**
 * Tour-Autoplay
 */
async function autoplay() {
  global_autoplay = 1;
  if(!lang) {
    lang = "en";
  }

  // console.log("autoplay steps ...");
  // console.log("running_tour", running_tour);

  let lang      = running_tour["lang"];
  let locations = running_tour["locations"];
  let titles    = running_tour["titles"];
  let text      = running_tour["text_"+lang];
  let start     = running_tour["current_step"] + 1;
  let end       = running_tour["max_steps"];

  for (let i = start; i < end; i++) {
      if(!global_autoplay) return;      // abbrechen, falls durch Nutzerinteraktion weiternavigiert
    // console.log("i", i, locations[i]);
    updateButtons(i, end);
    await flyTo(locations[i]);
    await util.sleep(800);
    showInfo(titles[i], text[i]);
    fadeInfo("in");
    await util.sleep(5000);
      if(!global_autoplay) return;
      if(i < end - 1) {
        fadeInfo("out");
      } else {
        endTour();
      }
    await util.sleep(500);
    running_tour["current_step"]++;
  }

  // -> https://openlayers.org/en/latest/examples/animation.html
  /**
  let index = -1;
  function next(more) {
    if (more) {
      ++index;
      if (index < locations.length) {
        console.log("index", index);
        const delay = index === 0 ? 0 : 750;
          setTimeout(function () {
          await flyTo(locations[index], next);
        }, delay);
      } else {
        alert('Tour complete');
      }
    } else {
      alert('Tour cancelled');
    }
  }
  next(true);
  **/
}

function updateButtons(current_step, max_steps) {
  // Zähler
  let shown_step = current_step + 1;                      // fange bei 1 anstatt bei 0 an
   $('#tour_counter').text(`${shown_step}/${max_steps}`);

   // Aktivierung und Deaktivierung von Buttons
   if(current_step < 1) {
     deactivateButton('#tour_prev');
   } else {
     activateButton('#tour_prev')
   }
   if(current_step >= max_steps-1) {
     deactivateButton('#tour_next');
   } else {
     activateButton('#tour_next');
   }



}

function stepMatcher(current_step) {
  if(running_tour["current_step"] == current_step) {
    return 1;
  } else {
    return 0;
  }
}


function activateButton(buttonIdentifier) {
  if(!$(buttonIdentifier).hasClass("active")) {
    $(buttonIdentifier).addClass("active");
  }
}


function deactivateButton(buttonIdentifier) {
  if($(buttonIdentifier).hasClass("active")) {
    $(buttonIdentifier).removeClass("active");
  }
}


function endTour() {
  $("#tour_autoplay").hide();
  $("#tour_end").css('display', 'inline-block');
}

function unendTour() {
  $("#tour_autoplay").show();
  $("#tour_end").hide();
}


  module.exports = {
    findAllTours:       findAllTours,
    takeTour:           takeTour,
    nextStep:           nextStep,
    prevStep:           prevStep,
    toggleAutoplay:     toggleAutoplay,
    close:              close,
    open:               open
  }
