// Debug
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import {TileDebug} from 'ol/source';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import { createXYZ } from "ol/tilegrid";
import Projection from 'ol/proj/Projection';

// Funktionen aus anderen Javascript-Dateien
var def = require('./def');
var pre = require('./pre');


function sleep(milliseconds) {
  // zum Testen, von: https://heise.de/tipps-tricks/JavaScript-Sleep-und-setTimeout-4060840.html
 return new Promise(resolve => setTimeout(resolve, milliseconds));
}

var projection = new Projection({
  code: 'simple',
  units: 'pixels',
  extent: def.gridExtent,
});

var tilegrid = new TileGrid({
  extent: def.gridExtent,
  resolutions: pre.gridResolutions,
  tileSize: 256
});

/**
 * gibt die aktuelle Mausposition an, wird über Control.extend(defaultControls) in die Karte eingebunden (Objekt map)
 * @type {MousePosition}
 */
// https://openlayers.org/en/latest/examples/mouse-position.html
var mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(2),
  projection: 'simple',
  // comment the following two lines to have the mouse position
  // be placed within the map.
  className: 'custom-mouse-position',
  target: document.getElementById('mouse-position'),
  undefinedHTML: '&nbsp;'
});

/**
 * TileDebug-Ebene, ein Raster mit den XYZ-Adressen der einzelnen Kacheln
 * @type {TileLayer}
 */
var tiledebug = new TileLayer({
    source: new TileDebug({
      // projection: 'simple',
      tileGrid: tilegrid,
      zDirection: 0
    }),
    extent: def.gridExtent,
    zIndex: 2,
    opacity: 0.8
});

module.exports = {
  mousePositionControl: mousePositionControl,
  tiledebug: tiledebug,
  sleep: sleep
}
