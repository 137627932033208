// HASH-FUNKTIONEN

// [TODO]: Verlinkung nach view="..." gestatten (~› dafür nach 'view=' suchen)

var def = require('./def');
var util = require('./util');
var main = require('./map');
  var map        = main.map;
  var zoomLogics = main.zoomLogics;


/** LINKS ZU TAXA **/
// erstmaliges Aufrufen des Hashs
function initHash() {
  var fragment = window.location.hash;
  // console.log("INIT_HASH", fragment);
  processUrlFragment("hashinit", fragment);
}

// späteres Aufrufen des Hashs
$(window).on('hashchange', function(event) {
  var fragment = window.location.hash;
  // console.log("HASH_CHANGE", fragment);
  processUrlFragment(event, fragment);
});





/**
 * AUTOMATISCHE ÄNDERUNG DES HASHES IN DER URL-LEISTE
 * @param  {[type]} event
 * @param  {[type]} at
 * @param  {[type]} viewZoom
 * @param  {[type]} x
 * @param  {[type]} y
 * @param  {[type]} zoom_mode
 * @param  {[type]} view_mode
 */
/** [TODO]: Funktioniert, aber Vergleich mit Vorgänger ist eher umständlich; Code-Refactoring wenn Zeit ist wäre nicht schlecht **/
function updateHash(event, at, viewZoom, x, y, zoom_mode, view_mode) {
  // console.log("UPDATEHASH", event, at, viewZoom, x, y);
  // console.log("Aktueller Kartenzustand:", map.get("logic"), map.get("meta"));

  // neuer Zustand
  var path          =   map.get("meta")['path'];
  var viewZoom      =   map.getView().getZoom();
  var coord_x, coord_y;

  // Parameter verarbeiten
      // Koordinaten
      if(x === undefined) {
        coord_x = map.getView().getCenter()[0];
      } else {
        coord_x = Number.parseFloat(x);
      }
      if(y === undefined) {
        coord_y = - map.getView().getCenter()[1];
      } else {
        coord_y = - Number.parseFloat(y);
      }

      coord_x = coord_x < 0 ? 0 : coord_x;
      coord_y = coord_y < 0 ? 0 : coord_y;

    // @
    var url_at;
    if(at === undefined || at == "button") {
      url_at = "";
    } else {
      url_at = at;
    }

    // zoom_mode
    let logic = map.get("logic");
    let logic_name = util.findLogicName(logic, zoomLogics)
    if(zoom_mode === undefined) {
      if(logic_name.includes("detail")) {
        zoom_mode = "fixed";
      } else if(logic_name == "auto_min") {
        zoom_mode = "auto_min";
      } else if(logic_name == "auto_max") {
        zoom_mode = "auto_max";
      } else {
        zoom_mode = "auto";
      }
    }

    // view_mode
    let perspective = map.get("view");
    if(view_mode === undefined) {
      view_mode = "photo";
    }

  // Neue Parameter
  var url_hash       = "#" + path + "-" + viewZoom + "-" + coord_x.toFixed(2) + "-" + coord_y.toFixed(2);
  var url_hash_short = viewZoom + "-" + coord_x.toFixed(2) + "-" + coord_y.toFixed(2);
  var url_string     = url_hash + url_at;
  var url_modes      = ",zoom=" + zoom_mode;          // [TODO]: view
  // var url_modes      = ",zoom=" + zoom_mode + ",view=" + view_mode;

  // Mit Vorgänger vergleichen, um @ zu erhalten
  var prev_url          = window.location.hash;
  var prev_atPosition   = prev_url.indexOf("@");
  var prev_modePosition = prev_url.indexOf(",");
      prev_modePosition = prev_modePosition < 0 ? prev_url.length : prev_modePosition;                 // wenn keine Modi angegeben, setze Endposition (für prev_at später) ans Ende der URL
  var prev_hash         = prev_atPosition   < 0 ? prev_url : prev_url.substring(0, prev_atPosition);
  var prev_at           = prev_atPosition   < 0 ? ""       : prev_url.substring(prev_atPosition, prev_modePosition);

  var prev_hash_short  = prev_url;
  var firstHyphen      = prev_hash.indexOf("-");
  if(firstHyphen > 0) {
    prev_hash_short  = prev_hash.substring(firstHyphen+1, prev_hash.length);    // entferne path (phylum, etc.); Grund: wird in Zwischenstufe neu gesetzt
  }                                                                             // [TODO]: das ist eher ein Hotfix, besser wäre es wenn Neusetzen des Paths vermieden werden könnte


  // console.log("url_hash", url_hash);
  // console.log("url_string", url_string);
  // console.log("url_modes ", url_modes);
  // console.log("prev_hash", prev_hash);
  // console.log("prev_hash_short", prev_hash_short);
  // console.log("prev_at", prev_at);

  if(!at && prev_at && prev_hash_short == url_hash_short) {   // erhalte das @, falls fehlend
    silentAt(prev_at);
  } else {
    if(at) {
      silentAt(url_at);
    } else {
      url_string = url_string + url_modes;
      silentHashChange(url_string);
    }
  }

}

// ändert die URL, Rückgängig-Funktion geht zu vorheriger Internetseite (nicht zu vorheriger Koordinate)
function silentHashChange(url_string) {
  history.replaceState(null, null, document.location.pathname + url_string);
}

// ändert die URL, sodass nur @ und Modi übrig bleiben
// Ergebnis z. B.  ...#@Mollusca,zoom=auto_max,view=photo
function silentAt(at_string) {
  let curr_logic   = map.get("logic");
  let logic_name   = util.findLogicName(curr_logic, zoomLogics);

  let view_string = "photo";
  let mode_string;
  if(logic_name.includes("detail")) {
    mode_string = "fixed";
  } else {
    mode_string = logic_name;      // "auto", "auto_min", "auto_max"
  }

  let url_string   = `#@${at_string},zoom=${mode_string}`;
  // let url_string   = `#@${at_string},zoom=${mode_string},view=${view_string}`;   // [TODO]

  history.replaceState(null, null, document.location.pathname + url_string);
}


/**
 * URL-FRAGMENT VERARBEITEN: Anhand einer Adresse zu einer bestimmten Kartenstelle gehen
 * Mögliche Formate:    #phylum-3-6219.83-3684.48,zoom=auto_min,view=photo  (auf Koordinate verlinken)
 *                      #@Tracheophyta,zoom=auto_min,view=photo,level=3     (auf Taxon verlinken)
 *                      #phylum-3-6219.83-3684.48@...!                      (Verlinkung auf Koordinate erzwingen)
 * @param  {[type]} event    Auslösendes Event (rein informativ, [TODO]: kann später auch entfernt werden)
 * @param  {[type]} fragment URL-Fragment
 */
async function processUrlFragment(event, fragment) {
  // leerer Hash
  if(fragment == "") {
    return;
  }

  let hashElements = extractHash(event, fragment);

  let hashObject = hashElements[0];
  let at         = hashElements[1];
  let excl       = hashElements[2];

  processHash(event, hashObject, at, excl)
}


/**
 * ENTSCHEIDUNG, OB ZU KOORDINATE GEGANGEN WERDEN SOLL ODER OB ZUVOR NOCH TAXON GESUCHT WERDEN MUSS
 * @param  {[type]} event      [description]
 * @param  {[type]} hashObject [description]
 * @param  {[type]} at         [description]
 * @param  {[type]} excl       [description]
 * @return {[type]}            [description]
 */
async function processHash(event, hashObject, at, excl) {
// console.log("processHash", event, hashObject, at, excl);
let hashOptions = hashObject["options"];

  // Verarbeite @
  if(at) {
    if(excl != "!") {                                     // @...!
      var fragment = await findTaxon(at, hashOptions);    // Suche nach Taxon (bei direkter URL-Eingabe)
      // console.log("resolve(fragment):", fragment);
      processUrlFragment("foundLink", fragment);          // Erneuter Versuch
    } else {
      goToHash(event, hashObject, at);    // verwende Koordinaten (z. B. bei Klick auf Suchergebnisse)
    }
  } else {
      // console.log("processHash -> goToHash", event, hashObject);
      goToHash(event, hashObject, "");
  }
}



/*****/
async function findAddress(fragment) {
  return new Promise(async function(resolve, reject) {

  // leerer Hash
  if(fragment == "") {
    return;
  }

  // Fall 1: Adresse bereits bekannt
  let hashElements = extractHash("findAddress", fragment);
  let hashObject = hashElements[0];
  let at         = hashElements[1];
  let excl       = hashElements[2];

  if(!at || excl == "!") {
     resolve(hashObject);
  }

  // Fall 2: Adresse muss aus @Taxon abgerufen werden
  let fragment2, hashOptions;
      hashOptions = hashObject["options"];
                                                          // @...!
      fragment2    = await findTaxon(at, hashOptions);    // Suche nach Taxon (bei direkter URL-Eingabe)
      hashElements = extractHash("findAddress", fragment2);
      hashObject   = hashElements[0];
      at           = hashElements[1];
      excl         = hashElements[2];

      // alte Optionen wieder hinzufügen
      hashObject["options"] = hashOptions;

      resolve(hashObject);
  });
}






/**
 * INFORMATIONEN AUS URL-FRAGMENT GEWINNEN UND IN JAVASCRIPT-OBJEKT UMWANDELN
 * @param  {[type]} event       rein informativ
 * @param  {[type]} fragment    Format: #phylum-3-6219.83-3684.48@Tracheophyta,zoom=auto_min,view=photo,level=3
 * @return {[type]}             [description]
 */
//
function extractHash(event, fragment) {
  // console.log("PROCESSURLFRAGMENT", event, fragment);

  var url_string = fragment;
  var hash = "";
  var at   = "";
  var excl = "";

  // Finde "!"
  if(fragment.indexOf("!") >= 0) {
    excl = "!";
    url_string = url_string.replace('!', '');
  }

  // Finde URL-Bestandteile
  var atPosition    = url_string.indexOf("@");
  var paramPosition = url_string.indexOf(",");
  var length        = url_string.length;


  var positions = [
    { name: "hash",   start: 0             },
    { name: "at",     start: atPosition    },
    { name: "params", start: paramPosition }
  ]

  // Array nach Startpositionen sortieren
  positions.sort((a, b) => parseInt(a.start) - parseInt(b.start));

  // @ und ,-Parameter extrahieren
  var coordPart = "";
  var modePart  = "";
  for(let i=0, len=positions.length; i < len; i++) {
    let current_start = positions[i]["start"];
    let current_name  = positions[i]["name"];

    if(current_start < 0) { continue; }
    let current_end;      // undefined = gehe bis Ende
    if(i < len - 1) {
      current_end = positions[i+1]["start"];
    }

  if(current_name == "hash") {
      coordPart  = url_string.substring(current_start, current_end);
    } else if(current_name == "at") {
      at         = url_string.substring(current_start, current_end);
    } else if(current_name == "params") {
      modePart   = url_string.substring(current_start, current_end);
    }
  }
  // console.log("coordPart, modePart, at", coordPart, modePart, at);

  /*
  if(atPosition > 0) {                                          // nur #
      coordPart = url_string.substring(0, atPosition);
      at        = url_string.substring(atPosition);
  } else {
      hash = url_string;
  }
  */

  // Extrahiere Information aus Hash
  // var hashParts =   hash.split(',');

  /*
  if(hashParts.length == 1) {
    coordPart = hash;
  } else {
    coordPart = hashParts[0]
    modeParts = hashParts.slice(1);     // alle außer erstes Element
  }
  **/

  // Optionen: Zoom-Modus und Perspektive
  var modeParts = modePart.split(",");
  var zoom_mode, view_mode, level_mode;
  // console.log("extractHash / modeParts", modeParts);
  for(let i = 0, len=modeParts.length; i < len; i++) {
    let modeSplit = modeParts[i].split('=');
    // console.log("extractHash / modeSplit", modeSplit);
    if(modeSplit.length != 2) { continue; }

    let mode_param = modeSplit[0];
    let mode_value = modeSplit[1];

    if(mode_param == "zoom" || mode_param == "z") {
        zoom_mode = mode_value;
    } else if(mode_param == "view" || mode_param == "v") {
        view_mode = mode_value;
    } else if(mode_param == "level" || mode_param == "l") {
        level_mode = parseInt(mode_value);
    }
  }

  // Koordinaten
  var coordParts =   coordPart.replace('#', '').split('-');
  var path       =   coordParts[0].trim();
  var viewZoom   =   parseInt(coordParts[1]);
  var coord_x    =   parseFloat(coordParts[2]);
  var coord_y    = - parseFloat(coordParts[3]);

  // Allgemeine Tests
  if(!at) {
    if(coordParts.length != 4) {
      console.log("Invalid coordinates in URL Fragment (format should be #path-z-x-y,params). Check length of URL fragment.");
      return;
    }

    if(!Number.isInteger(viewZoom)) {
      console.log("Invalid coordinates in URL Fragment (format should be #path-z-x-y,params). View zoom z should be an integer.");
      return;
    }

    if(Number.isNaN(coord_x) || Number.isNaN(coord_y)) {
      console.log("Invalid coordinates in URL fragment (format should be #path-z-x-y,params). x, y coodinates need to be numbers.");
      return;
    }
  }

  if(modeParts.length > 4) {
    console.log("modeParts:", modeParts);
    console.log("Invalid parameters in URL fragment (format should be #coords,zoom=...,view=...,level=...). No more than three parameters might be given.");
    return;
  }

  // Objekt erstellen
  let hashObject = {
    hash: {
      path: path,
      viewZoom: viewZoom,
      coord_x: coord_x,
      coord_y: coord_y,
    },
    options: {
      zoom_mode: zoom_mode,
      view_mode: view_mode,
      viewZoom:  level_mode
    }
  }

  // console.log("extractHash / hashObject, at, excl", hashObject, at, excl);

  return [hashObject, at, excl];
}








/**
 * AUSGEHEND VON ADRESSE (hashobject, at) ZU KARTENSTELLE NAVIGIEREN
 * @param  {[type]} event      [description]
 * @param  {[type]} hashObject Navigierbare Adresse
 * @param  {[type]} at         Hier nur verwendet, um festzustellen, ob navigation animiert werden soll
 */
async function goToHash(event, hashObject, at) {
    // console.log("goToHash", event, hashObject, at);

    if(hashObject === undefined) {
      return;
    }

    let hash     = hashObject['hash']
    let path     = hash["path"];
    let viewZoom = hash["viewZoom"];
    let coord_x  = hash["coord_x"];
    let coord_y  = hash["coord_y"];

    let options         = hashObject["options"];
    let zoom_mode       = options["zoom_mode"];
    let view_mode       = options["view_mode"];
    let viewZoom_option = options["view_mode"];
    if(options["viewZoom"]) {
      viewZoom = viewZoom_option;
      if(hash["viewZoom"] && options["viewZoom"] != hash["viewZoom"]) {
        console.log("Ambigous URL fragment given (format should be #path-z-x-y,params). View zoom z given as coordinate and parameter level; using the latter.")
      }
    }



    // Logik: Name der auszuwählenden Logik finden
    var global_logic = util.findLogic('auto', zoomLogics)['logic'];
    var curr_logic   = map.get("logic");

    var logic_name;
    if(!zoom_mode) {
      logic_name     = util.findLogicName(curr_logic, zoomLogics);
    } else {
      if(zoom_mode == "auto") {
        logic_name = "auto";
      } else if(zoom_mode == "auto_min") {
        logic_name = "auto_min";
      } else if(zoom_mode == "auto_max") {
        logic_name = "auto_max"
      } else if(zoom_mode == "fixed") {
        logic_name = "detail_" + util.getMetaForPath(path);
      } else {
        console.log("Invalid URL Fragment (format should be #coords,zoom=...,view=...). Zoom parameter incorrect; zoom should be one of: [auto, auto_min, auto_max, fixed]");
        return;
      }
    }

    // Logik anhand ihres Namens finden
    var selected_logic_withOptions = util.findLogic(logic_name, zoomLogics);
    var selected_logic             = selected_logic_withOptions['logic'];

    var new_logic_withOptions = selected_logic_withOptions;   // Ergebnis, wenn keine Korrektur vorgenommen werden muss
    var new_logic             = selected_logic;

    // Bearbeite #path
    var global_uniquePaths   = util.getUniquePaths(global_logic);
    var curr_uniquePaths     = util.getUniquePaths(curr_logic);
    var selected_uniquePaths = util.getUniquePaths(selected_logic);

    if(!global_uniquePaths.includes(path)) {
      console.log("Invalid URL Fragment (format should be #path-z-x-y,params). Path attribute incorrect; path should be one of:", global_uniquePaths);
      return;
    }

    if(!selected_uniquePaths.includes(path)) {
      var new_logicName           = 'auto';
          new_logic_withOptions   = util.findLogic(new_logicName, zoomLogics);           // versuche auf Autozoom zu wechseln
          new_logic               = new_logic_withOptions['logic'];
      console.log("WARNING: Implausible URL Fragment (format should be #path-z-x-y,zoom=...,view=...,[level=z]). The selected zoom mode (zoom=...) does not allow for the given path. Automatic switch to auto zoom applied.");
    }

    // Bearbeite #z
    var meta           = util.getMetaForPath(path);
    var viewZoom_range = util.getViewZoomForMeta(meta, new_logic);
    var viewZoom_min   = viewZoom_range['range_min'];
    var viewZoom_max   = viewZoom_range['range_max'];

    // Teste #...-x-y- auf Korrektheit
    coord_x = coord_x < def.contentExtent[0] ? def.contentExtent[0] : coord_x;
    coord_x = coord_x > def.contentExtent[2] ? def.contentExtent[2] : coord_x;
    coord_y = coord_y > def.contentExtent[3] ? def.contentExtent[3] : coord_y;
    coord_y = coord_y < def.contentExtent[1] ? def.contentExtent[1] : coord_y;
    var center = [coord_x, coord_y];

    // falls viewZoom nicht in aktuell überprüfter Ebene zugänglich:
    if(viewZoom < viewZoom_min || viewZoom > viewZoom_max) {
      console.log("WARNING: Implausible URL Fragment (format should be #path-z-x-y,zoom=...,view=...). The selected zoom mode (zoom=...) does not allow for given view zoom:", viewZoom);
      // console.log("viewZoom in aktueller Logik nicht zugänglich", viewZoom, viewZoom_min, viewZoom_max);

      var new_logicName;

      // ... prüfe verschobene Autozoom-Modi
          var logic_autoMin          = util.findLogic("auto_min", zoomLogics)['logic'];
          var viewZoom_range_autoMin = util.getViewZoomForMeta(meta, logic_autoMin);

          if(viewZoom >= viewZoom_range_autoMin['range_min'] && viewZoom <= viewZoom_range_autoMin['range_max']) {
            new_logicName = "auto_min"
          } else {
            var logic_autoMax          = util.findLogic("auto_max", zoomLogics)['logic'];
            var viewZoom_range_autoMax = util.getViewZoomForMeta(meta, logic_autoMax);
            if(viewZoom >= viewZoom_range_autoMax['range_min'] && viewZoom <= viewZoom_range_autoMax['range_max']) {
              new_logicName = "auto_max"
            }
          }

      // ... prüfe Übersichtsebene
          if(!new_logicName) {                      // nur, wenn noch keine neue Logik aus den beiden verschobenen Autozoom-Modi gefunden ist
              new_logicName = 'detail_' + meta;
          }

        // Neu ausgewählte Logik testen
          new_logic_withOptions   = util.findLogic(new_logicName, zoomLogics);
          new_logic               = new_logic_withOptions['logic'];

          // ... teste ob viewZoom in Übersichtsebene zugänglich
          viewZoom_range = util.getViewZoomForMeta(meta, new_logic);
          viewZoom_min   = viewZoom_range['range_min'];
          viewZoom_max   = viewZoom_range['range_max'];

          // ... falls nicht, setze viewZoom auf maximalen (minimalen) Wert zurück
          viewZoom = viewZoom < viewZoom_min ? viewZoom_min : viewZoom;
          viewZoom = viewZoom > viewZoom_max ? viewZoom_max : viewZoom;

      console.log("Automatic switch to plausible values (logic, viewZoom_min, viewZoom_max):", new_logicName, viewZoom_min, viewZoom_max);
    }

    // Neu ausgewählte Logik setzen
    if(curr_logic != new_logic) {
      // console.log("new_logic != curr_logic", new_logic, curr_logic, viewZoom, center);
      // console.log("new_logic_withOptions", new_logic_withOptions);
      main.changeLogic(new_logic_withOptions, viewZoom, center);
    } else {
      // console.log("new_logic == curr_logic", new_logic_withOptions, curr_logic, viewZoom, center);

      map.un("moveend", updateHash);
      map.getView().setZoom(viewZoom);
      map.getView().setCenter(center);
      map.on("moveend", updateHash);

    }

    // Ausgewähltes Taxon markieren
    if(at) {
      main.flashPoint(center, 2000);
    }


      updateHash("hashManual", at, viewZoom, coord_x, coord_y);    // muss nach metaZoom erfolgen
    return;
}


/**
 * FINDE HASH-ADRESSE FÜR TAXON (Javascript-Teil)
 * @param    {[type]} at [description]
 * @options  {[type]} at [description]
 * @return   {[type]}    [description]
 */
async function findTaxon(at, options) {
  return new Promise(function(resolve, reject) {
  // console.log("FINDTAXON", at, options);

  let phylum, taxon;
      at_format = at;                                  // mit _
      at = at.replace("@", "").replace("_", " ");      // mit [ ]

  let atSplit = at.split("-");
  if(atSplit.length > 1) {  // Tracheophyta-Galanthus
    phylum = atSplit[0];    // Galanthus
    taxon = atSplit[1];     // Galanthus

  } else {                  // Galanthus oder ID
    taxon = atSplit[0];
    phylum = "";
  }

  let preferredMode = options["zoom_mode"];

  console.log("findLink ...");
  findLink(taxon, phylum).then((result) => {
    let name        = result["name"]
    let meta        = result["level"];
    let x_unshifted = result["x"];
    let y_unshifted = result["y"];
    let fsize       = result["fsize"];
    let species_no  = result["species_no"];

    console.log("result", result);
    console.log(`name: ${name}, meta: ${meta}, fsize: ${fsize}, spec_no: ${species_no}`);

    let tax_level   = util.getPathForMeta(meta);

    let optimizedLink = optimizeLink(meta, fsize, util.textWidth(name), x_unshifted, y_unshifted, preferredMode, species_no);
    let viewZoom = optimizedLink["viewZoom"];   // bester ViewZoom aus aktueller Perspektive
    let coord_x  = optimizedLink["x"];          // ggf. verschobene Koordinaten
    let coord_y  = optimizedLink["y"];
    let autoZoomLogic = optimizedLink["logic"];

    let hash     = `#${tax_level}-${viewZoom}-${coord_x}-${coord_y}`;
    let options  = `,zoom=${autoZoomLogic}`; // [TODO]: view
    // let options  = `,zoom=${autoZoomLogic},view=photo`;
    let fragment = `${hash}${at_format}${options}!`;
    // console.log("findTaxon/findLink:", hash, fragment);

    resolve(fragment);
    });
  });
}



/**
 * FINDE HASH-ADRESSE FÜR TAXON (Link zu PHP)
 **/
async function findLink(taxon, phylum) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/linkSearch2.php",
      data: { taxon: taxon, phylum: phylum},

      success: function(output) {
        // console.log("output in findLink", output);
        var result = JSON.parse(output);
        resolve(result);
      },
      error: function(error) {
        reject(error);
      }
    })
  })
}

// Ausgelagert (Mikrooptimierung)
var autoLogic       = util.findLogic('auto', zoomLogics)['logic'];
var autoLogic_min  = util.findLogic('auto_min', zoomLogics)['logic'];
var autoLogic_max  = util.findLogic('auto_max', zoomLogics)['logic'];
var speciesViewZoom = util.getViewZoomForMeta(7, autoLogic)['range_min'];


/**
 * Findet für Koordinaten eines Taxons einen möglichst guten Zoom zur Anzeige
 * @param  {[type]} meta            Taxonomische Ebene. Benötigt, um maximalen Zoom zu finden.
 * @param  {[type]} fsize           Schriftgröße des Taxons. Bestimmt die Anzeigegröße.
 * @param  {[type]} textWidth       Schriftlänge des Taxons. Bestimmt die Anzeigegröße.
 * @param  {[type]} x_unshifted     Koordinaten des Taxons: x.
 * @param  {[type]} y_unshifted     Koordinaten des Taxons: y.
 * @param  {[type]} autoZoom_mode   Bestimmt, welche autoZoom-Logik verwendet werden soll.
 *                                  Mögliche Werte:  {"auto", "auto_min", "auto_max", "full"}
 *                                  Bei "full" optimiere aus allen möglichen Autozoomwerten.
 * @return {[type]}                 Optimierte URL.
 */
function optimizeLink(meta, fsize, textWidth, x_unshifted, y_unshifted, autoZoom_mode, species_no) {

  // Parameter-Test
  if(!autoZoom_mode) {
   autoZoom_mode = "full";
  }

  /**
  let allowedModes = ["auto", "auto_min", "auto_max", "full"];
  if(allowedModes.includes(autoZoom_mode.toString())) {
    autoZoom_mode = "full";
    console.log("WARNING in optimizeLink: autoZoom_mode must be one of", allowedModes, autoZoom_mode);
  }
  **/


  let x = x_unshifted;
  let y = y_unshifted;

  // viewZoom optimieren
  let viewZoom_range;
  let extended_rangeLogics;


  if(autoZoom_mode == "auto") {
    viewZoom_range     = util.getViewZoomForMeta(meta, autoLogic);
  } else if(autoZoom_mode == "auto_min") {
    viewZoom_range     = util.getViewZoomForMeta(meta, autoLogic_min);
  } else if(autoZoom_mode == "auto_max") {
    viewZoom_range     = util.getViewZoomForMeta(meta, autoLogic_max);
  } else {  // autoZoom_mode == "full"
    extended_rangeLogics     = util.getFullAutoZoomForMeta(meta, autoLogic, autoLogic_min, autoLogic_max)
    viewZoom_range           = extended_rangeLogics["range"];
  }




  /** [TODO]:
      Hier gibt es folgendes Problem: Da die optimale Größe eines Taxons aus der Schriftgröße  eines Taxons abgeleitet wird,
      ist nicht die eigentliche Polygonfläche entscheidend, sondern nur seine Breite!

      Das führt bei optimalSize = 220 dazu, dass die Mammalia (auf 1920x1080px Bildschirmen) auf auto_max optimiert werden
      und die Gastropoda auf auto_min. Eigentlich sollten beide auf auto sein.

      Abhilfe: Keine Schriftgrößenwerte verwenden, sondern die Anzahl der enthaltenen Arten (normalisiert auf Viewzoom).
      [Beispieleinheit: Arten/coord]
  **/


  // Viewzoom optimieren
  let optimalSize_px     = 250;               // empirischer Wert

  let viewZoom           = viewZoom_range['range_min'];
  let current_difference = 10000;
  for(let v = viewZoom_range['range_min']; v <= viewZoom_range['range_max']; v++) {
    let internalZoom         = autoLogic[v]["internalZoom"];
    let taxonSize_px         = util.fsizeToPx(1/fsize, internalZoom) * textWidth;
                              // Schriftgröße (pro Zeichen) * Breite
    let taxonSize_spec       = 0;

    let new_difference    = Math.abs(optimalSize_px - taxonSize_px);
    if(new_difference < current_difference) {
      viewZoom = v;
      current_difference = new_difference;
    }
  }

  // Koordinaten optimieren
  let shifted_x, shifted_y;

  if(meta < 7) {  // Koordinaten = rechts oben, verschiebe zur Mitte (empirisch, fsize zoomunabhängig wie Koordinaten)
    shifted_x = parseFloat(x) + 0.65*textWidth/fsize;
    shifted_y = parseFloat(y) + 0.5/fsize;
  } else {       // Koordinaten = Mitte (Artebene)
    shifted_x = parseFloat(x);
    shifted_y = parseFloat(y) + 0.5;
    // viewZoom = speciesViewZoom;  // wenn auf Artebene, wähle immer den gleichen Viewzoom
  }

  shifted_x = shifted_x.toFixed(2);
  shifted_y = shifted_y.toFixed(2);


  // Logik ermitteln
  let viewZoom_logic;
  if(autoZoom_mode == "full") {
    viewZoom_logic = extended_rangeLogics["levels"].find(element => element["viewZoom"] == viewZoom)["logic"];
  } else {
    viewZoom_logic = autoZoom_mode;
  }

  let optimizedUrl = {
    logic: viewZoom_logic,
    viewZoom: viewZoom,
    x: shifted_x,
    y: shifted_y,
  }

  // console.log("optimizedUrl", optimizedUrl);
  return  optimizedUrl;
}


module.exports = {
  initHash:         initHash,
  updateHash:       updateHash,
  extractHash:      extractHash,
  goToHash:         goToHash,
  optimizeLink:     optimizeLink,
  silentHashChange: silentHashChange,
  silentAt:         silentAt,
  findAddress:      findAddress
}
