var def = require('./def');

function getAutoDefs() {
    // see openlayers.docx for more information on this procedure

    // Maximalen Zoom ermitteln
    var global_zoom          = def.autoZoom_default;
    var global_internalZooms = global_zoom.map(line => line['internalZoom']);       //    [{viewZoom: 0, internalZoom: 1.8, ...}, {viewZoom: 1, ...}, ...] -> [0, 1, ..., 12]
    var maxZoom              = Math.ceil(Math.max(...global_internalZooms));        //    -> 11

    // Längere Seite des TileGrids finden
    const MAX_EXTENT = Math.max(Math.abs(def.gridExtent[1]), Math.abs(def.gridExtent[2]));   // typischer contentExtent: [0, -y, x, 0]
    const TILE_SIZE  = def.tileSize;

    // Ermittle Basiszoom z1, an dem gilt: res0 = 1.000
    const z1 = Math.log(MAX_EXTENT/TILE_SIZE) / Math.log(2);

    // Resolutions-Array ermitteln
    var gridResolutions = [];
    if(def.gridResolutions == "auto") {

      for(let z=0; z <= maxZoom; z++) {
        var curr_res = Math.round(Math.pow(2, z1-z)*1000000000)/1000000000         // res=2^(z1-z)    round: 49.999999 -> 50
            gridResolutions.push(curr_res);                                        // z. B. neu [50, 25, 12.5, 6.25, 3.125, 1.5625, 0.78125, 0.390625, 0.1953125, 0.09765625, 0.048828125, 0.024414063, 0.012207031, 0.006103516, 0.003051758]
      }                                                                            // z. B. alt [8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625, 0.03125, 0.015625, 0.0078125, 0.00390625]

    } else {
      gridResolutions = def.gridResolutions
    }


    return {
      maxZoom: maxZoom,
      resExponent: z1,
      gridResolutions: gridResolutions,
  }
}


module.exports = {
  resExponent:     getAutoDefs().resExponent,
  gridExtent:      getAutoDefs().gridExtent,
  maxZoom:         getAutoDefs().maxZoom,
  gridResolutions: getAutoDefs().gridResolutions
}
