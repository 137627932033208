// Funktionen aus anderen Javascript-Dateien
var def  = require('./def');
var pre  = require('./pre');
var util = require('./util');


/**
 * Initialisiere Kartenwerte entsprechend der aktuellen Bildschirmgröße
 * @return {[type]} [description]
 */
export function initializeMap() {
  var fullscreenZoom = getFullscreenZoom();
  var autoZoom_modified = modifyZoom(fullscreenZoom);
  var viewResolutions = getViewResolutions(autoZoom_modified);
  var zoomLogics = getZoomLogics(autoZoom_modified, def.detailZoom_default, ["auto_min", "auto_max"], [def.autoZoom_leftShift, def.autoZoom_rightShift], [def.autoZoom_leftShift2, def.autoZoom_rightShift2]);
  var mapOptions = getMapOptions(autoZoom_modified);

  var mapSettings = { zoomLogic: autoZoom_modified, viewResolutions: viewResolutions, options: mapOptions, zoomLogic2: zoomLogics };
  return mapSettings;
}





/**
 * Ermittle numerischen Wert aus Zoomoption des folgenden Formats: "auto", "auto+3", "auto-3", "+3"
 * @param  {option} Zoomoption (unterstützte Formate siehe oben)
 * @param  {auto}   anzuwendender numerischer Wert für auto
 * @param  {global_min}   Zoomwert, der nicht unterschritten werden darf
 * @param  {global_max}   Zoomwert, der nicht überschritten werden darf
 * @return {array}  Optionen
 */
function parseDetailOption(option, auto_value, global_min, global_max) {
      option = option.toString();
  var option_split = option.split("auto");                  // (1) "", ""       (2) ["", "+3"]    (3) ["3"]
  var auto_summand, result;


  if(option_split.length > 1) {                              // auf automatischer Logik basierend
    if(option_split[1].trim().length == 0) {                 // (1) "auto":     ohne Modifikator
      auto_summand = 0;
    } else {                                                // (2) "auto+3":     mit Modifikator
      auto_summand = parseInt(option_split[1]);             // "+3" -> 3

    }
    result = auto_value + auto_summand;

  } else {                                                   // benutzerdefinierter Wert
    result = parseInt(option_split[0]);                      // (3): "3"
  }

  if(isNaN(result)) {                                     // falls Fehler, z. B. "auto+§", verwende "auto"
    result = auto_value;
    console.log("Error in parsing detail zoom option", option, ": using \'auto\' value", auto_value, "instead.");
  }

  if(global_min !== undefined && result < global_min) {
    result = global_min;
    console.log("Error in parsing detail zoom option", option, ": given zoom below global minimum value - increasing to value", global_min, "instead.");
  }

  if(global_max !== undefined && result > global_max) {
    result = global_max;
    console.log("Error in parsing detail zoom option", option, ": given zoom exceeds global maximum value - lowering to value", global_max, "instead.");
  }

  return result;
}



/**
 * Erstelle ein Array mit allen Zoomlogiken, automatischer Zoommodus und Detailebenen
 * @param  {autoZoom_modified}        Errechnete (fertige) Logik für den automatischen Zoommodus
 * @param  {detailZoom_default}       Vorgegebene (unfertige) Optionen für Detailebenen
 * @param  {autoZoomShift_names}      Array mit Namen der verschobenen Autozoom-Ebenen.
 * @param  {autoZoomShift_defaults}   Vorgegebene (unfertige) Logik für verschobene Autozoom-Ebenen.
 *
 * @return {logics}               Vollständiges Array
 */
function getZoomLogics(autoZoom_modified, detailZoom_default, autoZoomShift_names, autoZoomShift_defaults, autoZoomShift_defaults2) {

  // NORMALER AN BILDSCHIRMGRÖSSE ANGEPASSTER AUTOZOOM
  // alle weiteren Logiken auf Werte zwischen Minimum und Maximum des Autozooms beschränken
  var global_viewZooms = autoZoom_modified.map(line => line['viewZoom']);       //    [{viewZoom: 0, internalZoom: 1.8, ...}, {viewZoom: 1, ...}, ...] -> [0, 1, ..., 12]
  var global_min = Math.min(...global_viewZooms);                               //    -> 0
  var global_max = Math.max(...global_viewZooms);                               //    -> 12

  // AutoZoom zur Logik hinzufügen
  var logics = [];
      logics.push({name: 'auto', minZoom: global_min, maxZoom: global_max, logic: autoZoom_modified});

  // LOGIK DER VERSCHOBENEN AUTOZOOM-EBENEN (1)
  // alle verschobenen Autozoom-Ebenen und ihre Namen ermitteln (auto_min, auto_max; kann ggf. erweitert werden)
  for(let i=0, len0=autoZoomShift_defaults.length; i < len0; i++) {
    let current_logicName  = autoZoomShift_names[i];
    let current_logicShift = autoZoomShift_defaults[i];

    // Verschiebung in Logik des modifizierten normalen Autozooms einfügen
    let current_logic = [];
    for(let j = 0, len1=autoZoom_modified.length; j < len1; j++) {
      let current_line     = autoZoom_modified[j];                                                        // z. B. {viewZoom: 0, internalZoom: 1.8, meta: 2},

      let current_viewZoom = current_line['viewZoom'];                                                    // 0
      let shifted_meta     = util.getYForX(current_logicShift, "viewZoom", current_viewZoom, "meta");     // z. B. "undefined" oder "3"
      let current_meta     = shifted_meta === undefined ? current_line['meta'] : shifted_meta;            // z. B. {viewZoom: 0, internalZoom: 1.8, meta: 2} oder {viewZoom: 0, internalZoom: 1.8, meta: 3}

      current_logic.push( {viewZoom: current_line['viewZoom'], internalZoom: current_line['internalZoom'], meta: current_meta} );
   }

   logics.push({name: current_logicName+"_2", minZoom: global_min, maxZoom: global_max, logic: current_logic});
 }

 // LOGIK DER VERSCHOBENEN AUTOZOOM-EBENEN (2)
 // alle verschobenen Autozoom-Ebenen und ihre Namen ermitteln (auto_min, auto_max; kann ggf. erweitert werden)
 for(let i=0, len0=autoZoomShift_defaults2.length; i < len0; i++) {
   let current_logic = [];
   let current_logicName  = autoZoomShift_names[i];                         // "auto_min", "auto_max";
   let current_logicShift = autoZoomShift_defaults2[i];                     // Array mit Optionen:   [ { meta: 1, min_viewZoom: 'auto'   }, { meta: 2, min_viewZoom: 'auto-1' }, ... ]

   // finde minimale Zoomlevel unter Verarbeitung der in def.js eingegebenen Werte
   let min_viewZooms_explicit = [];
   for(let j = 0, len1=current_logicShift.length; j < len1; j++) {                      // iteriere alle Metazoom-Stufen
     let current_meta = current_logicShift[j]['meta'];                                  // z. B. 3 (meta)
     let current_autoRange = util.getViewZoomForMeta(current_meta, autoZoom_modified);  // { range_min: 1, range_max: 2, path: 'class' }
     let current_option = current_logicShift[j]['min_viewZoom'];                        // z. B. "auto-1"
     let auto_min = current_autoRange['range_min'];                                     // z. B. 1 (ViewZoom)
     let current_value_min = parseDetailOption(current_option, auto_min, global_min, global_max); // -> (auto) range_max -1 -> 0 (ViewZoom)
     min_viewZooms_explicit.push(current_value_min);   // ["auto", "auto-1", "autoü1"...] -> [0, 1, 5, ...]
   }

    // teste, ob Eingabe aus def.js plausibel
    if(util.arrayHasDuplicates(min_viewZooms_explicit)) {
      console.log("WARNING: Ambiguous zoom values for modified auto zoom", current_logicName, "- check for errors of detail zoom settings in def.js!");
    }


    if(!util.arrayIsSorted(min_viewZooms_explicit)) {
      console.log("WARNING: Overlapping zoom values for modified auto zoom", current_logicName, "- check for errors of detail zoom settings in def.js!");
    }


    if(min_viewZooms_explicit[0] != 0) {
      console.log("WARNING: Modified auto zoom", current_logicName, "should start with internalZoom 0 - check for errors of detail zoom settings in def.js!");
    }

    // erstelle verschobenen AutoZoom
    min_viewZooms_explicit.reverse();                                               // suche letztes statt erstes Vorkommen -> [..., 5, 1, 0]

    for(let k = 0, len2=autoZoom_modified.length; k < len2; k++) {
      let current_line     = autoZoom_modified[k];                                  // z. B. {viewZoom: 0, internalZoom: 1.8, meta: 2},
      let current_viewZoom = current_line['viewZoom'];                              // z. B. 0
                                                                                    // für Index mache "reverse" rückgängig
      let current_meta_index = (min_viewZooms_explicit.length - 1) - min_viewZooms_explicit.findIndex(element => current_viewZoom >= element);
      let current_meta       = current_logicShift[current_meta_index]["meta"];
      // console.log("curr_viewZoom", current_viewZoom, "curr_metaIndex", current_meta_index);

      current_logic.push( {viewZoom: current_line['viewZoom'], internalZoom: current_line['internalZoom'], meta: current_meta} );
    }

   logics.push({name: current_logicName, minZoom: global_min, maxZoom: global_max, logic: current_logic});
}


  // LOGIK DER DETAILEBENEN
  // Optionen aus detailZoom_default auslesen
  for(let i = 0, len=detailZoom_default.length; i < len; i++) {
    let current_meta = detailZoom_default[i]['meta'];                                   // z. B. 3
    let current_name = "detail_" + current_meta;                                        //       'detail_3'
    let current_autoRange = util.getViewZoomForMeta(current_meta, autoZoom_modified);   //       { range_min: 1, range_max: 2, path: 'class' }

    let current_option_min = detailZoom_default[i]['min_viewZoom'];
    let auto_min = 0;
    let current_value_min = parseDetailOption(current_option_min, auto_min, global_min, global_max);

    let current_option_max = detailZoom_default[i]['max_viewZoom'];
    let auto_max = current_autoRange['range_max'];
    let current_value_max = parseDetailOption(current_option_max, auto_max, global_min, global_max);


    // Detailebenen zur Logik hinzufügen
    let current_logic = [];
    for(let j = 0, len=autoZoom_modified.length; j < len; j++) {
      let current_line     = autoZoom_modified[j];                                           // z. B. {viewZoom: 0, internalZoom: 1.8, meta: 2},
      let current_viewZoom = current_line['viewZoom'];                                       //        0

      if(current_viewZoom <= current_value_max && current_viewZoom >= current_value_min) {   // wenn Zoomstufen in dieser Logik erhalten, übernehme sie aus autoZoom_modified, mit geändertem Meta-Wert                                                //       {viewZoom: 0, internalZoom: 1.8, meta: 3},
        current_logic.push( {viewZoom: current_line['viewZoom'], internalZoom: current_line['internalZoom'], meta: current_meta} );
      }

      }
      if(current_value_max < current_value_min) {                                               // Hinweis auf mögliche Eingabefehler, aufgrund derer das Logikarray leer wird.
        console.log("Warning: Found maximum zoom level for", current_name, "to be below minimum zoom level. Check for errors of detail zoom settings in def.js!");
      }

    logics.push({name: current_name, minZoom: current_value_min, maxZoom: current_value_max, logic: current_logic});                                //       {name: 'detail_3', logic: [{viewZoom: 0, internalZoom: 1.8, meta: 3}, ...]}

  }

  // ERGEBNIS
  // console.log("zoomLogics2", logics);
   return logics;
}




/**
 * Ermittle zoombezogene Optionen der veränderten Zoomlogik.
 * @param  {zoomArray} autoZoom_modified ZoomArray mit internen Zoomstufen, aufsteigend geordnet von viewZoom = 0 ausgehend.
 * @return {array}                      Optionen
 */
function getMapOptions(autoZoom_modified) {
  // var minResolution = viewResolutions[0];
  // var maxResolution = viewResolutions[viewResolutions.length - 1];

  var minZoom = autoZoom_modified[0]['viewZoom'];
  var maxZoom = def.maxZoom;

  // Finde erste viewZoom-Stufe für die taxonomische Rangstufe, mit der die Karte gestartet werden soll
  // Getestet für automatischen Modus (forceView = 0), funktioniert (unterschiedliche Bildschirmgrößen, Subscreen, unterschiedlicher startZoom)
  var startZoom;
    if(!def.forceView) {
      startZoom = getMetaPositions(def.startMeta, autoZoom_modified)['range_vZoom'][0];
    } else {
      startZoom = def.forceViewOptions["startZoom"];
    }

  return { minZoom: minZoom, maxZoom: maxZoom, startZoom: startZoom };
}



/**
 * Lese die Auflösung der zoombaren Ansichten (viewResolutions) aus einem zoomArray aus und wandle sie ein ein resolutionsArray um.
 * @param  {zoomArray} zoomArray                ZoomArray mit internen Zoomstufen, aufsteigend geordnet von viewZoom = 0 ausgehend.
 * @return {resolutionsArray} viewResolutions   Auflösungen aller Ansichten, beginnend bei viewZoom = 0
 */
function getViewResolutions(zoomArray) {
  var viewResolutions = [];
  var currentResolution;

  // Ermittle viewResolutions aus dem ZoomArray
  for(var i = 0, len=zoomArray.length; i < len; i++) {
    currentResolution = util.getResolutionForZoom(zoomArray[i]['internalZoom']);
    viewResolutions.push(currentResolution);
  }

  return viewResolutions;
}

/**
 * Verändert die festgelegten Zoomstufen in def.js in Abhängigkeit von der Bildschirmgröße und dort definierten Optionen.
 * @param  {internalZoom} fullscreenZoom  Interner Zoom, bei dem die aktuelle Bildschirmkonfiguration die Karte im Vollbild zeigen soll.
 * @return {zoomArray} autoZoom_modified  ZoomArray mit an die aktuelle Konfiguration angepassten Zoomstufen.
 */
function modifyZoom(fullscreenZoom) {
  //  Diese Funktion ist getestet (inkl. subScreen, große und kleine Bildschirme).
  //  [TODO]: Sehr große Bildschirmgrößen (nicht getestet, siehe unten).

  /*  Beispiel: fullScreenMeta = 4, kleinste Zoomstufe = 3, subScreenStep = 0.2, fullScreenZoom = 3.5,
                def.autoZoom_default: MetaZoom 4 reicht „natürlicherweise“ von 4.5 bis 6.5; MetaZoom 3 mit 4 viewZoom-Stufen

                init.autoZoom_modified (Ergebnis):
                0: Object { viewZoom: 0, internalZoom: 2.7, meta: 3 }
          ​      1: Object { viewZoom: 1, internalZoom: 2.9, meta: 3 }
                2: Object { viewZoom: 2, internalZoom: 3.1, meta: 3 }
                3: Object { viewZoom: 3, internalZoom: 3.3, meta: 3 }   << bis hierhin subScreenLevels
          ​      4: Object { viewZoom: 4, internalZoom: 3.5, meta: 4 }   << hier FullScreenZoom
          ​      5: Object { viewZoom: 5, internalZoom: 4.25, meta: 4 }
          ​      6: Object { viewZoom: 6, internalZoom: 5, meta: 4 }
          ​      7: Object { viewZoom: 7, internalZoom: 5.75, meta: 4 }  << bis hier Spreizung der Ebene metaZoom 4
          ​      8: Object { viewZoom: 8, internalZoom: 6.5, meta: 5 }   << ab hier Übergang zu metaZoom 5 wie im autoZoom_default
          ​      9: {…}
  */

  // Standard-ZoomArray kopieren
  var autoZoom_modified = def.autoZoom_default;
  var subscreenMetaLevels = def.fullScreenMeta - def.autoZoom_default[0]['meta'];

  /* SUBSCREEN LEVELS */
  // Ermittle Anzahl der Zoomstufen, die kleiner als im Vollbild angezeigt werden sollen
  var subscreenViewLevels = 0;

  // Prüfe, ob SubScreenLevels vorhanden
  if(
    // automatischer Modus
    ( !def.forceView && subscreenMetaLevels > 0) ||

    // forcierter Modus
    ( def.forceView && def.forceViewOptions["fullScreenZoom"] - 1 > 0)

    ) {

    var currZoom = fullscreenZoom;
    if(!def.forceView) {
      subscreenViewLevels = getMetaPositions(def.fullScreenMeta)['range_vZoom'][0] - 1;
    } else {
      subscreenViewLevels = def.forceViewOptions["fullScreenZoom"] - 1;
    }

    // Zoomstufen im ZoomArray anpassen (mit subScreen überschreiben)
    for(var i = subscreenViewLevels, j=1; i >= 0; i--, j++) {
      currZoom = Math.round((currZoom - def.subScreenStep)*1000)/1000;
      autoZoom_modified[i]['internalZoom'] = currZoom;
    }
  }


  /* SUPERSCREEN LEVELS */
  // Höhere Ebenen anpassen: Übergang vom Zoom auf der Vollbildstufe zum standardmäßigen Zoom
  // Dazwischen liegende Ansichten werden gleichmäßigt aufgeteilt (gespreizt oder gestaucht)
  var fullscreen_real = getZoomPositions(fullscreenZoom);
  var fullscreen_default = getMetaPositions(def.fullScreenMeta);
    // console.log("fullscreen_real", fullscreen_real, "fullscreen_default", fullscreen_default);

  if(fullscreen_real['currMeta'] < fullscreen_default['currMeta']) {
    fullscreen_real = fullscreen_default;
  }

  // Ermittle Start und Ende der anzupassenden Zoomstufen
  var viewZoom_start;

  if(!def.forceView) {
    viewZoom_start = fullscreen_default['range_vZoom'][0];
  } else {
    viewZoom_start = def.forceViewOptions["fullScreenZoom"];
  }

  var viewZoom_end = fullscreen_real['range_vZoom'][1];
    // var internalZoom_start = fullscreen_default['range_iZoom'][0];
  var internalZoom_end = fullscreen_real['range_iZoom'][1];

  // Ermittle Anzahl der Zoomstufen, die vom Übergang erfasst werden
  var superscreenViewLevels = viewZoom_end - viewZoom_start;
  var superscreenZoomDistance = internalZoom_end - fullscreenZoom;

  var superscreenStep = Math.round(superscreenZoomDistance/superscreenViewLevels*1000)/1000;

  // Zoomstufen im ZoomArray anpassen (mit superScreen überschreiben)
  var currZoom = fullscreenZoom;
  for(var i = viewZoom_start; i <= viewZoom_end-1; i++) {
    autoZoom_modified[i]['internalZoom'] = currZoom;
    currZoom = Math.round((currZoom + superscreenStep)*1000)/1000;
  }

  // console.log("autoZoom_modified:", autoZoom_modified);
  return autoZoom_modified;
}


/**
 * Bestimmt den internen Zoom auf Vollbildgröße für die aktuelle Bildschirmkonfiguration.
 * @return {internalZoom} Interner Zoom auf Vollbildgröße.
 */
function getFullscreenZoom() {
  const HEADER_HEIGHT = def.headerHeight;
  const TOOLBAR_WIDTH = def.toolbarWidth;
                                            // z. B. Bildschirmgröße 1920x1080
  var width = window.innerWidth;            // in Firefox: 1920×927
  var height = window.innerHeight;

  // [TODO]: auf Handys mit screen.orientation.lock('landscape') und testen, welches die längere Seite ist
    // var shorterSide = width < height - HEADER_HEIGHT ? width : height - HEADER_HEIGHT;
    // var availableSpace = shorterSide;   // die Höhe sei hier als die kürzere Seite definierten

  var availableHeight = height - HEADER_HEIGHT;
  var availableWidth = width - TOOLBAR_WIDTH;

  var screenRatio = (height - HEADER_HEIGHT)/width;                                  // Bildschirmformat
  var mapRatio = Math.abs(def.contentExtent[1]) / Math.abs(def.contentExtent[2]);    // Kartenformat
                                                                                     // hier: contentExtent, nicht gridExtent (logisch)

  // [TODO]: es wird hier davon ausgegangen, dass beim Kartenformat immer Höhe < Breite (wahrscheinliche Konfiguration, muss aber nicht so sein)
  // für Bildschirme, die breiter als hoch sind gilt:
    // ist das Bildschirmformat breiter als das Kartenformat, ist die Bildschirmhöhe der limitierende Faktor
    // ist das Bildschirmformat schmaler als das Kartenformat, ist die Bildschirmbreite der limitierende Faktor
    // [TODO]: inwiefern ist das verallgemeinerbar auf Bildschirme, die höher als breit sind?
    // [TODO]: eventuell einfach kürzere Seite als Höhe nehmen (siehe oben)
    // müsste erst überlegt werden, wenn Karte eingeführt werden soll, die höher ist als breit
    // was unterschiedliche Bildschirmformate betrifft, funktioniert es


    // limitierende Dimension für die Größe der Karte
    var limitingDimension = screenRatio < mapRatio ? 'height' : 'width';

    var availableSpace;

    if(limitingDimension === "height") {
      availableSpace = availableHeight / mapRatio;        // zur Erklärung siehe openLayers.docx
    } else {
      availableSpace = availableWidth;
    }

  // Für die Herleitung der Formel siehe openlayers.docx
  var fullscreenZoom = Math.log(availableSpace/def.tileSize) / Math.log(2);

  fullscreenZoom = fullscreenZoom < def.smallest_fullscreen ? def.smallest_fullscreen : fullscreenZoom;
  fullscreenZoom = fullscreenZoom > def.largest_fullscreen ? def.largest_fullscreen : fullscreenZoom;


  // Beim BotGate: 300px (1200/2048 * 512)      Beim LifeGate2020: 227,5px (910/2048)
  /* alt, kann wahrscheinlich gelöscht werden
  var contentRatio_height = def.contentExtent[1]/def.gridExtent[1];     // ausgefüllte Höhe des TileGrids (Anteil 0..1)
  var contentRatio_width = def.contentExtent[2]/def.gridExtent[2];      // ausgefüllte Breite des TileGrids (Anteil 0..1)
  */


  return Math.round(fullscreenZoom*100)/100;
}







/**
 * Ermittelt für einen gegebenen Zoom und ein zugehöriges zoomArray die aktuelle
 * taxonomische Rangstufe, ihren Zoombereich und die vorherige und folgende Rangstufe.
 *
 * @param  {number} searchZoom    Zoom, für den diese Werte ermittelt werden sollen
 * @param  {zoomArray} zoomArray  ZoomArray, in dem die Untersuchung durchgeführt wird
 * @return {[Object]}             @property {meta} currMeta
 *                                @property {[array]} range [start, end]
 *                                @property {meta} prevMeta
 *                                @property {meta} nextMeta
 */
function getZoomPositions(searchZoom, zoomArray) {
  if(zoomArray === undefined) { zoomArray = def.autoZoom_default; }

  // Ermittle zugehörige taxonomische Rangstufe für gegebenen Zoom
  var currMeta;
  var len = zoomArray.length;
  for(var i = 0; i < len; i++) {
    if(zoomArray[i]['internalZoom'] >= searchZoom) {
        currMeta = zoomArray[i]['meta'];
        break;
    }
  }
  if(currMeta === undefined) { currMeta = zoomArray[len-1]['meta'] }    // falls Zoom außerhalb des Bereichs

  // Ermittle Beginn und Ende der gefundenen Rangstufe
  var startMeta, endMeta;
  var startView, endView;
  var prevMeta, nextMeta;
  var i;
  for(var i = 0, len = zoomArray.length; i < len; i++) {
    if(zoomArray[i]['meta'] == currMeta) {
        if(startMeta === undefined) {
          startMeta = zoomArray[i]['internalZoom'];
          startView = i;

          if(i > 0) {
            prevMeta = zoomArray[i-1]['meta'];
          } else {
            prevMeta = 'none';
          }
        }

    } else if(zoomArray[i]['meta'] != currMeta && startMeta !== undefined) {
      endMeta = zoomArray[i]['internalZoom'];
      endView = i;

      if(i+1 < len) {
        nextMeta = zoomArray[i+1]['meta'];
      } else {
        nextMeta = 'none';
      }

      break;
    }
  }

  return {currMeta: currMeta, range_iZoom: [startMeta, endMeta], range_vZoom: [startView, endView], prevMeta: prevMeta, nextMeta: nextMeta};

}

/**
 * Ermittelt für eine gegebene taxonomische Hierarchiestufe Zoom und ein zugehöriges zoomArray die benachbarten
 * taxonomische Rangstufen und ihre Zoombereiche. Analog zu getZoomPositions; anderer Eingabeparameter (metaZoom statt internalZoom).
 *
 * @param  {number} searchZoom    Zoom, für den diese Werte ermittelt werden sollen
 * @param  {zoomArray} zoomArray  ZoomArray, in dem die Untersuchung durchgeführt wird
 * @return {[Object]}             @property {meta} currMeta
 *                                @property {[array]} range [start, end]
 *                                @property {meta} prevMeta
 *                                @property {meta} nextMeta
 */
function getMetaPositions(searchMeta, zoomArray) {
    if(zoomArray === undefined) { zoomArray = def.autoZoom_default; }

    // Ermittle zugehörigen internen Zoom für gegebene Rangstufe
    var currMeta = searchMeta;
    var startMeta, endMeta;
    var startView, endView;
    var prevMeta, nextMeta;

    var len = zoomArray.length;
    for(var i = 0; i < len; i++) {
      if(startMeta === undefined && zoomArray[i]['meta'] == currMeta) {
          startMeta = zoomArray[i]['internalZoom'];
          startView = i;

          if(i > 0) {
            prevMeta = zoomArray[i-1]['meta'];
          } else {
            prevMeta = "none";
          }
      } else if(startMeta !== undefined && zoomArray[i]['meta'] > currMeta) {
          endMeta = zoomArray[i]['internalZoom'];
          endView = i;
          nextMeta = zoomArray[i]['meta'];
          break;
          // [TODO]: maxZoom, wenn dieser Fall nie erreicht wird
      }
    }

      return {currMeta: currMeta, range_iZoom: [startMeta, endMeta], range_vZoom: [startView, endView], prevMeta: prevMeta, nextMeta: nextMeta};
}




/**
 * [downshiftAutoZoom description]
 * @param  {[type]} zoom          Letzter ViewZoom, ...
 * @param  {[type]} meta          ... der aktuellen MetaZoom in der zu verschiebenden Logik enthalten soll.
 * @param  {[type]} logicToShift  Zoomlogik, von der ausgehend Veränderung durchgeführt werden soll.
 * @return {[type]} shiftedLogic  Veränderte Zoomlogik mit gegebenem Meta auf gegebenem ViewZoom und ansonsten aufgeteilten weiteren Zoomstufen.
 */
export function downshiftAutoZoom(zoom, meta, logicToShift) {
  var shiftedLogic = [];

  // Finde Anzahl metaZoom-Stufen
  var uniqueMetas = logicToShift.map(element => element.meta)                                      // mögliche MetaZoom-Werte finden
                               .filter((value, index, self) => self.indexOf(value) === index);     // distinct values from object key
                                                                                                   // -> https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript

  // Ermittle Anzahl Zoomstufen vor/nach aktuellem ViewZoom (ohne ViewZoom selbst)  // z. B. zoom = 4; insg. 13 Zoomlevel
  var zoomLevels_before = zoom;                                                     // z. B. [0, 1, 2, 3] -> Länge 4
  var zoomLevels_after = logicToShift.length - zoom - 1;                            // z. B. [5, 6, 7, 8, 9, 10, 11, 12] -> Länge 13-4-1 = 8
  var zoomLevels_total = logicToShift.length;

  // Ermittle Anzahl Größe des viewZoom-Bereichs für metaZoom ≤ meta und > meta.
  var smallerMetas_rangeSize = util.getViewZoomForMeta(meta, logicToShift)['range_max']+1;                        // z. B. meta: 4 -> Bereich für meta 2, 3, 4 -> ZoomStufen 0-5 -> range: 6
  var largerMetas_rangeSize;                                                                                      // z. B. meta: 4 -> Bereich für meta 5, 6    -> ZoomStufen 6-12 -> range: 5
    if(meta >= Math.max(...uniqueMetas)) {
      largerMetas_rangeSize = 0;
    } else {
      largerMetas_rangeSize = zoomLevels_total - util.getViewZoomForMeta(meta+1, logicToShift)['range_min'];
    }

    // Ermittle neu aufgespreizte Bereiche ...
    var newMetaRanges = [];
    var completed_range = -1;   // bereits behandelter Bereich; -1, da weiter unten stets +1 gerechnet wird und mit ViewZoom = 0 angefangen werden soll (vermeidet eine zusätzliche If-Abfrage)
    var i = 0;                  // zähle Iterationen mit

    // ... für jede Meta-Zoomstufe
    uniqueMetas.forEach(function(metaFromArray) {
      var currentMeta_share;
      var currentMeta_range      = util.getViewZoomForMeta(metaFromArray, logicToShift);                  // ursprünglicher Bereich (range_min, range_max)
      var currentMeta_range_size = currentMeta_range['range_max'] - currentMeta_range['range_min'] + 1;   // ursprüngliche  Bereichsgröße
      var currentMeta_range_size_new, currentMeta_range_min_new, currentMeta_range_max_new;

      // unterhalb oder gleich aktuellen MetaZooms ("erste Hälfte")
      if(metaFromArray < meta) {
        currentMeta_share = currentMeta_range_size/smallerMetas_rangeSize;                               // ermittle Anteil des aktuellen Metazooms am Gesamtbereich der ≤-Metas
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_before);                  // spreize auf neuen zur Verfügung stehenden Platz
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(completed_range+currentMeta_range_size_new);
      } else if(metaFromArray == meta) {
        currentMeta_share = currentMeta_range_size/smallerMetas_rangeSize;
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_before);
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(zoom);                                                      // der übergebene Zoom soll stets ganz unten liegen

      // unterhalb aktuellen MetaZooms ("erste Hälfte")
      } else if(i < uniqueMetas.length - 1) {
        currentMeta_share = currentMeta_range_size/largerMetas_rangeSize;                               // ermittle Anteil des aktuellen Metazooms am Gesamtbereich der >-Metas
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_after);                  // spreize auf neuen zur Verfügung stehenden Platz
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(completed_range+currentMeta_range_size_new);
      } else {
        currentMeta_share = currentMeta_range_size/largerMetas_rangeSize;
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_after);
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(zoomLevels_total-1);
      }

      i++;
      completed_range = currentMeta_range_max_new;
      newMetaRanges.push({'meta': metaFromArray, 'range_min': currentMeta_range_min_new, 'range_max': currentMeta_range_max_new });

    });


    // Erstelle neue Logik
    logicToShift.forEach(function(logicLine) {
      var newLogicLine = Object.assign({}, logicLine);      // vermeide, dass ursprüngliches Objekt verändert wird
      var current_viewZoom = logicLine['viewZoom'];
      var newMeta = newMetaRanges.find(element => element.range_max >= current_viewZoom)['meta'];
      newLogicLine['meta'] = newMeta;
      shiftedLogic.push(newLogicLine);
});

// console.log("logicToShift, shiftedLogic", init.autoZoom_modified, shiftedLogic);

return shiftedLogic;

}



/**
 * [downshiftAutoZoom description]
 * @param  {[type]} zoom          Erster ViewZoom, ...
 * @param  {[type]} meta          ... der aktuellen MetaZoom in der zu verschiebenden Logik enthalten soll.
 * @param  {[type]} logicToShift  Zoomlogik, von der ausgehend Veränderung durchgeführt werden soll.
 * @return {[type]} shiftedLogic  Veränderte Zoomlogik mit gegebenem Meta auf gegebenem ViewZoom und ansonsten aufgeteilten weiteren Zoomstufen.
 */
export function upshiftAutoZoom(zoom, meta, logicToShift) {
  var shiftedLogic = [];

  // Finde Anzahl metaZoom-Stufen
  var uniqueMetas = logicToShift.map(element => element.meta)                                      // mögliche MetaZoom-Werte finden
                               .filter((value, index, self) => self.indexOf(value) === index);     // distinct values from object key
                                                                                                   // -> https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript

  // Ermittle Anzahl Zoomstufen vor/nach aktuellem ViewZoom (ohne ViewZoom selbst)  // z. B. zoom = 4; insg. 13 Zoomlevel
  var zoomLevels_before = zoom;                                                     // z. B. [0, 1, 2, 3] -> Länge 4
  var zoomLevels_after = logicToShift.length - zoom - 1;                            // z. B. [5, 6, 7, 8, 9, 10, 11, 12] -> Länge 13-4-1 = 8
  var zoomLevels_total = logicToShift.length;

  // Ermittle Anzahl Größe des viewZoom-Bereichs für metaZoom < meta und ≥ meta (anders als bei downshift!)
  var smallerMetas_rangeSize;
  if(meta <= Math.min(...uniqueMetas)) {
    smallerMetas_rangeSize = 0;
  } else {
    smallerMetas_rangeSize - util.getViewZoomForMeta(meta, logicToShift)['range_min'];
  }                                                                                                          // z. B. meta: 4 -> Bereich für meta 2, 3       -> ZoomStufen 0-2 -> range: 3
  var largerMetas_rangeSize = zoomLevels_total - util.getViewZoomForMeta(meta, logicToShift)['range_min'];   // z. B. meta: 4 -> Bereich für meta 4, 5, 6    -> ZoomStufen 3-12 -> range: 10


    // Ermittle neu aufgespreizte Bereiche ...
    var newMetaRanges = [];
    var completed_range = -1;   // bereits behandelter Bereich; -1, da weiter unten stets +1 gerechnet wird und mit ViewZoom = 0 angefangen werden soll (vermeidet eine zusätzliche If-Abfrage)
    var i = 0;                  // zähle Iterationen mit

    // ... für jede Meta-Zoomstufe
    uniqueMetas.forEach(function(metaFromArray) {
      var currentMeta_share;
      var currentMeta_range      = util.getViewZoomForMeta(metaFromArray, logicToShift);                  // ursprünglicher Bereich (range_min, range_max)
      var currentMeta_range_size = currentMeta_range['range_max'] - currentMeta_range['range_min'] + 1;   // ursprüngliche  Bereichsgröße
      var currentMeta_range_size_new, currentMeta_range_min_new, currentMeta_range_max_new;

      // unterhalb oder gleich aktuellen MetaZooms ("erste Hälfte")
      if(metaFromArray < meta-1) {
        currentMeta_share = currentMeta_range_size/smallerMetas_rangeSize;                               // ermittle Anteil des aktuellen Metazooms am Gesamtbereich der ≤-Metas
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_before);                  // spreize auf neuen zur Verfügung stehenden Platz
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(completed_range+currentMeta_range_size_new);
      } else if(metaFromArray == meta-1) {
        currentMeta_share = currentMeta_range_size/smallerMetas_rangeSize;
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_before);
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(zoom-1);

      // unterhalb aktuellen MetaZooms ("erste Hälfte")
    } else if(metaFromArray == meta) {                                                                  // der übergebene Zoom soll stets ganz oben liegen
        currentMeta_share = currentMeta_range_size/largerMetas_rangeSize;
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_after);
        currentMeta_range_min_new =  parseInt(zoom);
        currentMeta_range_max_new =  parseInt(completed_range+currentMeta_range_size_new);
      } else if(i < uniqueMetas.length - 1) {
        currentMeta_share = currentMeta_range_size/largerMetas_rangeSize;                               // ermittle Anteil des aktuellen Metazooms am Gesamtbereich der >-Metas
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_after);                  // spreize auf neuen zur Verfügung stehenden Platz
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(completed_range+currentMeta_range_size_new);
      } else {
        currentMeta_share = currentMeta_range_size/largerMetas_rangeSize;
        currentMeta_range_size_new = Math.round(currentMeta_share * zoomLevels_after);
        currentMeta_range_min_new =  parseInt(completed_range+1);
        currentMeta_range_max_new =  parseInt(zoomLevels_total-1);
      }

      i++;
      completed_range = currentMeta_range_max_new;
      newMetaRanges.push({'meta': metaFromArray, 'range_min': currentMeta_range_min_new, 'range_max': currentMeta_range_max_new });

    });


    // Erstelle neue Logik
    logicToShift.forEach(function(logicLine) {
      var newLogicLine = Object.assign({}, logicLine);      // vermeide, dass ursprüngliches Objekt verändert wird
      var current_viewZoom = logicLine['viewZoom'];
      var newMeta = newMetaRanges.find(element => element.range_max >= current_viewZoom)['meta'];
      newLogicLine['meta'] = newMeta;
      shiftedLogic.push(newLogicLine);
});

// console.log("logicToShift, shiftedLogic", init.autoZoom_modified, shiftedLogic);

return shiftedLogic;

}
