var def   = require('./def');
var util  = require('./util');


async function updateButtons(path, tileExtent) {
  let meta  = tileExtent["meta"];
  let z     = tileExtent["z"];
  let x_min = tileExtent["x"][0];
  let x_max = tileExtent["x"][1];
  let y_min = tileExtent["y"][0];
  let y_max = tileExtent["y"][1];

  let extraButtons_search = def.perspectives_extra;
  let extraButtons_found  = await findButtons(JSON.stringify(extraButtons_search), path, meta, z, x_min, x_max, y_min, y_max);

  let speciesButtons_search = def.perspectives_species;
  let speciesButtons_found = [];

  // console.log("extraButtons_search", extraButtons_search)
  // console.log("extraButtons_found",  extraButtons_found);

  if(util.getMetaForPath(meta) == 7 && z > 10) {
    // console.log("speciesButtons_search", speciesButtons_search);
    speciesButtons_found  = await findButtons(JSON.stringify(speciesButtons_search), path, meta, z, x_min, x_max, y_min, y_max);
    // console.log("speciesButtons_found",  speciesButtons_found);
  }

  // Allgemeine Buttons (auf allen taxonomischen Ebenen sichtbar) aus Standard- (Schema, Fotos) und Extrabuttons (Karte) zusammenfassen
  var defaultButtons  = def.perspectives_default;
  var generalButtons  = [...defaultButtons, ...extraButtons_found];

  let totalButtons = {
    generalButtons: generalButtons,
    speciesButtons: speciesButtons_found
  }

  return totalButtons;
}



/**
 * FINDE HASH-ADRESSE FÜR TAXON (Link zu PHP)
 **/
async function findButtons(totalButtons, path, meta, z, x_min, x_max, y_min, y_max) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/buttonSearch2.php",
      data: { buttonList: totalButtons, path: path, meta: meta, z: z, x_min: x_min, x_max: x_max, y_min: y_min, y_max: y_max },

      success: function(output) {
        var result = JSON.parse(output);

        // nur gefundene Buttons anzeigen
        // EINGABE: [ { name: "ranges", path: "Karte", found: 1 }
        //            { name: "test",   path: "test", found: 0  }]
        // AUSGABE: [ { name: "ranges", path: "Karte", found: 1 }]
        var foundButtons = result.filter(button => button.found == 1);

        resolve(foundButtons);
      },
      error: function(error) {
        reject(error);
      }
    })
  })
}



module.exports = {
  updateButtons:      updateButtons,
}
