import {Fill, Stroke, Style, Text} from 'ol/style';


export var invisible_Style = new Style({
  stroke: new Stroke({
    color: 'rgba(255,255,255,0)',
    width: 1,
  }),
 fill: new Fill({
    color: 'rgba(255,255,255,0)',
  }),
});

export  var vtFeature_Style = new Style({
  stroke: new Stroke({
    color: 'rgba(200,200,200,0.8)',
    width: 1,
  }),
 fill: new Fill({
    color: 'rgba(20,20,20,0.9)',
  }),
  /** text: new Text({
    textAlign: 'left',
    textBaseline: 'hanging',
    fill: new Fill({
      color: '#fff'
    }),
    stroke: new Stroke({
      color: '#aaa',
      width: 2,
    }),
  }) **/
});

export var vtFeature_selectedStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(200,20,20,0.8)',
    width: 5,
  }),
  fill: new Fill({
    color: 'rgba(200,20,20,0.1)',
  }),
});

/** Gestaltung der Beschriftungen **/
/*
 var style = new Style({
   fill: new Fill({
     color: 'rgba(255, 100, 0, 0.2)'
   }),
   text: new Text({
     textAlign: 'left',
     textBaseline: 'hanging',
     fill: new Fill({
       color: '#fff'
     }),
     stroke: new Stroke({
       color: '#aaa',
       width: 2,
     }),
   })
 });
 */


 export var style = new Style({
   fill: new Fill({
     color: 'rgba(255, 100, 0, 0.2)'
   }),
   text: new Text({
     textAlign: 'left',
     textBaseline: 'hanging',
     fill: new Fill({
       color: '#fff'
     }),
     stroke: new Stroke({
       color: '#aaa',
       width: 2,
     }),
   })
 });


 export var style2 = new Style({
   fill: new Fill({
     color: 'rgba(255, 100, 0, 0.2)'
   }),
   text: new Text({
     textAlign: 'left',
     textBaseline: 'hanging',
     fill: new Fill({
       color: '#fff3b2'
     }),
     stroke: new Stroke({
       color: '#aaa',
       width: 2,
     }),
   }) /** ,         // [DEBUG]
    image: new CircleStyle({
      radius: 2,
      fill: new Fill({
        color: '#ffcc33'
      })
   }) **/
 });


 export var style3 = new Style({
   fill: new Fill({
     color: 'rgba(255, 100, 0, 0.2)'
   }),
   text: new Text({
     textAlign: 'left',
     textBaseline: 'hanging',
     fill: new Fill({
       color: '#fff'
     }),
     stroke: new Stroke({
       color: '#aaa',
       width: 2,
     }),
   })
 });
