import 'ol/ol.css';
import {Map, View} from 'ol';
import TileGrid from 'ol/tilegrid/TileGrid';
import Projection from 'ol/proj/Projection';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import {Group as LayerGroup} from 'ol/layer';
import {TileDebug} from 'ol/source';
import Feature from 'ol/Feature';

// highlight-Animation
import Point from 'ol/geom/Point';
import {easeOut} from 'ol/easing';
import {fromLonLat} from 'ol/proj';
import {getVectorContext} from 'ol/render';
import {unByKey} from 'ol/Observable';

// überprüfen ob alle benötigt
import MVT from 'ol/format/MVT';
import {Fill, Stroke, Style, Text} from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {Vector} from 'ol/source';
import {defaults as defaultControls, Attribution} from 'ol/control';
import {bbox} from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import CircleStyle from 'ol/style/Circle';
import {defaults as defaultInteractions} from 'ol/interaction';

import 'regenerator-runtime/runtime' // zur Unterstützung von async, await; https://flaviocopes.com/parcel-regeneratorruntime-not-defined/

// Funktionen aus anderen Javascript-Dateien
var def = require('./def');
var pre = require('./pre');
var util = require('./util');
var debug = require('./debug');
var styles = require('./styles');
var init = { };
  init.functions = require('./init');
var $ = require('jquery');

var zoomSlider2 = require('./zoomslider2')
var CustomZoomSlider = zoomSlider2.default;

// Versionshinweis
var attribution = $('#attribution').html();


// setze URL je nach Umgebung (Entwicklungsmodus oder produktiv)
  var url_finder_px, url_finder_vt, url_error_px;
  if (process.env.NODE_ENV === "development") {
    // url_finder_px = 'https://lifegate.idiv.de/DubTiles/';       // im lokalen Modus beziehe Kacheln dennoch vom Server
    // url_finder_px = 'tiles_compressed2/'                        // Alternative: komprimierte Kacheln

    url_finder_px = 'tiles_jpg/';                                  // Alternative: echter lokaler Modus
    url_finder_vt = 'tiles/vt2021/';

    // Error URLs
    url_error_px = 'tiles_jpg/error/empty2.png';

  } else {
    url_finder_px = 'tiles_px/';                              // wenn auf dem Server, benutze relative Pfade
    url_finder_vt = 'tiles_vt/';

    // Error URLs
    url_error_px = 'tiles_px/error/empty.png';
  }


  const TILE_SERVER_PX = url_finder_px;
  const TILE_SERVER_VT = url_finder_vt;
  const TILE_ERROR_PX  = url_error_px;

  /*********
     MAIN
  *********/


  /** Vorbereitung der Karte **/
    // KACHELSERVER: Vom Server abgerufenes Raster
    var standardGrid = new TileGrid({
      extent: def.gridExtent,
      resolutions: pre.gridResolutions,
      tileSize: 256
    });

    // PROJEKTION
    var projection = new Projection({
      code: 'simple',
      units: 'pixels',
      extent: def.gridExtent,
    });






/*****************
** KARTENEBENEN **
*****************/

/**
 * RASTER ENTSPR. METAEBENEN LADEN: für jeden Ladeschritt finde die richtige taxonomische Rangstufe
 *                                  und die richtige Zoomstufe {z} des Kachelservers
 * @param  {[type]} tileCoordinate [Position der zu ladenden Kachel]
 * @param  {[type]} mode           [Modus der zu ladenden Kacheln: "px"=Rasterkacheln, "vt"=Vektorkacheln, Standardwert: Rasterkacheln]
 * @return {[type]}                [URL der zu ladenden Kachel in Abhängigkeit von den aktuellen Einstellungen]
 */
function loadTiles(tileCoordinate, mode) {
    // Kachelserver festlegen
    const TILE_SERVER = (mode == "vt" ? TILE_SERVER_VT : TILE_SERVER_PX);
    const FILE_EXT    = (mode == "vt" ? ".pbf"         : ".jpg");

    const currZoom = tileCoordinate[0];
    const x = tileCoordinate[1];
    const y = tileCoordinate[2];
    const z = tileloader.tileserverZs[currZoom];

    // Aktuelle Logik
    var logic = map.get("logic");
    // var meta = util.getMetaForViewZoom(currZoom, logic)["level"];
    var meta = logic[currZoom]['meta'];
    var taxRank = def.taxRank.find(element => element['meta'] == meta);
    var path = taxRank['path'];
    // console.log("mode:", mode, "zoom: ", tileCoordinate[0], "z", z, "meta:", meta);

    // Aktuelle Ansicht
    var perspective_name = map.get("perspective");
    var perspective_url  = util.getPathForPerspective(perspective_name);

    if(mode == "vt") { perspective = ""; }
    // console.log("mode:", mode, "curr_zoom:", currZoom, "x:", x, "y:", y, "z:", z, "meta:", meta, "taxRank:", taxRank, "path:", path);

    // console.log("aktueller Pfad: ", mode + "/" + path + "/" + z);
    return TILE_SERVER
              + path + "/"
              + perspective_url
              + util.urlReplace(x, y, z, '/{z}/{x}/{y}')
              + FILE_EXT;
    }


// zu Testzwecken
/**
return TILE_SERVER
          + 'phylum'
          + util.urlReplace(x, y, z, '/{z}/{x}/{y}')
          + FILE_EXT;
}
**/

    // Instanziierungen der Funktion loadTiles
    const loadTiles_px = function(tileCoordinate) { return loadTiles(tileCoordinate, "px"); };
    const loadTiles_vt = function(tileCoordinate) { return loadTiles(tileCoordinate, "vt"); };



function loadSingleTile_px2(tile, src) {
    function secondAttempt(tile, src) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.addEventListener('loadend', function (evt) {
        var data = this.response;
        if (data !== undefined) {
          var objectUrl = URL.createObjectURL(data);
          tile.getImage().onload  = function(){
            URL.revokeObjectURL(objectUrl);
          }
          tile.getImage().src = objectUrl;
        }

        // ERROR HANDLING
        if (xhr.status == 404) {
          console.log("Fehler1");
          tile.getImage().src = TILE_ERROR_PX;
        }
      });

      xhr.addEventListener('error', function () {
        if (xhr.status == 404) {
          console.log("Fehler2");
          tile.getImage().src = TILE_ERROR_PX;
        }
      });

      //console.log("2nd attempt", src, tile.getImage().src);
      xhr.open('GET', src);
      xhr.send();
    }

    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.addEventListener('loadend', function (evt) {
      var data = this.response;
      if (data !== undefined) {
        var objectUrl = URL.createObjectURL(data);
        tile.getImage().onload  = function(){
          URL.revokeObjectURL(objectUrl);
        }
        tile.getImage().src = objectUrl;
      }

      // ERROR HANDLING
      if (xhr.status == 404) {
        let disassembledUrl = util.urlDisassemble(src, TILE_SERVER_PX);
        let errorUrl = util.findErrorUrl(disassembledUrl);

        secondAttempt(tile, errorUrl);
      }
    });

    xhr.addEventListener('error', function () {
      secondAttempt(tile, src.replace('fancy', 'normal'));
    });
    console.log("1st attempt", src, tile.getImage().src);
    xhr.open('GET', src);
    xhr.send();
}




/**
 * Loading single tiles including error handling
 * adapted from https://gis.stackexchange.com/a/401276/138705
 */

function loadSingleTile_px(tile, src) {
  // console.log("load single tile");
  // console.log(tile, src);

  var xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';

  xhr.addEventListener('loadend', function (evt) {
    // console.log("loadend", evt);

    // First attempt
    // Fallback to default layer
    var data = this.response;
    if (data !== undefined) {
      // console.log("data", data);
      var objectUrl = URL.createObjectURL(data);

      tile.getImage().onload  = function() {
        URL.revokeObjectURL(objectUrl);
      }
      tile.getImage().src = objectUrl;

      // console.log("loading attempt:", src, xhr.status);

    // ERROR HANDLING
    if (xhr.status == 404 || xhr.status == 204) {

      let disassembledUrl = util.urlDisassemble(src, TILE_SERVER_PX);
      // console.log("disassembled URL:", disassembledUrl);

      let errorUrl = util.findErrorUrl(disassembledUrl);
      /* tile.getImage().src = TILE_ERROR_PX; // vereinfachte Error-URL */
      tile.getImage().src = errorUrl;

      // console.log("error1,", "not found:", src, "fallback to:", errorUrl);
      // secondAttempt(tile, errorUrl);
      }
    }

  });

  /*
  xhr.addEventListener('error', function () {
    if (xhr.status == 404) {
      console.log("Fehler3");
      tile.getImage().src = 'error/empty2.png';
    } else {
      console.log("Fehler4");
      tile.getImage().src = 'error/empty2.png';
    }
    */

  xhr.open('GET', src);
  xhr.send();

  // Second attempt
  // [TODO]: hier weiter
  // tile.getImage().src = 'tiles_jpg/error/empty2.png';

  function secondAttempt(tile2, src2) {
    var xhr2 = new XMLHttpRequest();
    xhr2.responseType = 'blob';
    xhr2.addEventListener('loadend', function (evt2) {
      var data2 = this.response;
      if (data2 !== undefined) {
        var objectUrl2 = URL.createObjectURL(data2);
        tile2.getImage().onload  = function() {
          URL.revokeObjectURL(objectUrl2);
        }
        tile2.getImage().src = objectUrl2;
        console.log("loading attempt2:", src2, xhr2.status);

      // ERROR HANDLING
      if (xhr2.status == 404 || xhr2.status == 204) {

        let errorUrl2 = TILE_ERROR_PX;
        console.log("error2,", "not found:", src2, "fallback to:", errorUrl2);
        tile2.getImage().src = errorUrl2;
        console.log("new source set:", tile2.getImage().src);
          }
        }
      });

    /*
    xhr2.addEventListener('error', function () {
        tile.getImage().src = TILE_ERROR_PX;
    });
    */

    // console.log("xhr2 GET");
    xhr2.open('GET', src2);
    xhr2.send();
  }

}



/*
  function loadTiles_vt(tileCoordinate) {
    var mode="vt";
      // Kachelserver festlegen
      const TILE_SERVER = (mode == "vt" ? TILE_SERVER_VT : TILE_SERVER_PX);
      const FILE_EXT    = (mode == "vt" ? ".pbf"         : ".png");

      const currZoom = tileCoordinate[0];
      const x = tileCoordinate[1];
      const y = tileCoordinate[2];
      const z = tileloader.tileserverZs[currZoom];

      var logic = map.get("logic");
      var meta = logic[currZoom]['meta'];
      var taxRank = def.taxRank.find(element => element['meta'] == meta);
      var path = taxRank['path'];

      // console.log("mode:", mode, "curr_zoom:", currZoom, "x:", x, "y:", y, "z:", z, "meta:", meta, "taxRank:", taxRank, "path:", path);

      return TILE_SERVER
                + path
                + util.urlReplace(x, y, z, '/{z}/{x}/{y}')
                + FILE_EXT;
      }
**/

/**
 * Finde Trennzeichen, das am nächsten an der Mitte eines Strings ist
 * @param  {[string]}   Zu bearbeitender string
 * @return {[int]}      Position des besten Trennzeichens
 */
function separatorNearCenter(string) {
  let regex = /\s/gi;
  let match;
  let indices = [];

  // Indizes aller Trennzeichen finden
  while ( (match = regex.exec(string)) ) {
      indices.push(match.index);
  }

  // besten Index finden
  let best     = -1;      // Ausgabe, wenn kein Trennzeichen vorhanden
  let dist     = 999;
  let curr_dist;
  for(let i = 0; i < indices.length; i++) {
    curr_dist = Math.abs(string.length * 0.5 - indices[i]);
    if(curr_dist <= dist) {
      best = indices[i];
      dist = curr_dist;
    }
  }

  return best;
}


/** STYLEFUNKTIONEN **/
function fullStyle(feature) {

  var tx_name = feature.get('name');
  var tx_name_cmn = feature.get('name_cmn');
  var tx_fsize_rel = 1 / feature.get('fsize');
  var tx_level  = feature.get('level');

  var resolution = map.getView().getResolution();
  var gridZoom = util.getGridZoomForResolution(resolution, "unrounded");  // = "internalZoom"

  // Wissenschaftliche Namen (Schriftgröße aus Datenbank abgerufen): Textgestaltung bestimmen
  var style_fsize_px = util.fsizeToPx(tx_fsize_rel, gridZoom);
  var style_fstyle   = style_fsize_px + 'px ' + def.fstyle;

  const text_height_width_ratio = 0.5;

  var scaling_factor = tx_level < 7 ? 0.4 : 0.7;    // Vernakulärnamen größer auf Artebene

  // Vernakulärnamen (Schriftgröße und Verschiebung berechnet): Textgestaltung bestimmen
  var style2_fsize_px = Math.min(Math.max(10, style_fsize_px*scaling_factor), style_fsize_px);   // Größe aus wissenschaftlichen Namen bestimmen (Skalierungsfaktor Geschmackssache)
  var style2_fstyle   = style2_fsize_px + 'px/1 ' + def.fstyle;
                                                 // px gibt die Höhe des Texts an -> gesucht wird die Breite, hier mit textWidth geschätzt
  var maxWidth = util.textWidth(tx_name_cmn);

      // sehr große Texte trennen
      if(util.textWidth(tx_name_cmn) > 2.5 * util.textWidth(tx_name)) {

        let separatorIndex = separatorNearCenter(tx_name_cmn);
        if(separatorIndex > 0) {
          let tx_name_cmn1   = tx_name_cmn.substring(0, separatorIndex);
          let tx_name_cmn2   = tx_name_cmn.substring(separatorIndex+1);

          if(util.textWidth(tx_name_cmn1) > util.textWidth(tx_name_cmn2)) {
              maxWidth       = util.textWidth(tx_name_cmn1);
              tx_name_cmn2   = tx_name_cmn2.padStart(parseInt(maxWidth)*2.8, " ");
          } else {
              maxWidth       = util.textWidth(tx_name_cmn2);
              tx_name_cmn1   = tx_name_cmn1.padStart(parseInt(maxWidth)*2.8, " ");
          }

          tx_name_cmn    = tx_name_cmn1 + "\n" + tx_name_cmn2;
              // console.log("maxWidth", maxWidth);

        }
      }


  var style2_offsetX  = util.textWidth(tx_name)*style_fsize_px*0.5 - maxWidth*style2_fsize_px*0.5;
  var style2_offsetY  = Math.pow(style_fsize_px,0.9)+style_fsize_px*0.2;
                                                // empirisch

 // console.log("name:", tx_name);
 // console.log("fsize:", style_fsize_px, "\n");

  // Text zum Style hinzufügen
  var style = styles.style;
  var style2 = styles.style2;
  var style3 = styles.style3;
  style.getText().setText(tx_name);
  style.getText().setFont(style_fstyle);

  style2.getText().setText(tx_name_cmn);
  style2.getText().setFont(style2_fstyle);
  style2.getText().setOffsetX(style2_offsetX);
  style2.getText().setOffsetY(style2_offsetY);
  style3.getText().setText("");


  // Manuelle Nachbearbeitungen:
  // Nachbearbeitung auf Domänenebene:
  if(tx_level == 1 && tx_name.includes("Unikonta") || tx_name.includes("Bikonta")) {

    let style3_offsetX = 0;

    if(tx_name == "Unikonta") {
      style3_offsetX = util.textWidth("Unikonta")*style_fsize_px*0.5 - util.textWidth("Eukaryota")*style2_fsize_px*0.5;
    } else if(tx_name == "Bikonta") {
      style3_offsetX = util.textWidth("Bikonta")*style_fsize_px*0.5 - util.textWidth("Eukaryota")*style2_fsize_px*0.5;
    }

    let style3_offsetY = -style_fsize_px*0.4;
    style2.getText().setOffsetX(style2_offsetX);
    style2.getText().setOffsetY(style2_offsetY);

    style3.getText().setFont(style2_fstyle);
    style3.getText().setOffsetX(style3_offsetX);
    style3.getText().setOffsetY(style3_offsetY);
    style3.getText().setText("Eukaryota");


  // Nachbearbeitung auf Artebene
  } else if(tx_level >= 7) {
    var tx_replaced = tx_name.replace(" ", "\n")
    style.getText().setText(tx_replaced);
    style.getText().setTextAlign("center");
    style2_offsetY += style_fsize_px*1.5;
    style2.getText().setOffsetX(0);
    style2.getText().setOffsetY(style2_offsetY);
    style2.getText().setTextAlign("center");
  } else {
    style.getText().setTextAlign("left");
    style2.getText().setTextAlign("left");
  }

 // überprüfe ob dieses Feature wirklich angezeigt werden soll:
  if(tx_level <= def.tolerantLevel || style_fsize_px >= def.fsize_min) {
    return [style, style2, style3];
 } else {
   // console.log("nicht gezeichnet:", tx_name, "Größe:", style_fsize_px);
   return null;
   // siehe auch: https://stackoverflow.com/questions/6528243/how-to-hide-vector-features-in-openlayers
 }
}

// TODO: nach Überarbeitung der Anzeigefunktionen für die Schrift entfernen
function partStyle(feature) {
    var tx_name = feature.get('name');
    var tx_name_cmn = feature.get('name_cmn');
    var tx_fsize_rel = feature.get('fsize');
    var tx_level  = feature.get('level');

    var resolution = map.getView().getResolution();
    var gridZoom = util.getGridZoomForResolution(resolution, "unrounded");

    // Wissenschaftliche Namen (Schriftgröße aus Datenbank abgerufen): Textgestaltung bestimmen
    var style_fsize_px = util.fsizeToPx(tx_fsize_rel, gridZoom);
    var style_fstyle   = style_fsize_px + 'px ' + def.fstyle;

    const text_height_width_ratio = 0.5;

    // Vernakulärnamen (Schriftgröße und Verschiebung berechnet): Textgestaltung bestimmen
    var style2_fsize_px = Math.min(Math.max(10, style_fsize_px*scaling_factor), style_fsize_px);   // Größe aus wissenschaftlichen Namen bestimmen (Skalierungsfaktor Geschmackssache)
    var style2_fstyle   = style2_fsize_px + 'px/1 ' + def.fstyle;
                                                   // px gibt die Höhe des Texts an -> gesucht wird die Breite, hier mit textWidth geschätzt
    var maxWidth = util.textWidth(tx_name_cmn);

        // sehr große Texte trennen
        if(util.textWidth(tx_name_cmn) > 2.5 * util.textWidth(tx_name)) {

          let separatorIndex = separatorNearCenter(tx_name_cmn);
          if(separatorIndex > 0) {
            let tx_name_cmn1   = tx_name_cmn.substring(0, separatorIndex);
            let tx_name_cmn2   = tx_name_cmn.substring(separatorIndex+1);

            if(util.textWidth(tx_name_cmn1) > util.textWidth(tx_name_cmn2)) {
                maxWidth       = util.textWidth(tx_name_cmn1);
                tx_name_cmn2   = tx_name_cmn2.padStart(parseInt(maxWidth)*2.8, " ");
            } else {
                maxWidth       = util.textWidth(tx_name_cmn2);
                tx_name_cmn1   = tx_name_cmn1.padStart(parseInt(maxWidth)*2.8, " ");
            }

            tx_name_cmn    = tx_name_cmn1 + "\n" + tx_name_cmn2;
                // console.log("maxWidth", maxWidth);

          }
        }

    var style2_offsetX  = util.textWidth(tx_name)*style_fsize_px*0.5 - util.textWidth(tx_name_cmn)*style2_fsize_px*0.5;
    var style2_offsetY  = Math.pow(style_fsize_px,0.9)+style_fsize_px*0.2;    // empirisch


    // Text zum Style hinzufügen
    var style = styles.style;
    var style2 = styles.style2;
    var style3 = styles.style3;
    style.getText().setText(tx_name);
    style.getText().setFont(style_fstyle);

    style2.getText().setText(tx_name_cmn);
    style2.getText().setFont(style2_fstyle);
    style2.getText().setOffsetX(style2_offsetX);
    style2.getText().setOffsetY(style2_offsetY);

    style3.getText().setText("");


    // Manuelle Nachbearbeitungen:
    // Nachbearbeitung auf Domänenebene:
    if(tx_level == 1 && tx_name.includes("Unikonta") || tx_name.includes("Bikonta")) {
      let style3_offsetX = 0;

      if(tx_name == "Unikonta") {
        style3_offsetX = util.textWidth("Unikonta")*style_fsize_px*0.5 - util.textWidth("Eukaryota")*style2_fsize_px*0.5;
      } else if(tx_name == "Bikonta") {
        style3_offsetX = util.textWidth("Bikonta")*style_fsize_px*0.5 - util.textWidth("Eukaryota")*style2_fsize_px*0.5;
      }

      let style3_offsetY = -style_fsize_px*0.4;
      style2.getText().setOffsetX(style2_offsetX);
      style2.getText().setOffsetY(style2_offsetY);

      style3.getText().setFont(style2_fstyle);
      style3.getText().setOffsetX(style3_offsetX);
      style3.getText().setOffsetY(style3_offsetY);
      style3.getText().setText("Eukaryota");


    // Nachbearbeitung auf Artebene
    } else if(tx_level >= 7) {
      style.getText().setTextAlign("center");
      style2.getText().setTextAlign("center");
    }

    // überprüfe ob dieses Feature wirklich angezeigt werden soll:
    if(tx_level <= def.tolerantLevel || style_fsize_px >= def.fsize_min) {    // hier nochmal getestet (d. h. nicht nur bei der Datenabfrage), um einheitliches Verhalten beim Herauszoomen (zu kleine Labels etnfernen)
      return [style, style3];
   } else {
     return null;
   }
}

function vectorTileStyle(feature) {
  var colour = map.get("color");
  var vtFeature_Style = style.vtFeature_Style
      vtFeature_Style.getStroke().setColor(colour);

  /* Problem: setzt Text in die Mitte jeder Kachel
    var tx_name = feature.get('name');
    var style_fstyle   = '8px ' + def.fstyle;

    // Text zum Style hinzufügen
    vtFeature_Style.getText().setText(tx_name);
    vtFeature_Style.getText().setFont(style_fstyle);
 */
    return [vtFeature_Style];
}








  // STEUERELEMENTE DER KARTE
  var attribution2 = new Attribution({
    target: 'attribution_Box',
  });



/**
 * INITIALISIEREN: VARIABLEN DER KARTE FÜR AKTUELLE KONFIGURATION DES NUTZERS
 **/
 var map;
 var virtualTileGrid;
 var zoomslider;
 var currentView, standardView;
 var startView, startLogic, startPerspective;
 var rasterLayer_tiles;
 var vectorSource_lbl, vectorLayer_lbl, vectorLayer_tiles, vectorLayer_points;
 startMap();

 // TILELOADER
 var tileloader;


  function startMap() {

    // ZOOMSCHRITTE: Passe navigierbare Zoomstufen entsprechend der Bildschirmgröße an
      //  angepasst von:   https://gis.stackexchange.com/questions/344604/openlayers-smoothly-change-tile-source-on-zoom
      //            und    https://github.com/openlayers/openlayers/issues/10432   https://codesandbox.io/s/simple-bg7d4
    var initZoomVar = init.functions.initializeMap();
      init.autoZoom_modified = initZoomVar['zoomLogic'];
      init.viewResolutions = initZoomVar['viewResolutions'];
      init.minZoom = initZoomVar['options']['minZoom'];             // [TODO]: überarbeiten, abhängig von zoomLogics
      init.maxZoom = initZoomVar['options']['maxZoom'];             // [TODO]: überarbeiten, abhängig von zoomLogics
      init.startZoom = initZoomVar['options']['startZoom'];
      init.zoomLogics = initZoomVar['zoomLogic2'];

    // LADESCHRITTE: Ermögliche den Aufruf von tileUrlFunction für jeden einzelnen Zoomschritt (auch ohne Änderung von {z})
    // -> https://gis.stackexchange.com/questions/344604/openlayers-smoothly-change-tile-source-on-zoom

    // Tileloader zurücksetzen
    tileloader = { };
    tileloader.virtualResolutions = [];
    tileloader.virtualTileSizes = [];
    tileloader.tileserverZs = [];

    init.viewResolutions.forEach(function(resolution) {
        // Finde Schritte
        const tileserverZ = standardGrid.getZForResolution(resolution);
        const standardResolution = standardGrid.getResolution(tileserverZ);
        const standardTileSize = standardGrid.getTileSize(tileserverZ);

        const virtualTileSize = Math.round((standardTileSize * standardResolution) / resolution);
        const virtualResolution = (standardResolution * standardTileSize) / virtualTileSize;

        // Speichere Schritte
        tileloader.virtualResolutions.push(virtualResolution);
        tileloader.virtualTileSizes.push(virtualTileSize);
        tileloader.tileserverZs.push(tileserverZ);
      });

    virtualTileGrid = new TileGrid({
      resolutions: tileloader.virtualResolutions,
      tileSizes: tileloader.virtualTileSizes,
      extent: def.gridExtent
    });

    standardView = customizeView(init.viewResolutions);

    // bearbeite den View für alle Zoomlogiken
    init.zoomLogics.forEach(function(zoomElement) {
      var completeResolutions = standardView.getResolutions();
      var minZoom = parseInt(zoomElement['minZoom']);
      var maxZoom = parseInt(zoomElement['maxZoom']);
      var constraintResolutions = completeResolutions.slice(minZoom, maxZoom+1);      // minZoom, maxZoom starten ebenfalls bei 0 wie die Array-Indizes
      var constraintView =  customizeView(constraintResolutions);
      zoomElement['view'] = constraintView;
    });

    function constrainResolutions(minZoom, maxZoom) {
      return completeResolutions.slice(minZoom, maxZoom);
    }


    zoomslider = new CustomZoomSlider({
        duration: 200,
        globalLogic: util.findLogic('auto', init.zoomLogics),       // logicWithOptions
    });

    zoomslider.setTarget(document.getElementById('zoomSlider'));  //    definiere CSS-Div-Element des Zoomcontainers (muss vor Definition in map [controls] festgelegt werden)
                                                                    // -> siehe  https://gis.stackexchange.com/a/253409 https://openlayers.org/en/latest/apidoc/module-ol_control_ZoomSlider-ZoomSlider.html

    // Startansicht aus Hash festlegen
    startView  = standardView;
    startLogic = util.findLogic('auto', init.zoomLogics)['logic'];

    // Startansicht festlegen
    startPerspective = "def";




    /** KARTENEBENEN **/
    /**
     * KARTENEBENE: Rasterkacheln
     * @type {TileLayer}
     */
      rasterLayer_tiles = new TileLayer({
            source: new XYZ({
              tileGrid: virtualTileGrid,
              tileUrlFunction: loadTiles_px,
              tileLoadFunction: loadSingleTile_px,
              zDirection: 0,
              transition: 250,
              attributions: [ attribution ]
            }),
            preload: 0,                         // ACHTUNG: Das wären Kacheln mit niedrigerer Auflösung!  // [TODO]: ???
            useInterimTilesOnError: false,      // verhindert das Laden falscher Kacheln
            zIndex: 1
          });

      /** Fehlerbehandlung
        rasterLayer_tiles.getSource().on("tileloaderror", errorFunction);
        function errorFunction(e) {
          console.log("src_", e.tile.src);
          console.log("tileCoord", e.tile.tileCoord);
          console.log("event", e.tile);

          var replacement_Tile = "tiles_jpg/class/2/2/1.jpg";
          e.tile.src_ = replacement_Tile;
          e.tile.load();
        }
    **/


      /**
       * KARTENEBENE: Vektorkacheln
       * @type {TileLayer}
       */
      // -> https://openlayers.org/en/latest/apidoc/module-ol_layer_VectorTile-VectorTileLayer.html
          vectorLayer_tiles = new VectorTileLayer({
                  source: new VectorTileSource({
                    format: new MVT(),
                    tileGrid: virtualTileGrid,
                    tileUrlFunction: loadTiles_vt,
                    zDirection: 0,
                    transition: 0,
                    attributions: [ attribution ],
              }),

              preload: 0,
              useInterimTilesOnError: false,
              zIndex: 2,
              // renderBuffer: 80,
              style: styles.vectorTileStyle,
            })

      // console.log("vt/zDirection:", vectorLayer_tiles.getSource().zDirection);
      // console.log(rasterLayer_tiles);
      // console.log(vectorLayer_tiles);


      /**
       * KARTENEBENE: Vektorquelle mit aktuellen Beschriftungen
       * @type {Vector}
       */
       vectorSource_lbl = new Vector({                    // von ol/source (daher der wenig verständliche Name)
        format: new GeoJSON(),
        // wrapX: false,
        overlaps: false,                                    // Optimierung; gesetzt, da Punkte mit Schriften nicht überlappend

        /**
         * Wird aufgerufen bei Änderung von @param extent und @param resolution
         * Gibt an, welcher Bereich der Karte geladen werden soll und überwacht Löschen des Caches.
         * @param  {[type]} extent     Sichtbarer Kartenbereich
         * @param  {[type]} resolution Aktuelle Zoomstufe als Resolution
         * @return {[type]}            Zu ladender Kartenbereich abzgl. Cache
         */
         /* aus der Dokumentation von OpenLayers:
          * A function that takes an module:ol/extent~Extent and a resolution as arguments,
          * and returns an array of module:ol/extent~Extent with the extents to load.
          * Usually this is one of the standard module:ol/loadingstrategy strategies.
          */
        strategy: function(extent, resolution) {
          // console.log("current feature count:", vectorSource_lbl.getFeatures().length);
          // console.log("~› Strategy.")
          // console.log("labelStatus:", map.get("labelStatus"), "\n");

          var current_resolution = map.getView().getResolution();                                 // aktuelle Kartenauflösung
          var current_gridZoom = util.getGridZoomForResolution(current_resolution, "unrounded");

          updateMeta();

          var buffer  = util.fsizeToDB(def.buffer, current_gridZoom);
          var rounder = Math.round(buffer);

          var bufferedExtent0_temp = util.round(extent[0] - buffer, rounder);
          var bufferedExtent1_temp = util.round(extent[1] - buffer, rounder);
          var bufferedExtent2_temp = util.round(extent[2] + buffer, rounder);
          var bufferedExtent3_temp = util.round(extent[3] + buffer, rounder);

          var bufferedExtent0 = bufferedExtent0_temp < def.contentExtent[0] ? def.contentExtent[0] : bufferedExtent0_temp;
          var bufferedExtent1 = bufferedExtent1_temp < def.contentExtent[1] ? def.contentExtent[1] : bufferedExtent1_temp;
          var bufferedExtent2 = bufferedExtent2_temp > def.contentExtent[2] ? def.contentExtent[2] : bufferedExtent2_temp;
          var bufferedExtent3 = bufferedExtent3_temp > def.contentExtent[3] ? def.contentExtent[3] : bufferedExtent3_temp;

          var bufferedExtent = [bufferedExtent0, bufferedExtent1, bufferedExtent2, bufferedExtent3];

          /*
          console.log("unbuffered extent", extent);
          console.log("  buffered extent", bufferedExtent);
          console.log("\n");
          */

          var prev_labelStatus = map.get("labelStatus")       //  { meta: ..., time: ..., extent: [], fsize_max: 0 }
          var prev_zoom = prev_labelStatus["gridZoom"];
          var prev_meta = prev_labelStatus["metaZoom"];
          var prev_time = prev_labelStatus["time"];
          var prev_extent = prev_labelStatus["extent"];

          var new_meta = map.get("meta")['level'];
          var new_time = Date.now();
          var new_extent = bufferedExtent;
          var new_zoom = current_gridZoom;                      // nur eine Umbenennung für die Einheitlichkeit

          var fsize_loaded = prev_labelStatus["fsize_loaded"];  // das wird erst im Loader aktualisiert (da das Laden einige Zeit dauern kann)

          var new_labelStatus = { gridZoom: new_zoom, metaZoom: new_meta, time: new_time, extent: new_extent, fsize_loaded: fsize_loaded };

          // console.log("\nprev_labelStatus", prev_labelStatus);
          // console.log("new_labelStatus", new_labelStatus, "\n");

          // UNTERSCHIEDLICHE ABFRAGEN UM AUFRUF DES LOADERS ZU OPTIMIEREN (nicht zu oft, nicht zu selten aufrufen)

          // Metazoom ändert sich: Loader immer aufrufen
          if(prev_meta != new_meta) {
            new_labelStatus['fsize_loaded'] = -1;       // geladene Schriftgrößen zurücksetzen
              map.set("labelStatus", new_labelStatus);
            // console.log("new meta, loading \n");
            updateLabels();                             // Daten neu laden (refresh = clear + reload)
            return [bufferedExtent];                    // Loader aufrufen (Extent übergeben)
          }

          // Metazoom ändert sich nicht; zu kurze Zeit zwischen zwei Abfragen: Abfrage blockieren
          if(new_time - prev_time < 250) {
            new_labelStatus = prev_labelStatus;
              new_labelStatus["gridZoom"] = new_zoom;     // aktualisiere den Gridzoom (wichtig, damit Panning richtig erkannt wird), aber behalte vorherigen Zeitstempel bei
              map.set("labelStatus", new_labelStatus);
              // console.log("timing, no loading \n");         // Loader nicht aufrufen (leerer Extent)
            return [];
          }

          // Metazoom ändert sich nicht, Zoom hinein: nur bislang nicht geladene Schriftgrößen laden
          if(new_zoom - prev_zoom > 0.02) {
            this.loadedExtentsRtree_.clear();           // Information über bisher geladene Bereiche löschen
            map.set("labelStatus", new_labelStatus);
            // console.log("Zoom in, Rtree cleared & loading \n", new_labelStatus);
            return [bufferedExtent];
          }

          // Metazoom ändert sich, aber nicht genug: Loader nicht aufrufen
            if(new_zoom - prev_zoom > 0.005) {
            // console.log("insufficient zoom, no loading; delta zoom:", new_zoom - prev_zoom);
            return [];

          // Metazoom ändert sich nicht, gridZoom auch nicht: Panning oder Zoomout
          } else {
            if(prev_zoom - new_zoom > 0.02) {
              this.loadedExtentsRtree_.clear();          // bei Zoomout: Information über bisher geladene Bereiche löschen
              // console.log("cleared in zoom out")
            }
            new_labelStatus["fsize_loaded"] = -1;       // Schriftgröße zurücksetzen (speichere nur für Zoom-in)
            map.set("labelStatus", new_labelStatus);
            // console.log("panning or zoom out, loading \n");
            return [bufferedExtent];
          }
        },


        /**
         * Lädt die Vektordaten aus der Datenbank für einen bestimmten Kartenbereich und Zoom
         * @param  {[type]} extent     Zu ladender Bereich (aus Strategy)
         * @param  {[type]} resolution Aktueller Zoom als resolution
         * @param  {[type]} projection Aktuelle Projektion
         * @return {[type]}            [description]
         */
          loader: function(extent, resolution, projection) {
            // console.log("LOADER");
            // console.log("~› Loader:");


            // aktualisiere MetaZoom
            var curr_logic = map.get('logic');
            var new_viewZoom = map.getView().getZoomForResolution(resolution);
            var new_metaZoom = util.getMetaForViewZoom(new_viewZoom, curr_logic);
            var level = new_metaZoom['level'];


            // Anpassung an Format der Datenbank
             var xmin = extent[0];
             var xmax = extent[2];
             var ymin = -extent[3];
             var ymax = -extent[1];

             // console.log("xmin: ", xmin, "xmax: ", xmax, "ymin: ", ymin, "ymax:", ymax);


            // Zoomstufe
            var current_resolution = map.getView().getResolution();                                 // aktuelle Kartenauflösung
            var current_gridZoom = util.getGridZoomForResolution(current_resolution, "unrounded");

            // Minimale zu ladende Schriftgröße ermitteln
              // da im LifeGate2021 die Schriftgröße der Datenbank sinkt, wenn die Schrift größer werden soll, muss hier mit 1/ operiert werden
              // außerdem sind in der Datenbankabfrage unten fsize_min und fsize_max vertauscht (Abfrage x1 BETWEEN x2) mit x1 < x2
            var current_resolution = map.getView().getResolution();                                 // aktuelle Kartenauflösung
            var current_gridZoom = util.getGridZoomForResolution(current_resolution, "unrounded");  // aktueller Zoom
            var fsize_min;

            if(level <= def.tolerantLevel) {
              fsize_min = 1 / util.fsizeToDB(def.fsize_min_tolerantLevel, current_gridZoom);
            } else {
              fsize_min = 1 / util.fsizeToDB(def.fsize_min, current_gridZoom);
            }

            var fsize_max = map.get("labelStatus")["fsize_loaded"];

            var lang = document.documentElement.lang;

            console.log("start loading labels\n")
            // Lade Beschriftungen aus Datenbank
             $.ajax({
               type: "POST",
               url: "php/label2.php",
               data: { x_min: xmin, x_max: xmax, y_min: ymin, y_max: ymax, fsize_min: fsize_max, fsize_max: fsize_min, level: level, lang: lang},
               success: function(output) {

                 // ABGERUFENE FEATURES AUS JSON IN JAVASCRIPT-OBJEKT UMWANDELN
                 var result = JSON.parse(output);
                 // console.log("PHP/Output:", output);
                 // console.log("PHP/Result:", result);
                 // var oldFeatures = vectorSource_lbl.getFeatures();

                // NEUE FEATURES LADEN
                var currentFeatures = vectorSource_lbl.getFeatures();
                var newFeatures = vectorSource_lbl.getFormat().readFeatures(result);

                // console.log("newFeatures", newFeatures);
                // console.log("length", newFeatures.length);

                // PRÜFE, OB GELADENE FEATURES NOCH DEM AKTUELLEN KARTENZUSTAND (Meta-Zoom)  ENTSPRECHEN
                // ganz wichtig, da asynchrone Abfrage; sonst würden evtl. veraltete Features geladen
                if(newFeatures.length > 0) {
                  if(newFeatures[0].get("level") == map.get("meta")['level']) {       // falls Metazoom noch aktuell ...
                    vectorSource_lbl.addFeatures(newFeatures);                        // ... füge geladene Features in Karte ein (Duplikatfilterung über ID automatisch durch Leaflet)

                    var curr_labelStatus = map.get("labelStatus");
                        curr_labelStatus['fsize_loaded'] = fsize_min;                 // aktualisiere geladene Schriftgröße
                    map.set("labelStatus", curr_labelStatus);

                  } else {                                                            // falls Metazoom nicht mehr aktuell ...
                     // console.log("stop loading and update labels after level mismatch");
                     var curr_labelStatus = map.get("labelStatus");
                         curr_labelStatus['fsize_loaded'] = -1;                       // ... setze Liste mit geladenen Schriftgrößen zurück ...
                     map.set("labelStatus", curr_labelStatus);
                     updateLabels();                                                  // ... und erzwinge Aktualisierung der Labels
                     return;                                                          // ... stoppe Ausführung
                  }

                }


                // CACHE-OPERATIONEN (Ziel: Vermeiden, dass zu viele Beschriftungen im Hintergrund geladen bleiben, Speicherleaks vermeiden)
                var cache            = vectorSource_lbl.getFeatures();
                var cacheSize        = cache.length;
                var allowedCacheSize = def.cache;

                if(cache.size > allowedCacheSize) {
                  var curr_features   = vectorSource_lbl.getFeaturesInExtent(extent);

                  // Sortiere geladene Features nach Schriftgröße (mit kleinster angezeigter Schriftgröße zuerst => solche mit Attribut fsize groß zuerst)
                  cache.sort(function(e1, e2) {
                    return e2.get("fsize") - e1.get("fsize");
                  })

                  var fsize_min_new = fsize_min;
                  for(let k = 0; k < cacheSize - allowedCacheSize; k++) {                         // lösche Features oberhalb der erlaubten Cachegröße ihrer Schriftgröße nach (oben geordnet) ...
                    var feature = cache[k];
                    if(!curr_features.includes(feature) || feature.get("fsize") > fsize_min) {    // ... sofern diese Features im aktuellen Bildausschnitt nicht zu sehen sind
                      // nicht sichtbar wegen Panning   || nicht sichbar wegen Zoomout

                      fsize_min_new = feature.get("fsize");                                       // ... speichere die letzte gelöschte Schriftgröße
                      vectorSource_lbl.removeFeature(feature);
                      // console.log("removed:", feature.get("name"), feature);
                    }
                    var curr_labelStatus = map.get("labelStatus");
                        curr_labelStatus['fsize_loaded'] = fsize_min_new;                         // ... und setze Liste mit geladenen Schriftgrößen auf diese Schriftgröße zurück
                    map.set("labelStatus", curr_labelStatus);

                  }
                }

               },
               error: function(error) {
                 console.log("error in vectorSource")
                 vectorSource_lbl.removeLoadedExtent(extent);
               }
             });
           }
         });



       /**
        * KARTENEBENE: Vektorebene mit Beschriftungen
        * @type {TileLayer}
        */
       vectorLayer_lbl = new VectorLayer({
            updateWhileAnimating: true,         // Schriftgröße bei Animation konstant halten
            updateWhileInteracting: true,       // Schriftgröße bei Interaktion (z. B. Zoomslider) konstant halten
            source: vectorSource_lbl,
            style: fullStyle,
            zIndex: 3


            /**
              // Center + Offset?  (Text exakt positionieren?)
              // https://gis.stackexchange.com/questions/132480/get-center-of-geometry-in-openlayers-3
            **/
        });



       /**
        * KARTENEBENE: Vektorebene mit Punkten
        * @type {TileLayer}
        */
       vectorLayer_points = new VectorLayer({
          source: new VectorSource({
              // Attribute
          }),
          zIndex: 100
        });


    /**************************
     *   ERSTELLUNG DER KARTE *
     **************************/
    map = new Map({

      // VERSCHIEDENE EINSTELLUNGEN
      target: 'map',
      controls: defaultControls({
          zoomOptions: {
          target: 'hud-zoom',
          delta: 2
        }
        }).extend([
        // debug.mousePositionControl,
        zoomslider
      ]),
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false,
        // Rotation vermeiden:
        // -> https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
      }),
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
      projection: projection,

      // LAYERS-ATTRIBUT
      layers: [
          // vectorLayer_tiles,
          rasterLayer_tiles,
          vectorLayer_lbl,
          vectorLayer_points,
          // debug.tiledebug
      ],

      // VIEW-ATTRIBUT
      view: startView,
    });

  // BENUTZERDEFINIERTE ATTRIBUTE (zugänglich mit get() und set())
  var labelStatus = {
    metaZoom: -1,
    gridZoom: -1,
    time: 0,
    extent: [],
    fsize_loaded: -1,
  }

  // Attribute nach erstem Laden der Karte
  map.set('meta', util.getMetaForViewZoom(init.startZoom, init.autoZoom_modified));       // anfänglicher Metazoom
  map.set('color', util.getColourForMeta(map.get('meta')['level']))
  map.set('logic', startLogic);
  map.set('perspective', startPerspective);
  map.set('fsizeLoaded_max', 0);
  map.set('lastLoadedZoom', undefined);
  map.set('labelStatus', labelStatus);

  currentView = map.getView();


}   // Ende startMap();



/**
 * HILFSFUNKTIONEN DER KARTE
 */

  // ANSICHTEN DER KARTE: Stelle Ansichten (Views) der Karte bereit, mit angepassten Optionen für jede Logik
  // so kompliziert, weil minZoom/maxZoom nicht ohne weiteres anpassbar, wenn resolutions verwendet & resolutions können nach Erstellung des Views nicht mehr verändert werden
  // -> https://gis.stackexchange.com/questions/384621/openlayers-constrain-view-zoom-temporarily-after-having-it-set-up-with-resoluti
  function customizeView(customResolutions) {
      return new View({
        center: [def.contentExtent[2]/2, def.contentExtent[1]/2],                                                 // [935, -455]
        extent: [def.contentExtent[0], def.contentExtent[1], def.contentExtent[2], def.contentExtent[3]],         // [0, -910, 1870, 0]
        constrainOnlyCenter: true,                                                                                // muss true sein, ansonsten können subScreen-Ebenen nicht geladen werden und der startZoom wird überschrieben!
        resolutions: customResolutions,
        zoom: init.startZoom,             // startZoom
        constrainResolution: true         // beschränkt den ViewZoom auf die eingestellten, ganzzahligen Stufen
                                          // maxZoom und minZoom können hier nicht eingesetzt werden (werden wegen resolutions ignoriert)
      });
    };

    // Feature an der ausgewählten Position der Karte finden: lade Vektorkachel und finde Feature
    async function featureOnPosition(e) {
            // var rangeExtent = [e.coordinate[0]-4000, e.coordinate[1]-2000, e.coordinate[0]+2000, e.coordinate[1]+4000];          // Testextents
            // var pointExtent = [e.coordinate[0]-0.0001, e.coordinate[1]-0.0001, e.coordinate[0]+0.0001, e.coordinate[1]+0.0001];


            // Aktuelle Logik
            var currZoom = Math.round(map.getView().getZoom());
            var logic = map.get("logic");
            var meta = util.getMetaForViewZoom(currZoom, logic)["level"];

            // Metaebene finden
            var path = util.getPathForMeta(meta);

            // Z-Ebene finden, von der Vektorkachel abgefragt werden soll (fest über Lookup)
            var z_lookup = util.getLookupForMeta(meta);
            var z_current = tileloader.tileserverZs[currZoom];
            var z_diff = z_lookup - z_current;

            // Konfiguriere Abfrage der Vektorkachel
            var viewZoom = parseInt(Math.round(map.getView().getZoom()));
            var tileSizes = rasterLayer_tiles.getSource().getTileGrid().tileSizes_;
            var curr_tileSize = tileSizes[viewZoom];
            var resolutions = rasterLayer_tiles.getSource().getTileGrid().resolutions_;
            var curr_resolution = resolutions[viewZoom];

            var tileScaling = Math.pow(2, z_diff);    // finde Verhältnis der abgefragten zur aktuellen Vektorkachel


            // das TileGrid bestimmt die Überlagerung der zu lagernden Kachel auf der angezeigten Kartenebene
            var temporaryGrid = new TileGrid({
              extent: def.gridExtent,
              resolutions: [curr_resolution],       // aktuelle Resolution
              tileSize: curr_tileSize/tileScaling,  // verkleinert (bzw. vergrößert) um Differenz
            });


            // finde einzelne zu ladende Kachel und zu ladenden Bereich
            let coord_x = e.coordinate[0];
            let coord_y = e.coordinate[1];
            let tilesPerAxis = Math.pow(2, z_lookup);
            let coords_singleTile = def.gridExtent[2] / tilesPerAxis;
            let parts_x = Math.floor(coord_x / coords_singleTile);
            let parts_y = Math.floor(coord_y / coords_singleTile);
            let min_x =  parts_x * coords_singleTile;
            let max_x =  (parts_x+1) * coords_singleTile;
            let min_y =  (parts_y) * coords_singleTile;
            let max_y =  (parts_y+1) * coords_singleTile;

            var rangeExtent2 = [min_x, min_y, max_x, max_y];
            var tile_x = parseInt(parts_x);
            var tile_y = parseInt(-parts_y-1);

            // zu landende temporäre Ebene
            var temporaryLayer = new VectorTileLayer({
                  source: new VectorTileSource({
                    format: new MVT({
                      idProperty: 'id',
                    }),
                    tileGrid: temporaryGrid,
                    tileUrlFunction: function(tileCoordinate) {
                      // console.log("tileCoordinate", tileCoordinate);
                      const currZoom = tileCoordinate[0];
                      const x = tileCoordinate[1];
                      const y = tileCoordinate[2];
                      const z = z_lookup;

                      if(x == tile_x && y == tile_y) {    // lade nur eine Kachel (an der Klickposition)
                        return TILE_SERVER_VT + path + util.urlReplace(tile_x, tile_y, z, '/{z}/{x}/{y}') + '.pbf';
                      } else {
                        return;
                      }
                    },
                    zDirection: 0,

              }),
              extent: rangeExtent2,   // funktioniert auch mit rangeExtent, da ohnehin immer nur eine Kachel
              preload: 0,
              useInterimTilesOnError: false,
              zIndex: 5,
              style: function() {
                if (!$("#debug").is(':checked')) {
                  return styles.invisible_Style;           // unsichtbar im normalen Modus
                } else {
                  return styles.vtFeature_Style;           // sichtbar im Debugmodus
                }
              }
            });


            // Ausführung
            map.addLayer(temporaryLayer);
            var featureAtPosition = await findFeature(e, temporaryLayer);
                featureAtPosition.properties_["meta"] = meta;
                // ANMERKUNGEN
                // 1.) diese Information sollte auch in der Vektorkachel enthalten sein (properties -> layer (z. B. phylum))
                // muss allerdings vor Erzeugung der Vektorkacheln gesetzt werden, deshalb aufwändig zu korrigieren, wenn vergessen
                // indem das Attribut hier gesetzt wird, ist es nicht nötig, auf diese Information aus Vektorkachel zuzugreifen
                // 2.) Normalerweise würde man diese Eigenschaft mit feature.set("meta", meta) setzen. Da aus Vektorkacheln ausgelesen
                // handelt es sich hier jedoch um die vereinfachte Klasse RenderFeature, die kein Set unterstützt.
            map.removeLayer(temporaryLayer);
            return featureAtPosition;


    }


    // (Teilfunktion) Feature an der ausgewählten Position einer Vektorkachel finden
    async function findFeature(e, temporaryLayer) {

      // warte bis die temporäre Ebene geladen ist
      // -> vgl. https://stackoverflow.com/a/43084615
      return new Promise(function(resolve, reject) {
        temporaryLayer.getSource().once("tileloadend", async function() {
          // console.log("successfully loaded");

          var features = [];
          let i = 0;

          // Beobachtung: es dauert etwas, bis die Features verfügbar sind; daher versuche bis Features gefunden oder breche ab
          for(let i = 0; i <= 8 && features.length == 0; i++) {
              features = map.getFeaturesAtPixel(e.pixel);
              // console.log("i, features", i, features);
              await util.sleep(50*i);   // erhöhe Wartezeit (normalerweise sollte bereits nach 50 ms das Feature vorliegen)
          }

          var feature;
          if(features.length > 0) {
            feature = features[0];    // wähle das erste Feature aus
            // console.log("feature/id, name", feature.get("name"), feature.getId());
          }

          resolve(feature);         // gebe das Feature zurück
        });
      });
    }



    async function setTemporaryLayer(layer) {
      await map.addLayer(layer);
      return;
    }

    async function findFeaturesInExtent(layer, extent) {
      map.removeLayer(layer);
      await setTemporaryLayer(layer);
      var features =  layer.getSource().getFeaturesInExtent(extent);
      // console.log("features", features);
    }



    // AUSDEHNUNG AKTUELL GELADENER KACHELN FINDEN
    function findTileExtent(e) {
      // ZOOM DER KARTE
      let curr_viewZoom = Math.round(map.getView().getZoom());
      let curr_gridZoom = tileloader.tileserverZs[curr_viewZoom];

      // META DER KARTE
      var curr_meta = map.get("meta")['path'];

      // KOORDINATENAUSDEHNUNG DER KARTE
      let curr_coordExtent   = map.getView().calculateExtent(); // [xmin, ymax, xmax, ymin]
      let minTile_coords = [curr_coordExtent[0], curr_coordExtent[3]];
      let maxTile_coords = [curr_coordExtent[2], curr_coordExtent[1]];

      // KACHELAUSDEHNUNG ERMITTELN
      let grid = standardGrid;    // hier nicht virtualTileGrid verwenden, sonst stimmen die Kachelgrößen unten nicht!

      // maximal mögliche Ausdehnung
      let boundaries_coords = def.contentExtent;
      let minBoundaries_coords = [boundaries_coords[0], boundaries_coords[3]]   // [0, 0]
      let maxBoundaries_coords = [boundaries_coords[2], boundaries_coords[1]]   // [-7200, 12800]

      let minBoundaries_zxy = grid.getTileCoordForCoordAndZ(minBoundaries_coords, curr_gridZoom);       // [z, 0, 0]
      let maxBoundaries_zxy = grid.getTileCoordForCoordAndZ(maxBoundaries_coords, curr_gridZoom);       // [z, x, y]
          // maxBoundaries_zxy = [maxBoundaries_zxy[0], maxBoundaries_zxy[1]-1, maxBoundaries_zxy[2]-1]
          // Anpassung, sonst sind maxBoundaries immer um eine Kachel zu groß?

      // Kacheln im sichtbaren Bildausschnitt
      let minTile_zxy = grid.getTileCoordForCoordAndZ(minTile_coords, curr_gridZoom);   // [z, x, y]
      let maxTile_zxy = grid.getTileCoordForCoordAndZ(maxTile_coords, curr_gridZoom);   // [z, x, y]

      // auf maximale Ausdehnung zuschneiden
      minTile_zxy[1] = minTile_zxy[1] < minBoundaries_zxy[1] ? minBoundaries_zxy[1] : minTile_zxy[1];
      minTile_zxy[2] = minTile_zxy[2] < minBoundaries_zxy[2] ? minBoundaries_zxy[2] : minTile_zxy[2];

      maxTile_zxy[1] = maxTile_zxy[1] > maxBoundaries_zxy[1] ? maxBoundaries_zxy[1] : maxTile_zxy[1];
      maxTile_zxy[2] = maxTile_zxy[2] > maxBoundaries_zxy[2] ? maxBoundaries_zxy[2] : maxTile_zxy[2];

      /*
      console.log("findTileExtent", curr_coordExtent);
      console.log("minBoundaries_zxy", minBoundaries_zxy);
      console.log("maxBoundaries_zxy", maxBoundaries_zxy);
      console.log("minTile_xy corr3", minTile_zxy);
      console.log("maxTile_xy corr3", maxTile_zxy);
      */

      return {
        meta: curr_meta,
           z: curr_gridZoom,
           x: [minTile_zxy[1], maxTile_zxy[1]],
           y: [minTile_zxy[2], maxTile_zxy[2]]
      };

    }


    var assumeActiveSoftKeyboard = 0;   // verwendet, wenn Bildschirmgröße auf Androidgeräten geändert wird

    // KARTE NEU LADEN BEI ÄNDERUNG DER BILDSCHIRMGRÖSSE
    // -> von https://stackoverflow.com/a/21037523
    $(window).bind('resize', function(e) {
      // alert("Breite: " + $(window).width());

      // Abbrechen, wenn diese Funktion deaktiviert ist
      if(!def.resize) { return; }

      // Abbrechen, wenn Event durch Ein- oder Ausblenden der Bildschirmtastatur (Android-Geräte) ausgelöst wurde
      // -> https://stackoverflow.com/questions/47798279/jquery-mobile-how-to-detect-if-mobile-virtual-keyboard-is-opened
      // -> https://stackoverflow.com/a/32768730 (testen, ob Input-Element aktiv)
      if(util.isAndroid()) {                                        // only on Android, resize is triggered on loading soft keyboard (not on iOS)
        if($(document.activeElement).prop('type') === 'text') {     // test whether text input is in focus
          assumeActiveSoftKeyboard = 1;   // assume soft keyboard was loaded
          return;                         // do not resize
        } else if(assumeActiveSoftKeyboard == 1) {
          assumeActiveSoftKeyboard = 0;   // assume soft keyboard was closed
          return;                         // do not resize
        }
      }


      // Darstellung
      map.setTarget(null);                  // Karte entfernen
      var loader = $("#reloader").html();   // Loader einblenden
      $("#map").html(loader);
      $("#container_zoomSlider").html("");

      // Neu laden
      if (window.RT) clearTimeout(window.RT);
      window.RT = setTimeout(function()
      {
        this.location.reload(false); /* false to get page from cache (stackOverflow) */
      }, 100);
    });


    function removeMap() {
      $("#hud-zoom").empty();
    }

/**
 * EVENTS ZUR AKTUALISIERUNG DER KARTE
 */



// Events neu setzen bei Veränderung der Ansicht
// map.on('moveend', updateMeta);                // erstes Laden der Karte (?)
                                                 // Änderung des Views: Event erneuern
// ALTERNATIVE:
  // (+) entfernt sofort alte Beschriftungen, wenn Metazoom überschritten
  // (-) schlechtere Performanz
  // (-) "Rückeffekt" beim ersten laden
// [TODO]: Kombination aus 'moveend' (Metazoom ändern) und 'change:resolution' (Metazoom vorfristig prüfen und ggf. Schrift entfernen oder wieder einblenden)
/**
currentView.on('change:resolution', updateMeta);
map.on('change:view', updateView);
function updateView() {
  currentView.un('change:resolution', updateMeta);   // Event vom vorherigen View entfernen
  currentView = map.getView();                       // neuen View setzen
  currentView.on('change:resolution', updateMeta);   // Event zum neuen View hinzufügen                                           // erste Aktualisierung im neuen Event
}
**/


/* Prüfe, ob Metazoom neu gesetzt werden muss
   Anwendungsfall 1 [aus Kachelfunktionen]: Zoom ändert sich   /  neuer Zoom bereits gesetzt (target_zoom = neu gesetzter Zoom)
   Anwendungsfall 2 [aus Changelogik]:      Logik ändert sich  /  neue Logik bereits gesetzt (target_zoom = Parameter, im nächsten Schritt durch changeLogic modifiziert)
 */
// funktioniert, aber evtl. [TODO]: Kapselung verbessern
function updateMeta(target_zoom) {
  if(target_zoom === undefined) {
    target_zoom = map.getView().getZoom();    // target zoom = current Zoom
  }

  var curr_logic = map.get('logic');
  var old_meta = map.get('meta');
  var new_meta = util.getMetaForViewZoom(target_zoom, curr_logic);

  if(old_meta['level'] != new_meta['level']) {
    map.set('meta', new_meta);
    map.set('color', util.getColourForMeta(new_meta['level']))
    return 1;
  }

  return 0;
}



// Beschriftungen aktualisieren nach Änderung des Metazooms
// map.on('change:meta', updateLabels);
function updateLabels() {
  if(vectorSource_lbl) vectorSource_lbl.refresh();
}

// [TODO]: Es wäre wahrscheinlich sinnvoll, das gleich in der Funktion mit dem Neuladen des labelStatus zu verknüpfen (anstatt wie jetzt immer davor)
/*
function updateLabels() {
  if(vectorSource_lbl) {
  vectorSource_lbl.refresh();
  let curr_labelStatus = map.get("labelStatus");
  curr_labelStatus['fsize_loaded'] = -1;                       // ... setze Liste mit geladenen Schriftgrößen zurück ...
  map.set("labelStatus", curr_labelStatus);
}
// das dann an allen Stellen austauschen, an denen updateLabels vorkommt
*/

// console.log(map.get("logic"));

/** alten Layer nach Zoom entfernen
https://gis.stackexchange.com/questions/261269/hide-old-tiles-while-loading-new-ones-when-zooming-in-openlayers
  view.on("change:resolution", function(e) {
    if (Number.isInteger(e.target.getZoom())) {
      baseSource.refresh();
      baseLayer.setSource(baseSource);
    }
  });
**/





/**
 * Change logic of map, adapt max/min zoom and zoom.
 * @param  {[type]} newLogicWithOptions New logic to apply.
 * @param  {[type]} new_zoom            New view zoom to apply (optional; if not set: try to use old view zoom)
 * @param  {[type]} new_center          New center to apply (optional; if not set: try to use old center)
 * @return {[type]}
 */
function changeLogic(newLogicWithOptions, new_zoom, new_center) {

  // alte Werte
  var old_logic = map.get("logic");
  var old_zoom = map.getView().getZoom();
  // var old_url = loadTiles([old_zoom, 0, 0], "px");
  var old_url = loadTiles([old_zoom, 0, 0], "px");
  var old_center = map.getView().getCenter();

  /*
  var newMinZoom = newLogicWithOptions['minZoom'];
    var newMinResolution = map.getView().getResolutionForZoom(newMinZoom);
  var newMaxZoom = newLogicWithOptions['maxZoom'];
    var newMaxResolution = map.getView().getResolutionForZoom(newMaxZoom);
  */

  // NEUE ANSICHT UND LOGIK
  var newLogic   = newLogicWithOptions['logic'];
  var newView    = newLogicWithOptions['view'];

  var newMinZoom = newView.getMinZoom();
  var newMaxZoom = newView.getMaxZoom();

  // Breche ab, wenn sich Logik nicht geändert hat
  /**
  if(old_logic == newLogic) {
    console.log("abbrechen");
    return;
  }
  **/

  // Startzoom und Zentrierung für neue Ansicht bekannt (Parameter)
  if(new_zoom && new_center) {
    newView.setZoom(new_zoom);
    newView.setCenter(new_center);
  // Startzoom für neue Ansicht ermitteln
  } else if(old_zoom < newMinZoom) {      // alter Zoom kleiner als neues Minimum:
    new_zoom = newMinZoom;                // zoome auf Übersicht, zentriert auf Standardwert (Mitte der Karte)
    newView.setZoom(new_zoom);
  } else if(old_zoom > newMaxZoom) {
    new_zoom = newMaxZoom;
    newView.setZoom(new_zoom);            // alter Zoom größer als neues Maximum:
    newView.setCenter(old_center);        // zoome auf maximale vorhandene Ebene, behalte aktuellen Kartenort
  } else {
    new_zoom = old_zoom;
    newView.setZoom(new_zoom);            // alter Zoom auch für neue Ansicht möglich:
    newView.setCenter(old_center);        // bleibe an aktueller Stelle
  }

  // neue Ansicht, Logik und Metazoom setzen
  map.set("logic", newLogic, true);        // Logik setzen, Event noch nicht auslösen map.set(key, value, opt_silent=true)
    updateMeta(new_zoom);                  // MetaZoom nur neu setzen, wenn er sich ändert
  map.dispatchEvent("change:logic");       // nachdem auch Metazoom aktualisiert ist, Logik-Event auslösen
  map.setView(newView);

  // das Ergebnis ist wie folgt:
     // 1. Logik setzen:        in map.set("logic")
     // 2. Metazoom setzen      in updateMeta()
     //    Event change:meta    in updateMeta()
     // 3. Event change:logic   in map.dispatchEvent
     // stellt sicher, dass der Metazoom von der neuen Logik abhängig ist, aber change:logic-Events den neuen Metazoom bereits kennen (-> wichtig für Linkhash)



// GEGEBENENFALLS AKTUALISIERUNG DER KACHELEBENEN & LABELS
// var new_url = loadTiles([new_zoom, 0, 0], "px");
var new_url = loadTiles([new_zoom, 0, 0], "px");
  if(old_url !== new_url) {
    // console.log("URL hat sich geändert", old_url, new_url);
    rasterLayer_tiles.getSource().refresh();                            // Kacheln austauschen inkl. URL neu laden


    let curr_labelStatus = map.get("labelStatus");
        curr_labelStatus['fsize_loaded'] = -1;                          // setze Liste mit geladenen Schriftgrößen zurück
    map.set("labelStatus", curr_labelStatus);
      /** Erklärung:
          Das Laden der Labels funktioniert so, dass bei Zoom-In-Operationen bereits geladene Labels nicht noch einmal geladen werden
          (labelStatus speichert dafür den Grenzwert Schriftgröße). Wird der LabelStatus hier nicht zurückgesetzt, kann nicht erkannt werden,
          dass größere Labels eventuell noch ungeladen sind (führt zu Löchern in der Beschriftung ) **/

    updateLabels();                                                     // Labels aktualisieren
  } else {
    // console.log("URL hat sich nicht geändert", old_url, new_url);
    rasterLayer_tiles.getSource().setTileUrlFunction(loadTiles_px);     // nur URL neu laden
    vectorLayer_tiles.getSource().setTileUrlFunction(loadTiles_vt);
  }

}


/**
 * Change perspective of map (preliminary). [TODO]
 * @param  {[type]} perspective         New perspective to apply.
 */
function changePerspective(perspective) {
  map.set('perspective', perspective);
  rasterLayer_tiles.getSource().refresh();                            // Kacheln austauschen inkl. URL neu laden
  vectorLayer_tiles.getSource().refresh();
  return;
}


function toggleZoom() {
  var curr_logic = map.get('logic');
  var curr_zoom = map.getView().getZoom();
  var curr_meta = util.getMetaForViewZoom(curr_zoom, curr_logic);

  var toggle_logic, shifted_logic;
  var newLogicWithOptions;

  if(curr_logic === init.zoomLogics.find(element => element.name == 'auto')['logic'] ||
     curr_logic === init.zoomLogics.find(element => element.name == 'auto_shifted')['logic']) {   // Wechsel (toggle) zu detailZoom
    toggle_logic = "detail_" + curr_meta['level'];
  } else {
    var hypothetical_meta = util.getMetaForViewZoom(curr_zoom, init.autoZoom_modified);
    // console.log("hypo_meta, curr_meta", hypothetical_meta['level'], curr_meta['level']);
    if(hypothetical_meta['level'] > curr_meta['level']) {
      toggle_logic = shiftAutoZoom(curr_zoom, curr_meta['level'], init.autoZoom_modified, "down");
    } else if(hypothetical_meta['level'] < curr_meta['level']) {
      toggle_logic =  shiftAutoZoom(curr_zoom, curr_meta['level'], init.autoZoom_modified, "up");
    } else {
      toggle_logic = "auto";
    }
  }

  // console.log("toggle_logic", toggle_logic, "\n");
  var newLogicWithOptions = util.findLogic(toggle_logic, init.zoomLogics);
  // console.log("newLogicWithOptions", newLogicWithOptions);
  changeLogic(newLogicWithOptions);

}

/**
 * [shiftAutoZoom description]
 * @param  {[type]} zoom         Viewzoom, von dem ausgehend der AutoZoom (shifted_auto) verschoben werden soll.
 * @param  {[type]} meta         Gewünschter Metazoom auf der übergebenen Zoomebene.
 * @param  {[type]} logicToShift Zoomlogik, von der ausgehend Veränderung durchgeführt werden soll.
 * @param  {[type]} mode         "up": aktuelle Zoomstufe = erster Zoom mit gegebenem Meta / "down": aktuelle Zoomstufe = erster Zoom mit gegebenem Meta
 * @return {[type]} toggle_logic Logik zu der gewechselt werden soll. Falls angemessen wird zu vor (Side effect) auto_shifted geändert.
 */
function shiftAutoZoom(zoom, meta, logicToShift, mode) {
  var shiftedLogic;
  var toggleLogic;

  if(mode == "up") {
    shiftedLogic = init.functions.upshiftAutoZoom(zoom, meta, logicToShift);
  } else {
    shiftedLogic = init.functions.downshiftAutoZoom(zoom, meta, logicToShift);
  }

  // Überprüfe ob Informationsverlust an Metazoomebenen in dem verschobenem Zoom
  var metasBefore = util.getUniqueMetas(logicToShift).length;
  var metasAfter  = util.getUniqueMetas(shiftedLogic).length;

  // Falls Zoom nicht ohne Informationsverlust verschoben werden kann, gebe Anweisung, um in Detailebene zu wechseln.
  if(metasAfter < metasBefore) {
    // console.log("Zu starker Zoom, um Autozoom anzupassen. Wechsle stattdessen in Detailebene.");
    return "detail_" + meta;
  // Ansonsten passe verschobenen Zoom an und gebe Anweisung, in die verschobene Ebene zu wechseln
  } else {
    // console.log("Passe Autozoom an.")
    // console.log("vorher:", init.zoomLogics);
    init.zoomLogics.find(element => element.name == 'auto_shifted')['logic'] = shiftedLogic;    // ändere ursprüngliche Logik
    // console.log("nachher:", init.zoomLogics);
    return "auto_shifted";
  }


}

// Animation der Punktebene
// -> https://openlayers.org/en/latest/examples/feature-animation.html
function flashPoint(coords, duration) {
  // Punkt hinzufügen
  const geom = new Point(coords);
  const feature = new Feature(geom);
        feature.setStyle(styles.invisible_Style);
  vectorLayer_points.getSource().addFeature(feature);

  // Punkt animieren
  const start = Date.now();
  const flashGeom = feature.getGeometry().clone();

  const listenerKey = rasterLayer_tiles.on('postrender', animate);
  function animate(event) {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);
      vectorLayer_points.getSource().removeFeature(feature);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // Radius am Anfang 0, am Ende 50
    const radius = easeOut(elapsedRatio) * 50 + 0;
    const opacity = easeOut(Math.pow(1 - elapsedRatio, 2));

    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, ' + opacity + ')',
          width: 2 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    map.render();
  }
}





/*
var vectorSource_selection = new Vector({                    // von ol/source (daher der wenig verständliche Name)
  format: new MVT(),
  url: 'tiles/vt2021/phylum/0/0/0.pbf',
});

var vectorLayer_selection = new VectorLayer({
    source: vectorSource_selection,
    // style: vtFeature_Style,
    zIndex: 5,
    extent: [def.contentExtent[0], def.contentExtent[1], def.contentExtent[2], def.contentExtent[3]],
});



var vectorLayer2_tiles = new VectorTileLayer({
          source: new VectorTileSource({
            format: new MVT(),
            tileGrid: virtualTileGrid,
            tileUrlFunction: loadTiles_vt,
            zDirection: 0,
            transition: 0,
            attributions: [ attribution ],
      }),
      extent: [0, -3000, 700, 0],
      preload: 0,
      useInterimTilesOnError: false,
      zIndex: 5,
      // renderBuffer: 80,
      style: vectorTileStyle,
    })

    const vector2 = new VectorLayer({
      source: new VectorSource({
        url: 'tiles/vt2021/phylum/0/0/0.pbf',
        format: new MVT(),
      }),
      style: vtFeature_Style,
      zIndex: 5,
      wrapX: false,
    });


map.addLayer(vector2);
**/





// Statischer Imagelayer
/**         var imageLayer = new ImageLayer({
            source: new Static({
              attributions: '© <a href="https://xkcd.com/license.html">xkcd</a>',
              url: 'https://imgs.xkcd.com/comics/logic_gates.png',
              projection: projection,
              imageExtent: rangeExtent,
            }),
            zIndex: 100
          });
**/

// var autoLogic = util.findLogic('auto', init.zoomLogics);
// console.log("standardView", standardView);
// console.log("Logik", map.get("logic"));
// console.log("Logics", init.zoomLogics);
// console.log("Logik-Auto1", autoLogic['logic']);
// console.log("Logik-Auto2", autoLogic);



module.exports = {
  map: map,
    zoomLogics:        init.zoomLogics,
    startZoom:         init.startZoom,
    changeLogic:       changeLogic,
    changePerspective: changePerspective,
    vectorLayer_lbl:   vectorLayer_lbl,
    vectorLayer_tiles: vectorLayer_tiles,
    rasterLayer_tiles: rasterLayer_tiles,
//  toggleToDetailZoom: toggleToDetailZoom,
//  toggleToAutoZoom: toggleToAutoZoom,
    toggleZoom:        toggleZoom,
    flashPoint:        flashPoint,
    featureOnPosition: featureOnPosition,
    findTileExtent:    findTileExtent,
    startMap:          startMap,
    updateMeta:        updateMeta,
  fullStyle: fullStyle,
  partStyle: partStyle,
  zoomSlider: zoomslider,
  tileServer_px: TILE_SERVER_PX
}
