/**
 * @module ol/control/ZoomSlider
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Control from 'ol/control/Control.js';
import EventType from 'ol/events/EventType.js';
import ObjectEventType from 'ol/ObjectEventType.js';
import MapEventType from 'ol/MapEventType.js';
import PointerEventType from 'ol/pointer/EventType.js';
import MapProperty from 'ol/MapProperty.js';
import { CLASS_CONTROL, CLASS_UNSELECTABLE } from 'ol/css.js';
import { clamp } from 'ol/math.js';
import { easeOut } from 'ol/easing.js';
import { listen, listenOnce, unlistenByKey } from 'ol/events.js';
import { stopPropagation } from 'ol/events/Event.js';
var def = require('./def');
var util = require('./util');

/**
 * The enum for available directions.
 *
 * @enum {number}
 */
var Direction = {
    VERTICAL: 0,
    HORIZONTAL: 1,
};
/**
 * @typedef {Object} Options
 * @property {string} [className='ol-zoomslider'] CSS class name.
 * @property {number} [duration=200] Animation duration in milliseconds.
 * @property {function(import("../MapEvent.js").default):void} [render] Function called when the control
 * should be re-rendered. This is called in a `requestAnimationFrame` callback.
 */
/**
 * @classdesc
 * A slider type of control for zooming.
 *
 * Example:
 *
 *     map.addControl(new ZoomSlider());
 *
 * @api
 */
var ZoomSlider = /** @class */ (function (_super) {
    __extends(ZoomSlider, _super);
    /**
     * @param {Options=} opt_options Zoom slider options.
     */
    function ZoomSlider(opt_options) {
        var _this = this;
        var options = opt_options ? opt_options : {};
        _this = _super.call(this, {
            element: document.createElement('div'),
            render: options.render
        }) || this;
        /**
         * @type {!Array.<import("../events.js").EventsKey>}
         * @private
         */
        _this.dragListenerKeys_ = [];
        /**
         * Will hold the current resolution of the view.
         *
         * @type {number|undefined}
         * @private
         */
        _this.currentResolution_ = undefined;
        /**
         * The direction of the slider. Will be determined from actual display of the
         * container and defaults to Direction.VERTICAL.
         *
         * @type {Direction}
         * @private
         */
        _this.direction_ = Direction.VERTICAL;
        /**
         * @type {boolean}
         * @private
         */
        _this.dragging_;
        /**
         * @type {number}
         * @private
         */
        _this.heightLimit_ = 0;
        /**
         * @type {number}
         * @private
         */
        _this.widthLimit_ = 0;
        /**
         * @type {number|undefined}
         * @private
         */
        _this.startX_;
        /**
         * @type {number|undefined}
         * @private
         */
        _this.startY_;
        /**
         * The calculated thumb size (border box plus margins).  Set when initSlider_
         * is called.
         * @type {import("../size.js").Size}
         * @private
         */
        _this.thumbSize_ = null;
        /**
         * Whether the slider is initialized.
         * @type {boolean}
         * @private
         */
        _this.sliderInitialized_ = false;
        /**
         * @type {number}
         * @private
         */
        _this.duration_ = options.duration !== undefined ? options.duration : 200;

        // HINZUGEFÜGT:
        _this.globalLogic_ = options.globalLogic['logic'];
        _this.globalView_ = options.globalLogic['view'];

      // _this.activeView_ = ...?


        /**
         * @type {number}
         * @private
         */

        var className = options.className !== undefined ? options.className : 'ol-zoomslider';
        var thumbElement = document.createElement('button');
        thumbElement.setAttribute('type', 'button');
        thumbElement.className = className + '-thumb ' + CLASS_UNSELECTABLE;
        var containerElement = _this.element;
        containerElement.className =
            className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
        containerElement.appendChild(thumbElement);
        containerElement.addEventListener(PointerEventType.POINTERDOWN, _this.handleDraggerStart_.bind(_this), false);
        containerElement.addEventListener(PointerEventType.POINTERMOVE, _this.handleDraggerDrag_.bind(_this), false);
        containerElement.addEventListener(PointerEventType.POINTERUP, _this.handleDraggerEnd_.bind(_this), false);
        containerElement.addEventListener(EventType.CLICK, _this.handleContainerClick_.bind(_this), false);
        thumbElement.addEventListener(EventType.CLICK, stopPropagation, false);

        // console.log("listenerKeys", _this.listenerKeys);
        return _this;
    }
    /**
     * Remove the control from its current map and attach it to the new map.
     * Subclasses may set up event handlers to get notified about changes to
     * the map here.
     * @param {import("../PluggableMap.js").default} map Map.
     * @api
     */
    ZoomSlider.prototype.setMap = function (map) {
        _super.prototype.setMap.call(this, map);
        if (map) {
            this.listenerKeys.push(listen(map, ObjectEventType.PROPERTYCHANGE, this.sliderColours_, this)); // HINZUGEFÜGT der Erhalt des Elements ist aus der Funktion handleDraggerStart_ übernommen
            this.listenerKeys.push(listenOnce(map, MapEventType.POSTRENDER, this.sliderColours_, this));
            map.render();
        }
    };
    /**
     * Initializes the slider element. This will determine and set this controls
     * direction_ and also constrain the dragging of the thumb to always be within
     * the bounds of the container.
     *
     * @return {boolean} Initialization successful
     * @private
     */
    ZoomSlider.prototype.initSlider_ = function () {
        var container = this.element;
        var containerWidth = container.offsetWidth;
        var containerHeight = container.offsetHeight;
        if (containerWidth === 0 && containerHeight === 0) {
            return (this.sliderInitialized_ = false);
        }
        var thumb = /** @type {HTMLElement} */ (container.firstElementChild);
        var computedStyle = getComputedStyle(thumb);
        var thumbWidth = thumb.offsetWidth +
            parseFloat(computedStyle['marginRight']) +
            parseFloat(computedStyle['marginLeft']);
        var thumbHeight = thumb.offsetHeight +
            parseFloat(computedStyle['marginTop']) +
            parseFloat(computedStyle['marginBottom']);
        this.thumbSize_ = [thumbWidth, thumbHeight];
        if (containerWidth > containerHeight) {
            this.direction_ = Direction.HORIZONTAL;
            this.widthLimit_ = containerWidth - thumbWidth;
        }
        else {
            this.direction_ = Direction.VERTICAL;
            this.heightLimit_ = containerHeight - thumbHeight;
        }

        return (this.sliderInitialized_ = true);
    };
    /**
     * @param {PointerEvent} event The browser event to handle.
     * @private
     */
    ZoomSlider.prototype.handleContainerClick_ = function (event) {
        var view = this.getMap().getView();
        var relativePosition = this.getRelativePosition_(event.offsetX - this.thumbSize_[0] / 2, event.offsetY - this.thumbSize_[1] / 2);
        var resolution = this.getResolutionForPosition_(relativePosition);
        var zoom = view.getConstrainedZoom(view.getZoomForResolution(resolution));
        view.animateInternal({
            zoom: zoom,
            duration: this.duration_,
            easing: easeOut,
        });
    };

    /**
     * HINZUGEFÜGT, um Sliderbereiche entsprechend Metazoom einzufärben
     * @param  {[type]} event
     * @return {[type]}
     */
    ZoomSlider.prototype.sliderColours_ = function(event) {

      if (event.key === MapProperty.VIEW || event.type === "postrender") {                     // das Eventhandling ist aus ol/control/OverviewMap.js übernommen

        // FLÄCHE DES ZOOMSLIDERS
        var element = /** @type {HTMLElement} */ (this.element  // der Erhalt des Elements ist aus der Funktion handleDraggerStart_ übernommen
            .firstElementChild);
            // element.style.top = "200px";


        // AUSDEHNUNG METAZOOM-STUFEN FINDEN                    // eigener Entwurf
        // Vorbereitung: aktuelle Logik, metaZoom-Ebenen, max. Viewzoom der Autozoom-Ebene
        var currentLogic = this.getMap().get("logic");
        var uniqueMetas = util.getUniqueMetas(currentLogic);

        // Ausdehnung der einzelnen Meta-Zoomstufen finden
        var meta_ranges = [];
        uniqueMetas.forEach(function(meta) {
          let currmeta_ranges   = util.getInternalZoomForMeta(meta, currentLogic);
          meta_ranges.push({'meta': meta, 'min': currmeta_ranges['slider_min'], 'max': currmeta_ranges['slider_max']});
        });
        meta_ranges.sort((a, b) => (a.max > b.max) ? 1 : -1);    // Array sicherheitshalber nach Maxima des Metazooms sortieren


        // AUF ZOOMSLIDER AUFTEILEN
        // Grenzen im Zoomslider finden (normiert auf 0 und 1)
        var slider_positions = [];
        meta_ranges.forEach(function(meta) {
          let start_resolution = util.getResolutionForZoom(meta['min']);
          let stop_resolution = util.getResolutionForZoom(meta['max']);
          let slider_start = this.getPositionForResolution_(start_resolution);
          let slider_stop = this.getPositionForResolution_(stop_resolution);

          let slider_positions_meta  = meta['meta'];
          let slider_positions_start = Math.round(slider_start*100)/100;
          let slider_positions_stop  = Math.round(slider_stop*100)/100;

          slider_positions.push({'meta': slider_positions_meta, 'start': slider_positions_start, 'stop': slider_positions_stop});


        }, this); // forEach(callback, this) -> damit wird die Funktion aus "this" in forEach nutzbar


        // Falls vorhanden, unerreichbare Zoomstufen hinzufügen
        var length = slider_positions.length;
        var lowestStart = slider_positions[0]['start'];
        var highestStop = slider_positions[length-1]['stop'];

        // Nicht-ausgefüllten Zoom am Anfang des Arrays kennzeichnen
        if(lowestStart > 0) {
          slider_positions.unshift( {'meta': undefined, 'start': 0, 'stop': lowestStart});
        }

        // Nicht-ausgefüllten Zoom am Ende des Arrays kennzeichnen
        if(highestStop < 1) {
          slider_positions.push( {'meta': undefined, 'start': highestStop, 'stop': 1});
        }


          /** -> ERGEBNISBEISPIELE
          // Autozoom                                         // Übersichtsebene Familie
          [{ "meta": 2, "start": 0,    "stop": 0.03},         [ {"meta": 5,        "start": 0,     "stop": 0.89 },
           { "meta": 3, "start": 0.03, "stop": 0.23},           {"meta": undefined "start": 0.89,  "stop": 1}]
           { "meta": 4, "start": 0.23, "stop": 0.53},
           { "meta": 5, "start": 0.53, "stop": 0.8},
           { "meta": 6, "start": 0.8,  "stop": 1}]
          **/


        // GRAPHISCHE UMSETZUNG
        var radialGradient_text = ""; // Kreise (überlagern Übergänge)
        var linearGradient_text = ""; // abrupte Übergänge
        var height = this.element.clientHeight // automatische Anpassung der Kreise, wenn height in der CSS-Klasse .ol-zoomslider geändert wird
        var width  = this.element.clientWidth;
        var circle_offset = height/width*0.8;
        var thumb_offset = 3/width; // Offset, Thumb (zoomslider thumb, margin-left in design.css)    [TODO]: flexibel machen durch def.js; in design.css und hier
            // 0.9 und 7 empirisch


        for(let i = 0, len=slider_positions.length; i < len; i++) {
          var meta      = slider_positions[i]['meta'];
          var colour    = util.getColourForMeta(meta);

          // Empirisch: Circle_offset wächst mit Meta (nicht mit i; wird i verwendet, dann ist die Länge des Zoomreglers im fixierten Modus nicht korrekt)
          var meta_offset = 2;
          if(meta && meta > 2) {
            meta_offset = meta;
          }
          var min_share = slider_positions[i]['start']+1/(meta_offset-1)*circle_offset-thumb_offset;
          var max_share = slider_positions[i]['stop']+1/(meta_offset-1)*circle_offset-thumb_offset;

          // console.log("meta", meta, "min_share", min_share, "max_share", max_share);

          var current_RadialGradient_text = `radial-gradient(${height}px ${height}px at ${max_share*100}% 50%, ${colour} 50%, transparent 50%)`
          var current_LinearGradient_text = `${colour} ${min_share*100}%,${colour} ${max_share*100}%`


              radialGradient_text = radialGradient_text.length == 0 ?  radialGradient_text.concat(current_RadialGradient_text) : radialGradient_text.concat(",\n", current_RadialGradient_text);
              linearGradient_text = linearGradient_text.length == 0 ?  linearGradient_text.concat(current_LinearGradient_text) : linearGradient_text.concat(",", current_LinearGradient_text);
        }


        /* VARIANTE 1: nur linearer Gradient
        / gradient_text: linear-gradient(to right, #552222 0%,#552222 1%,#AA2222 1%,#AA2222 22%,#225522 22%,#225522 39%,#22AA22 39%,#22AA22 51%,#222255 51%,#222255 75%,#2222AA 75%,#2222AA 100%) */

        /* VARIANTE 2: radiärer Gradient + linearer Gradient
        radial-gradient(20px 20px at 1% 50%, #552222 50%, transparent 50%),
        radial-gradient(20px 20px at 22% 50%, #AA2222 50%, transparent 50%),
        radial-gradient(20px 20px at 39% 50%, #225522 50%, transparent 50%),
        radial-gradient(20px 20px at 51% 50%, #22AA22 50%, transparent 50%),
        radial-gradient(20px 20px at 75% 50%, #222255 50%, transparent 50%),
        linear-gradient(to right, #552222 0%,#552222 1%,#AA2222 1%,#AA2222 22%,#225522 22%,#225522 39%,#22AA22 39%,#22AA22 51%,#222255 51%,#222255 75%,#2222AA 75%,#2222AA 100%);
        */


        linearGradient_text = `linear-gradient(to right, ${linearGradient_text})`;

        var gradient_text = radialGradient_text.concat(",\n").concat(linearGradient_text);

        // gradient_text = `linear-gradient(to right, ${gradient_text})`;
        this.element.style['background'] = gradient_text;  // Gestaltung des Zoomsliders
        this.data = slider_positions;
        // console.log("logic", currentLogic);
        // console.log("meta_ranges:", meta_ranges);
        // console.log("slider_positions", slider_positions);
        // console.log(this.element.style['background']);
        // console.log("gradient_text:", gradient_text);



      }
    }


    /**
     * Handle dragger start events.
     * @param {PointerEvent} event The drag event.
     * @private
     */
    ZoomSlider.prototype.handleDraggerStart_ = function (event) {
        if (!this.dragging_ && event.target === this.element.firstElementChild) {
            var element = /** @type {HTMLElement} */ (this.element
                .firstElementChild);

            this.getMap().getView().beginInteraction();
            this.startX_ = event.clientX - parseFloat(element.style.left);
            this.startY_ = event.clientY - parseFloat(element.style.top);
            this.dragging_ = true;
            if (this.dragListenerKeys_.length === 0) {
                var drag = this.handleDraggerDrag_;
                var end = this.handleDraggerEnd_;
                var doc = this.getMap().getOwnerDocument();
                this.dragListenerKeys_.push(listen(doc, PointerEventType.POINTERMOVE, drag, this), listen(doc, PointerEventType.POINTERUP, end, this));
            }
        }
    };
    /**
     * Handle dragger drag events.
     *
     * @param {PointerEvent} event The drag event.
     * @private
     */
    ZoomSlider.prototype.handleDraggerDrag_ = function (event) {
        if (this.dragging_) {
            var deltaX = event.clientX - this.startX_;
            var deltaY = event.clientY - this.startY_;
            var relativePosition = this.getRelativePosition_(deltaX, deltaY);
            this.currentResolution_ = this.getResolutionForPosition_(relativePosition);
            this.getMap().getView().setResolution(this.currentResolution_);
        }
    };
    /**
     * Handle dragger end events.
     * @param {PointerEvent} event The drag event.
     * @private
     */
    ZoomSlider.prototype.handleDraggerEnd_ = function (event) {
        if (this.dragging_) {
            var view = this.getMap().getView();
            view.endInteraction();
            this.dragging_ = false;
            this.startX_ = undefined;
            this.startY_ = undefined;
            this.dragListenerKeys_.forEach(unlistenByKey);
            this.dragListenerKeys_.length = 0;
        }
    };
    /**
     * Positions the thumb inside its container according to the given resolution.
     *
     * @param {number} res The res.
     * @private
     */
    ZoomSlider.prototype.setThumbPosition_ = function (res) {
        var position = this.getPositionForResolution_(res);
        var thumb = /** @type {HTMLElement} */ (this.element.firstElementChild);
        if (this.direction_ == Direction.HORIZONTAL) {
            thumb.style.left = this.widthLimit_ * position + 'px';
        }
        else {
            thumb.style.top = this.heightLimit_ * position + 'px';
        }
    };
    /**
     * Calculates the relative position of the thumb given x and y offsets.  The
     * relative position scales from 0 to 1.  The x and y offsets are assumed to be
     * in pixel units within the dragger limits.
     *
     * @param {number} x Pixel position relative to the left of the slider.
     * @param {number} y Pixel position relative to the top of the slider.
     * @return {number} The relative position of the thumb.
     * @private
     */
    ZoomSlider.prototype.getRelativePosition_ = function (x, y) {
        var amount;
        if (this.direction_ === Direction.HORIZONTAL) {
            amount = x / this.widthLimit_;
        }
        else {
            amount = y / this.heightLimit_;
        }
        return clamp(amount, 0, 1);
    };
    /** GEÄNDERT
     * Calculates the corresponding resolution of the thumb given its relative
     * position (where 0 is the minimum and 1 is the maximum).
     *
     * @param {number} position The relative position of the thumb.
     * @return {number} The corresponding resolution.
     * @private
     */
    ZoomSlider.prototype.getResolutionForPosition_ = function (position) {
        var fn = this.globalView_.getResolutionForValueFunction();
        return fn(position);
    };
    /** GEÄNDERT
     * Determines the relative position of the slider for the given resolution.  A
     * relative position of 0 corresponds to the minimum view resolution.  A
     * relative position of 1 corresponds to the maximum view resolution.
     *
     * @param {number} res The resolution.
     * @return {number} The relative position value (between 0 and 1).
     * @private
     */
    ZoomSlider.prototype.getPositionForResolution_ = function (res) {
        var fn = this.globalView_.getValueForResolutionFunction();
        return clamp(fn(res), 0, 1);
    };



    /**
     * Update the zoomslider element.
     * @param {import("../MapEvent.js").default} mapEvent Map event.
     * @override
     */
    ZoomSlider.prototype.render = function (mapEvent) {
        if (!mapEvent.frameState) {
            return;
        }
        if (!this.sliderInitialized_ && !this.initSlider_()) {
            return;
        }
        var res = mapEvent.frameState.viewState.resolution;
        this.currentResolution_ = res;
        this.setThumbPosition_(res);
    };
    return ZoomSlider;
}(Control));
export default ZoomSlider;
