// jQuery-$ und Suchfunktion von Semantic UI aktivieren
// var $    = require("jquery");
// require('fomantic-ui');

/*
$.fn.search      = require('semantic-ui-search');
$.fn.tab         = require('semantic-ui-tab');
$.fn.popup       = require('semantic-ui-popup');
$.fn.transition  = require('semantic-ui-transition');
$.fn.api         = require('semantic-ui-api');
$.fn.dropdown    = require('fomantic-ui-dropdown');
*/


// import "jquery.easing";   // benötigt, damit die Suche hier funktioniert
import Overlay from 'ol/Overlay';
import * as d3 from "d3";

import { phylotree } from "phylotree";
import "phylotree/dist/phylotree.css";

var def           = require('./def');
var debug         = require('./debug');
var util          = require('./util');
var main          = require('./map');
var phylo         = require('./phylo');
var link          = require('./link');
var tour          = require('./tour');
var perspectives  = require('./perspectives');

var map               = main.map;
var vectorLayer_lbl   = main.vectorLayer_lbl;
var vectorLayer_tiles = main.vectorLayer_tiles;
var rasterLayer_tiles = main.rasterLayer_tiles;
var fullStyle         = main.fullStyle;
var partStyle         = main.partStyle;
var zoomLogics        = main.zoomLogics;
var startZoom         = main.startZoom;
var zoomslider        = main.zoomSlider;
var featureOnPosition = main.featureOnPosition;
var findTileExtent    = main.findTileExtent;
var updateHash       = link.updateHash;
var initHash         = link.initHash;
var optimizeLink     = link.optimizeLink;

var TILE_SERVER_PX   = main.tileServer_px;

// Warnungen im Produktivmodus unterdrücken (phylotree.js gibt Warnungen für Bäume ohne Längen aus)
  if (process.env.NODE_ENV !== "development") {
    console.warn = () => {};
  }

// Ordnerpfade
// -> https://stackoverflow.com/a/40627563

// Standardwerte für Checkboxen (um "Caching von Checkboxauswahl" zu umgehen)
$("#vector_checkbox").prop("checked", false);
$("#raster_checkbox").prop("checked", true);
$("#debug").prop("checked", false);
$("#labels").prop("checked", true);
$("#labels_de").prop("checked", false);
updateBtn_meta();
updateBtn_logic();


// STEUERELEMENTE 2
$('#btn_layerBox').click( function() {
  $('#layer_options').toggle();
});

$("#labels").change(function(){
  if($(this).is(':checked')) {
    map.addLayer(vectorLayer_lbl);
    $('#labels_de').prop('checked', true);
    vectorLayer_lbl.setStyle(fullStyle);
  } else {
    map.removeLayer(vectorLayer_lbl);
    $('#labels_de').prop('checked', false);
  }
});


$("#labels_de").change(function() {
    if($(this).is(':checked')) {

      if(!$('#labels').is(':checked')) {
        $('#labels').prop('checked', true);
        map.addLayer(vectorLayer_lbl);
      }
      vectorLayer_lbl.setStyle(fullStyle);

    } else {
      vectorLayer_lbl.setStyle(partStyle);
    }
});


$("#debug").change(function(){
  if($(this).is(':checked')) {
    map.addControl(debug.mousePositionControl);
    map.addLayer(debug.tiledebug);
    $('#viewZoom_level').show();
    $('#gridZoom_level').show();
    $('#metaZoom_level').show();
    $('#internalZoom_level').show();
  } else {
    map.removeControl(debug.mousePositionControl);
    map.removeLayer(debug.tiledebug);
    $('#viewZoom_level').hide();
    $('#gridZoom_level').hide();
    $('#metaZoom_level').hide();
    $('#internalZoom_level').hide();
  }
});


$("#vector_checkbox").change(function(){
  if($(this).is(':checked')) {
    map.addLayer(vectorLayer_tiles);
  } else {
    map.removeLayer(vectorLayer_tiles);
  }
});

$("#raster_checkbox").change(function(){
  if($(this).is(':checked')) {
    map.addLayer(rasterLayer_tiles);
  } else {
    map.removeLayer(rasterLayer_tiles);
  }
});



$('#btn_home').click( function() {
  resetToHome();
  // $('#map').focus();
});



// ZUSÄTZLICHE STEUERELEMENTE
// Einfache Interaktivität des Popups
// -> https://openlayers.org/en/latest/examples/popup.html
const container = document.getElementById('popup');
const closer = document.getElementById('popup-closer');

const overlay = new Overlay({
  element: container,
  positioning: 'bottom-center',
  autoPan: true,
  autoPanMargin: 20
});

// Overlay schließen
function closeOverlay() {
    overlay.setPosition(undefined);
    closer.blur();
    collapseLink();
    return false;
}

// Overlay schließen falls nicht Android/iOS (Hotfix)
function closeOverlay2() {
  if(!util.isAndroid() && !util.isIOS()) {
    closeOverlay();
  }
}


closer.onclick = function () {
 closeOverlay();
};



// INITIALISIERUNG & DEBUG-FUNKTIONEN
var current_view;

    map.once('postrender', onZoom);                   // erstes Laden der Karte
    map.once('postrender', initZoomLabels);           // erstes Laden der Karte: erstelle Zoomlabels
    map.once('postrender', initZoomButtons);          // erstes Laden der Karte: färbe Buttons (Automatik etc.)
    map.once('postrender', initHash);
    map.on('change:view', updateView);                // Änderung des Views: Event erneuern
    map.on('moveend', updateHash);
    map.on('moveend', updatePButtons);

    current_view = map.getView();
    current_view.on('change:resolution', onZoom);

    function updateView() {
      current_view.un('change:resolution', onZoom);   // Event vom vorherigen View entfernen
      current_view = map.getView();                   // neuen View setzen
      current_view.on('change:resolution', onZoom);   // Event zum neuen View hinzufügen
      onZoom();                                       // erste Aktualisierung im neuen Event
    }

    map.on('change:meta', onMeta);

    // Kartenfokus nach Klick wiederherstellen
    map.once('postrender', function() {
      $('#map').focus();  // Karte erstmalig in Fokus nehmen

      // nicht, sofern der Klick das Suchfeld (Input) auswählt
      // -> https://stackoverflow.com/a/7231019
      $(document).on("click", function(e) {
        // console.log("target", e.target);

        if (e.target.tagName.toUpperCase() === 'INPUT') {
           return;
       }
            $('#map').focus();
       })
    });

    // Debugschaltfläche anzeigen
    if(def.debug == 1) {
      $('.debugPanel').show();
    }

    // Buttons aktualisieren
    map.on('change:meta', updateBtn_meta);
    map.on('change:meta', updateOverlay_tabs);
    map.on('change:meta', closeOverlay2);
    map.on('change:logic', updateBtn_logic);

    // Hash aktualisieren
    map.on('change:meta',  updateHash);
    map.on('change:logic', updateHash);


    // Overlay hinzufügen
    map.addOverlay(overlay);

    // Rechtsklick auf Karte
     map.on('contextmenu', async function (e) {
       let popupContainer = document.getElementById('popup-phylo');
       let popupFooter    = document.getElementById('footer_imgSource');
       let popupCopy      = document.getElementById('inputContainer');

       // Standardkontextmenü erlauben:
       if(e.originalEvent.target == popupContainer
          || popupContainer.contains(e.originalEvent.target)
          || e.originalEvent.target == popupFooter
          || e.originalEvent.target == popupCopy) {
          return;
        }

      // Benutzerdefiniertes Kontextmenü:
         fillOverlay(e);
         e.preventDefault();
         return false;          // Verhindere, dass das normale Kontextmenü geladen wird

     });

     map.on('singleclick', async function (e) {   // Bei Klick auf die Karte Overlay schließen
       if(e.type == "singleclick" && !util.isAndroid() && !util.isIOS()) {
                              // nicht bei Touchevents und (Hotfix?) auf Android/iOS-Betriebssystemen
         closeOverlay();
       }
     });




function onZoom () {
    var resolution = map.getView().getResolution();
    var viewZoom = Math.round(map.getView().getZoom() * 100)/100;
    var metaZoom = map.get("meta")['level'];
    var internalZoom = Math.round(util.getGridZoomForResolution(resolution, "unrounded") * 100)/100;
    var gridZoom = util.getGridZoomForResolution(resolution);

    // DEBUG-Zoomlevel in der Voreinstellung verbergen
    if (!$("#debug").is(':checked')) {
        $('#viewZoom_level').hide();
        $('#metaZoom_level').hide();
        $('#gridZoom_level').hide();
        $('#internalZoom_level').hide();
      }

    // DEBUG: Zoomlevel aktualisieren
    $('#viewZoom_level').text("viewZoom: " + viewZoom);
    $('#metaZoom_level').text("metaZoom: " + metaZoom);
    $('#gridZoom_level').text("gridZoom: " + gridZoom);
    $('#internalZoom_level').text("internalZoom: " + internalZoom);
  }

function onMeta() {
  var metaZoom = map.get("meta")['level'];
  $('#metaZoom_level').text("metaZoom: " + metaZoom);
}



// INTERAKTIVITÄT FÜR KLEINE MOBILGERÄTE
// < 864px: Zoomleiste klappt ein, Logo verschwindet
// < 700px: Suchleiste klappt ein
// hard-coded hier und in design.css
// [TODO]: flexibel machen, abhängig von Variable

// SUCHFELD
$(document).ready(function() {            // Platzhalter festlegen
  let windowWidth = $(window).width();
  let placeholder = $('#search_placeholder').text();

  if(windowWidth < 700) {
    $('.prompt').attr('placeholder', '...');
  } else {
    $('.prompt').attr('placeholder', placeholder);
  }

});

// Suche ausklappen
function openSearch() {
  let windowWidth = $(window).width();
  let placeholder = $('#search_placeholder').text();

  if(windowWidth < 700 && $(".header").hasClass("defaultGrid")) {
    $(".header").removeClass("defaultGrid");
    $(".header").addClass("searchGrid");
    // $('#search_btn_back').css("visibility", "visible");
    $('.container_zoomSlider').css("display", "none");
    $('.container_zoomMode_small').css("display", "none");
    $('.prompt').attr('placeholder', placeholder);
  }

  if(windowWidth < 864) {
    $('.logo').css("visibility", "hidden");
  }
}

// Suche einklappen
function closeSearch() {
  let windowWidth = $(window).width();


  if($(".header").hasClass("searchGrid") && $('.prompt').val().length < 2) {
    $(".header").removeClass("searchGrid");       // nicht einklappen, wenn Suchbegriff eingetragen (sonst kein Klick möglich)
    $(".header").addClass("defaultGrid");
    $('#zoomSlider').css("visibility", "visible");
    // $('#search_btn_back').css("visibility", "hidden");
    $('.container_zoomSlider').css("display", "grid");
    $('.container_zoomMode_small').css("display", "inline-block");
    $('.prompt').attr('placeholder', '');

    if(windowWidth > 864) {
      $('.logo').css("visibility", "visible");
    }
  }
}


// Suche ausklappen, wenn in Fokus
$('.prompt').focusin( function() {
  openSearch();
});

// Suche einklappen, wenn außer Fokus
$('.prompt').focusout( function() {
  closeSearch();
});

// Suche einklappen bei Klick in den Header
$('.header').click( function(e) {
  if($(".header").hasClass("searchGrid") && ! $('.prompt').is(":focus")) {             // Suche einklappen, wenn woanders hingeklickt wird (auch, wenn gefüllt)
    closeSearch();
  }
})




// ZOOMOPTIONEN
// Zoom-Werkzeugleiste ausklappen
function openZoom() {
  $(".header").removeClass("defaultGrid");
  $(".header").addClass("zoomGrid");
  $('#zoomSlider').css("visibility", "hidden");
  $('.container_zoomMode').css("display", "grid");
  $('.container_zoomLinks').css("display", "grid");
  $('.container_zoomMode_small').css("visibility", "hidden");
  $('.container_zoomSlider').css("display", "none");
  $('.container_search').css("display", "none");
}

// Zoom-Werkzeugleiste einklappen
function closeZoom() {
  if($(".header").hasClass("zoomGrid")) {
    $(".header").removeClass("zoomGrid");
    $(".header").addClass("defaultGrid");
    $('#zoomSlider').css("visibility", "visible");
    $('.container_zoomMode').css("display", "none");
    $('.container_zoomLinks').css("display", "none");
    $('.container_zoomMode_small').css("visibility", "visible");
    $('.container_zoomSlider').css("display", "grid");
    $('.container_search').css("display", "grid");
  }
}


// Zoom-Werkzeugleiste ausklappen bei Klick auf Schaltfläche
$('#btn_zoom_small').click( function() {
  let windowWidth = $(window).width();
  if(windowWidth < 864 && $(".header").hasClass("defaultGrid")) {
    openZoom();
  }
});

// Zoom-Werkzeugleiste einklappen nach Interaktion (damit Änderung des Sliders sichtbar wird)
$('#btn_zoom_back').click( function(e) {
    closeZoom();
});

$('#btn_auto_design').click( function(e) {
    closeZoom();
});

$('#btn_auto_min').click( function(e) {
    closeZoom();
});

$('#btn_auto_norm').click( function(e) {
    closeZoom();
});

$('#btn_auto_max').click( function(e) {
    closeZoom();
});

$('#btn_fix_design').click( function(e) {
    closeZoom();
});




// SUCHFUNKTIONEN
$('.ui.search')
  .search({
    type             : 'category',
    minCharacters    : 3,
    searchDelay      : 400,
    selectFirstResult: 'true',
    apiSettings      : {
      url        : 'php/search.php?query={query}&lang={lang}',
      beforeSend: function (settings) {
            var lang = document.documentElement.lang;
            settings.urlData.lang = lang;
            return settings;
        },
      onResponse : function(output) {
        // Teste Output
        // console.log("output", output);

        // lege Liste für bearbeitete Ergebnisse an
        var response = {
            results : {}
          };

        // keine Ergebnisse
        if(!output || !output.items) {
          return;
        }

        // bearbeite Ergebnisse
        $.each(output.items, function(index, item) {

          // maximale Anzahl
          var maxResults = 1000;
          if(index >= maxResults) {
            return false;
          }

          // erhalte benötigte Variablenwerte
          var name          = item.name;
          var meta          = Number(item.level);
          var fsize         = parseFloat(fsize);
          var phylum        = item.phylum;
          var tax           = localizedNameForMeta(meta);

          var tax_level     = tax['path'];           // Pfad für den Link
          var tax_level_txt = tax['locale'];         // Beschriftung (kann internationalisiert werden)

          var optimizedLink = optimizeLink(meta, item.fsize, util.textWidth(item.name), item.x, item.y);
          var viewZoom = optimizedLink["viewZoom"];   // bester ViewZoom aus aktueller Perspektive
          var coord_x  = optimizedLink["x"];          // ggf. verschobene Koordinaten
          var coord_y  = optimizedLink["y"];
          var autoZoomLogic = optimizedLink["logic"];


          // neue Kategorie für jede taxonische Rangstufe
          if(response.results[tax_level] === undefined) {
            response.results[tax_level] = {
              name    : tax_level_txt,
              results : []
            };
          }

          // found_as bearbeiten
          var found_as = " ";
          var lang = item.lang;
          if (item.found_as.length > 0) {
            if(lang == "syn") {
              found_as = "<div class='foundAs'><i>" + $(`#search_scientific`).text() + "</i> " + item.found_as + "</div>";
            } else {
              found_as = "<div class='foundAs'><i>" + $(`#search_alternateName`).text() + "</i> " + item.found_as + "</div>";
            }

          }




          // Phylum bearbeiten
          // -> zu .eq(0) siehe https://stackoverflow.com/a/31306861

          var phylum_txt  = "";
          var phylum_url = "";
          var phylum_elem = $("#phylum_template").find(".phylum_lbl").eq(0).clone();

          if (phylum) {
            phylum_elem.find(".searchSub_phylumText").eq(0).html(`<i>${phylum}</i>`);
            phylum_txt = phylum_elem.prop("outerHTML");
            phylum_url = phylum + "-";
            // phylum_txt = "<i>" + $(`#search_phylum`).text() + "</i> " + phylum;
          }

          // ggf. Trennung zwischen Vernakulär- und Fundnamen
          var separator = "";
          if(item.name_cmn && found_as.length > 0) {
            separator = "<br>";
          }

          // URL mit Phylum
          var url = phylum_url + name.replace(/\s/g, "_").replace("?", "").replace("#","").replace("!","").replace("@","");


          // Beschreibung graphisch zusammenstellen


          /*
          	let searchSub_elem = $("#searchSub_template").find(".searchSub").eq(0).clone();
              searchSub_elem.find(".searchSub_foundAs").html(found_as);
              searchSub_elem.find(".searchSub_phylum" ).html(phylum_txt);

          // console.log("searchSub_elem", searchSub_elem.html());

          let searchSub_txt = searchSub_elem.prop("outerHTML");


          //    searchSub.find("#searchSub_foundAs").html(found_as);
          //    searchSub.find("#search_phylum").html(phylum_txt);
          */


          // Ergebnis zur jeweiligen Rangstufe hinzufügen
          response.results[tax_level].results.push({
            title       : item.name,
            description : `${item.name_cmn} ${separator} ${found_as}`,
            url         : `#${tax_level}-${viewZoom}-${coord_x}-${coord_y}@${url},zoom=${autoZoomLogic},view=photo!`,
            price       : phylum_txt,
          });
        });

        return response;
      },
      onFailure: function(response) {
        console.log("Search failed", response);

        // request failed, or valid response but response.success = false
      },
    },
      error : {
      source      : 'Cannot search. No source used, and Semantic API module was not included',
      noResults   : $("#search_noResults").text(),
      logging     : 'Error in debug logging, exiting.',
      noTemplate  : 'A valid template name was not specified.',
      serverError : $("#search_serverError").text(),
      maxResults  : 'Results must be an array to use maxResults setting',
      method      : 'The method you called is not defined.'
    },
  })
;

/** Hinweise zur Suche
     https://github.com/Semantic-Org/Semantic-UI/issues/3938
     https://stackoverflow.com/questions/38363272/semantic-ui-search-module-does-not-display-json-response
     https://stackoverflow.com/questions/33123033/semantic-ui-search-not-populating-with-ajax-data
     https://stackoverflow.com/questions/29664553/create-an-ajax-call-to-auto-complete-a-search-in-semantic-ui
     https://semantic-ui.com/modules/search.html
     https://semantic-ui.com/behaviors/api.html#modifying-response-json
     https://stackoverflow.com/questions/28782521/how-can-you-modify-the-results-in-semantic-ui-search-module
      hier unten ist es gut erklärt -->
      https://github.com/Semantic-Org/Semantic-UI/issues/1998
**/

// EXPERIMENTELL: FUNKTIONEN DER LEERTASTE
// $('#map').focus();
// -> https://stackoverflow.com/questions/55064633/how-is-javascript-on-method-defined

/*
var keyPressed = false;   // Event nur einmal auslösen (nicht dauerhaft, wenn Taste gedrückt)
map.getTargetElement().addEventListener('keydown', function(evt){
  if(!keyPressed && evt.keyCode == 32) {
    // console.log("Leertaste gedrückt");
    keyPressed = true;
    main.toggleZoom();
    }
//  } else if(evt.keyCode == 17) {

});

map.getTargetElement().addEventListener('keyup', function(evt){
  if(keyPressed && evt.keyCode == 32) {
    // console.log("Leertaste gelöst");
    keyPressed = false;
    main.toggleZoom();
  }
});
*/


/* ALTERNATIVE:
$('#map').on('keydown', function(e) {
  console.log("keydown", e);
  console.info("keydown", e.which);
});
*/

/*
map.on(['singleclick'], function(e) {

    var featureCount = 0;
    map.forEachFeatureAtPixel(e.pixel, function(feature) {
        featureCount++;
          console.log("Durch Klick ausgewählt:", feature.getProperties()['name']);
          $('#selection_text').text("ausgewählt: " + feature.getProperties()['name']);
          $('#selection_text').show()
          $('#selection_text').delay(2000).fadeOut(500);
          // selected_features = feature.getProperties()['name'];                        // derzeit nur jeweils ein Feature (da immer nur eines angeklickt werden kann)
          // console.log("main selected features:", selected_features);
          // vectorLayer_selection.changed();

          // LADE AUSGEWÄHLTES POLYGON AUS DATENBANK
          // DIESER ANSATZ SOLLTE GEWÄHLT WERDEN, SOBALD POLYGONE IN DATENBANK VORHANDEN
          /*

          var featureInfo = feature.getProperties();

          $.ajax({
            type: "POST",
            url: "php/poly.php",
            data: { name: featureInfo['name'] },
            success: function(output) {

              var result = JSON.parse(output);


             var global_metaZoom = metaZoom.current;
             if(new_metaZoom['level'] == global_metaZoom['level']) {
                vectorSource_lbl.addFeatures(features);
              }
            },
            error: function(error) {
              console.log("Error in fetching feature information.")
            }
          });
          */

/*
    }, {
        layerFilter: function (layer) {
            return layer === vectorLayer_tiles;
        }
    })
/*
    if(featureCount == 0) {                         // vorher angeklicktes Feature löschen, wenn kein Feature in Auswahl
      selected_features = "";
      vectorLayer_selection.changed();
    }
  */
// })

// Zoomlabels initialisieren
function initZoomLabels() {
  // console.log("init zoom labels");
  var slider_positions = zoomslider.data;   // wichtig, dass diese Abfrage nach map:postrender erfolgt, zu diesem Zeitpunkt gibt es globale Daten über den Zoomslider (für den Autozoom)
    for(let k = 0; k < slider_positions.length; k++) {
      let position = slider_positions[k];
      let baseline = position['stop'] - position['start'];
      let current_meta = position['meta'];
      let current_path = util.getPathForMeta(current_meta);
      let current_flexbox = '#flex_' + current_path;
      $(current_flexbox).css('flex-basis', `${baseline*100}%`);
      // console.log(position);
      // console.log(current_meta);
      // console.log(current_path);
      // console.log("current",current_flexbox);
  }
}

function initZoomButtons() {
  check_buttonAuto();
}


async function updatePButtons() {
  let tileExtent = findTileExtent();
  // let meta = util.getMetaForPath(tileExtent["meta"]);

  let pButtons   = await perspectives.updateButtons(TILE_SERVER_PX, tileExtent);


  // Alle Buttons zunächst ausblenden
  $('.customButtonP').css("display", "none");
  let btn_class      = "#btn_prsp_";

  // Buttons der allgemeinen Ansicht einblenden
  let generalButtons = pButtons["generalButtons"];
  for(let i = 0; i < generalButtons.length; i++) {
      let curr_button = btn_class + generalButtons[i]["name"];
      $(curr_button).css("display", "inline-block");
  }

  // Buttons der Artansicht
  let speciesButtons = pButtons["speciesButtons"];
  if(speciesButtons.length < 1) {
    $('.hud-margin-Pspecies').css("display", "none");
  } else {
    $('.hud-margin-Pspecies').css("display", "inline-block");
  }
  for(let j = 0; j < speciesButtons.length; j++) {
      let curr_button = btn_class + speciesButtons[j]["name"];
      $(curr_button).css("display", "inline-block");
  }


}

/* Perspektive bei Klick wechseln */
$('.customButtonP').click(function() {
  let btn_id      = this.id;
  let perspective = btn_id.replace("btn_prsp_","");
  console.log("btn_id", btn_id, "perspective", perspective);

  main.changePerspective(perspective);
});



function updateBtn_meta() {
  var meta       = map.get("meta");
  var btn_name   = meta['btn_name'];
  var btn_colour = meta['btn_colour'];

  $('#' + btn_name).prop("checked", true);             // Button auswählen
  $(':root').css('--btn_colour_zoom', btn_colour);     // Zoomsliderfarbe in CSS setzen
}

// Aktualisiere Markierung der Buttons: Automatischer Zoom (mit Untermodi) & fixierter Zoom
function updateBtn_logic() {
  var logic = map.get("logic");
  var logic_name = util.findLogicName(logic, zoomLogics);

  if(logic_name.includes("auto")) {
    let auto_mode = "norm";
    if(logic_name.includes("min")) {
      auto_mode = "min";
    } else if(logic_name.includes("max")) {
      auto_mode = "max";
    }
    check_buttonAuto(auto_mode);
  } else {
    check_buttonFixed();
  }
}



// BUTTONS
function changeZoomByMeta(new_meta)   {
  var curr_meta  = map.get("meta")['level'];
  var curr_logic = map.get("logic");
  var uniqueMetas = util.getUniqueMetas(curr_logic);
  var newResolution;


  // neuer Metazoom ist in derzeitiger Logik enthalten
  if(uniqueMetas.includes(new_meta)) {
    var viewZoomRange = util.getViewZoomForMeta(new_meta, curr_logic);
    var newViewZoom;

    // bearbeite Autozoom
    if(curr_meta < new_meta) {                      // wenn von links kommend, gehe zu niedrigstem Zoom auf neuer Metaebene
      newViewZoom = viewZoomRange['range_min'];
      map.getView().setZoom(newViewZoom);
    } else if(curr_meta > new_meta) {               // wenn von rechts kommend, gehe zu höchstem Zoom auf neuer Metaebene
      newViewZoom = viewZoomRange['range_max'];
      map.getView().setZoom(newViewZoom);
    }

    // bearbeite Fall, dass neuer metaZoom = alter metaZoom (springe in Autozoom)
    else if(uniqueMetas.length == 1) {  // von Übersichtsebene in Autozoom wechseln
      var newLogicWithOptions = util.findLogic('auto', zoomLogics);
      main.changeLogic(newLogicWithOptions);
    } else {                    // von Autozoom in Übersichtsebene wechseln
      var newLogicWithOptions = util.findLogic('detail_' + new_meta, zoomLogics);
      main.changeLogic(newLogicWithOptions);
    }

  // Bearbeitung der Übersichtsebenen
  } else {
      var currentZoom = map.getView().getZoom();
      var newLogicWithOptions = util.findLogic('detail_' + new_meta, zoomLogics);

      var minZoom = newLogicWithOptions['minZoom'];
      var maxZoom = newLogicWithOptions['maxZoom'];

      if(currentZoom < minZoom) {
        resetToHome();                      // stellt sicher, dass Rückkehr in die oberste Zoomstufe möglich ist (sonst Fehler, wenn Klick bei Stufe, auf der keine Phylumebene definiert)
        main.changeLogic(newLogicWithOptions);
      } else if(currentZoom > maxZoom) {
        resetToHome();                      // stellt sicher, dass Rückkehr in die oberste Zoomstufe möglich ist (sonst Fehler, wenn Klick bei Stufe, auf der keine Phylumebene definiert)
        main.changeLogic(newLogicWithOptions);
        map.getView().setZoom(maxZoom);
      } else {
        main.changeLogic(newLogicWithOptions);
      }

  }

  main.updateMeta();    // ggf. erneuere Metazoom
}

// Klick auf Zoombuttons
$("#btn_domain").click(function() {
  changeZoomByMeta(1);
});

$("#btn_phylum").click(function() {
  changeZoomByMeta(2);
});

$("#btn_class").click(function() {
  changeZoomByMeta(3);
});

$("#btn_order").click(function() {
  changeZoomByMeta(4);
});

$("#btn_family").click(function() {
  changeZoomByMeta(5);
});

$("#btn_genus").click(function() {
  changeZoomByMeta(6);
});

$("#btn_species").click(function() {
  changeZoomByMeta(7);
});

/**
$("#btn_species").click(function() {
  var newLogicWithOptions = util.findLogic('detail_7', zoomLogics);
  main.changeLogic(newLogicWithOptions);
  // $('#map').focus();
});
**/

/* Klick auf Buttons für Zoommodi */

// Automatischer Zoom (Standard)
$("#btn_auto").click(function() {
  var newLogicWithOptions = util.findLogic('auto', zoomLogics);
  main.changeLogic(newLogicWithOptions);

  // Markierung der Semantic-UI-Buttons
  check_buttonAuto("norm");
});

// Automatischer Zoom: groß
$("#btn_auto_min").click(function() {
  var newLogicWithOptions = util.findLogic('auto_min', zoomLogics);
  main.changeLogic(newLogicWithOptions);

  // Markierung der Semantic-UI-Buttons
  check_buttonAuto("min");
});

// Automatischer Zoom: normal
$("#btn_auto_norm").click(function() {
  var newLogicWithOptions = util.findLogic('auto', zoomLogics);
  main.changeLogic(newLogicWithOptions);

  // Markierung der Semantic-UI-Buttons
  check_buttonAuto();
});

// Automatischer Zoom: normal
$("#btn_auto_max").click(function() {
  var newLogicWithOptions = util.findLogic('auto_max', zoomLogics);
  main.changeLogic(newLogicWithOptions);

  // Markierung der Semantic-UI-Buttons
  check_buttonAuto("max");
});


// Fixierter Zoom
$("#btn_fix").click(function() {
  var meta = map.get("meta")["level"];
  if(meta >= util.minGlobalMeta() && meta <= util.maxGlobalMeta()) {
  var meta_text = parseInt(meta).toString();
    var newLogicWithOptions = util.findLogic('detail_'+meta_text, zoomLogics);
    main.changeLogic(newLogicWithOptions);

    // Markierung der Semantic-UI-Buttons
    check_buttonFixed();

  // Falls kein valider Metazoom, wechsle zu automatischem Modus
  } else {
    $("#btn_auto").click();
  }
  // $('#map').focus();
});




/* Änderung der Kartenperspektive */
$("#btn_defPv" ).click(function() {
  main.changePerspective("Standard");

  // Markierung der Semantic-UI-Buttons
  check_button_PvDefault();
});

$("#btn_geoPv" ).click(function() {
  main.changePerspective("Karte");

  // Markierung der Semantic-UI-Buttons
  check_button_PvGeo();
});


// Auswahl der UI-Sprache
$("#btn_geoPv" ).click(function() {
  main.changePerspective("Karte");

  // Markierung der Semantic-UI-Buttons
  check_button_PvGeo();
});

$("#btn_geoPv" ).click(function() {
  main.changePerspective("Karte");

  // Markierung der Semantic-UI-Buttons
  check_button_PvGeo();
});

// Sprachauswahl
var link_en = $("#href_en").html();
var link_de = $("#href_de").html();

$("#btn_lang_en").click(function() {
  var hash = window.location.hash;
  window.location = link_en + hash;
});

$("#btn_lang_de").click(function() {
  var hash = window.location.hash;
  window.location = link_de + hash;
});

$("#link_lang_en").click(function() {
  var hash = window.location.hash;
  window.location = link_en + hash;
});

$("#link_lang_de").click(function() {
  var hash = window.location.hash;
  window.location = link_de + hash;
});


// Funktionelle Markierung der Buttons
function check_buttonAuto(mode) {
  // Unsichtbaren Radiobutton ändern
  $('#btn_auto_radio').prop("checked", true);

  // Schaltfläche für Autozoom-Modus in Semantic UI markieren
  if(!$("#btn_auto_design").hasClass("active")) {
     $("#btn_auto_design").addClass("active");
  }
  if($("#btn_fix_design").hasClass("active")) {
     $("#btn_fix_design").removeClass("active");
  }

  // Schaltfläche für Ausprägung des Autozoom-Modus markieren
  // Min-Schaltfläche
  if(mode=="min") {
    if(!$("#btn_auto_min").hasClass("active")) {
       $("#btn_auto_min").addClass("active");
    }
    if($("#btn_auto_norm").hasClass("active")) {
       $("#btn_auto_norm").removeClass("active");
    }
    if($("#btn_auto_max").hasClass("active")) {
       $("#btn_auto_max").removeClass("active");
    }

  // Max-Schaltfläche
  } else if(mode=="max") {
    if(!$("#btn_auto_max").hasClass("active")) {
       $("#btn_auto_max").addClass("active");
    }
    if($("#btn_auto_min").hasClass("active")) {
       $("#btn_auto_min").removeClass("active");
    }
    if($("#btn_auto_norm").hasClass("active")) {
       $("#btn_auto_norm").removeClass("active");
    }

  // Mittlere Schaltfläche (default)
  } else {
    if(!$("#btn_auto_norm").hasClass("active")) {
       $("#btn_auto_norm").addClass("active");
    }
    if($("#btn_auto_min").hasClass("active")) {
       $("#btn_auto_min").removeClass("active");
    }
    if($("#btn_auto_max").hasClass("active")) {
       $("#btn_auto_max").removeClass("active");
    }



  }
}

function check_buttonFixed() {
  // Radiobutton ändern
  $('#btn_fix_radio').prop("checked", true);

  // Button in Semantic UI markieren
  if($("#btn_auto_design").hasClass("active")) {
    $("#btn_auto_design").removeClass("active");
  }
  if(!$("#btn_fix_design").hasClass("active")) {
      $("#btn_fix_design").addClass("active");
  }
  if($("#btn_auto_min").hasClass("active")) {
     $("#btn_auto_min").removeClass("active");
  }
  if($("#btn_auto_norm").hasClass("active")) {
     $("#btn_auto_norm").removeClass("active");
  }
  if($("#btn_auto_max").hasClass("active")) {
     $("#btn_auto_max").removeClass("active");
  }
}

function check_button_PvDefault() {
  // Radiobutton ändern
  $('#btn_defaultPv_radio').prop("checked", true);

  // Button in Semantic UI markieren
  if($("#btn_geoPv_design").hasClass("active")) {
    $("#btn_geoPv_design").removeClass("active");
  }
  if(!$("#btn_defaultPv_design").hasClass("active")) {
      $("#btn_defaultPv_design").addClass("active");
  }
}

function check_button_PvGeo() {
  // Radiobutton ändern
  $('#btn_btn_geoPv_radio').prop("checked", true);

  // Button in Semantic UI markieren
  if($("#btn_defaultPv_design").hasClass("active")) {
     $("#btn_defaultPv_design").removeClass("active");
  }
  if(!$("#btn_geoPv_design").hasClass("active")) {
      $("#btn_geoPv_design").addClass("active");
  }
}



/**
 * Zurücksetzen auf Autozoom und Startzoom
 */
function resetToHome() {
  var newLogicWithOptions = util.findLogic('auto', zoomLogics);
  main.changeLogic(newLogicWithOptions);
  map.getView().setZoom(startZoom);
  map.getView().setCenter([def.contentExtent[2]/2, def.contentExtent[1]/2]);
}



/**
 * TOUREN
 */

// Auswählbare Touren finden
let allTours = tour.findAllTours(document.documentElement.lang);
// console.log("allTours", allTours);

// Dropdown initialisieren
$('.tourDropdown').dropdown({
  values: allTours,
  direction: 'downward',
  duration: 250,
  /* showOnFocus: false, */
  on: 'hover',
  action: function(text, value) {
    tour.open();
    tour.takeTour(value, document.documentElement.lang);
  },
  onShow: function() {
  //  alert("dropdown show");
  },
  onHide: function() {
  //  alert("dropdown hide");
  }
});

$('#tour_next').click( function() {
  tour.nextStep();
});

$('#tour_prev').click( function() {
  tour.prevStep();
});

$('#tour_close').click( function() {
  tour.close();
});

$('#tour_autoplay').click( function() {
  tour.toggleAutoplay();
});







/**
 * KLICKFUNKTIONEN FÜR DIE KARTE
 */

// Tabs im Overlay ändern
/**
 * oberhalb der Artebene: Äußere Phylogenie (Tab 2) | Innere Phylogenie (Tab 1) |  Taxonomische Klassifikation (Tab 3)
 * in der Artebene:       Äußere Phylogenie (Tab 2) | Synonyme (Tab 1)          |  Taxonomische Klassifikation (Tab 3)
 */
function updateOverlay_tabs() {
  let meta = map.get("meta")["level"];
  let activeTab = findActiveTab();

  if(meta < 7) {
    // Wechsel zwischen 4 und 1
    if(activeTab == 4) {
      $("#tab_1").click();
      /* besser als: $.tab('change tab', 'tab_4');   --> https://semantic-ui.com/modules/tab.html#/usage
         weil dadurch der aktive Tab zwar geöffnet, aber optisch nicht aktiviert (Klasse "active") wird
         */
    }

    // Sichtbarkeit der Tabs
    $("#tab_4").css("display", "none");
    $("#tab_1").css("display", "flex");


  } else {
    // Wechsel zwischen 1 und 4
    if(activeTab == 1) {
      $("#tab_4").click();
    }

    // Sichtbarkeit der Tabs
    $("#tab_4").css("display", "flex");
    $("#tab_1").css("display", "none");
  }
}





// Globale Variablen für Phylogenien
// innere Phylogenie
var tree1 = "";                   // aus Newick errechneter Baum
var rendered_tree1 = undefined;   // svg
var newick1 = {tax: "", cmn: "", lit: "", error: ""};

// äußere Phylogenie
var tree2 = "";                    // aus Newick errechneter Baum
var rendered_tree2 = undefined;   // svg
var newick2 = {tax: "", cmn: "", lit: "", error: ""};

// Größe beider Bäume
var tree_steps = 0;

// aktuell geladene ID und Popup-Link
var globId = "";
var globalAt = "";

// statische Texte
var loader      = "<div class='ui active mini inline loader'></div>";
var loaderLarge = "<div class='ui active loader'></div>";
var error_noData = $('#tree_noData').text();
var error_outer  = $('#mono_outer').text();
var error_inner  = $('#mono_inner').text();


// Subfamilien
var subFam_outer = [2365415,2451253,2451518,2451570,2451646,2452066,2452835,2452845,2452969,2468146,2468803,2478638,2451109,2417649,2365420,2365438,2375867,2375947,2377486,2390755,2391109,2393114,2393268,2417096,2417610,2478975,2479900,2480499,2566223,2566228,2566629,2566848,2566960,2567007,2573133,2574337,2576690,2576695,2576699,2566011,2563772,2488166,2488173,2489123,2491033,2492249,2492474,2555377,2555451,2559873,2560250,2563565,2576770,2365388,480170,623379,623438,623461,623467,623760,623828,623833,623836,2123764,2123919,2129653,623115,523709,505387,505398,516919,516922,517096,522424,522428,522584,522641,523646,523706,2132305,2138197,2139141,2157428,2157431,2157455,2159493,2159518,2159582,2159585,2159666,2159991,2160443,2160814,2156817,2156812,2141637,2141992,2142009,2142809,2143424,2146238,2147109,2153847,2153906,2156252,2156281,2160906];
var subFam_inner = [480159, 2116960, 2373163, 2462985];



// Tabs initialisieren
$('.menu .item').tab();

function findActiveTab() {
  if($("#tab_phylo").hasClass("active")) {
    return 1;
  } else if($("#tab_phylo2").hasClass("active")) {
    return 2;
  } else if($("#tab_taxo").hasClass("active")) {
    return 3;
  } else if($("#tab_syno").hasClass("active")) {
    return 4;
  } else {
    return 0;
  }
}

$('#tabSelector').click( function() {
  renderTree_auto();
  overlay.panIntoView();
});

// INFORMATIONEN IN DAS POP-UP LADEN
async function fillOverlay(e) {

  // OVERLAY VORBEREITEN: WARTEN BIS NAME GELADEN
  // Schließe Overlay, falls Link aus vorherigem Overlay offen
  // die Links werden eingeklappt, damit man immer auch die Bildquelle sieht
  if($('#footer_permaLink').css("visibility") == "visible") {
      closeOverlay();   // bester optischer Eindruck
  }                     // nur falls nötig, da ohne Schließen noch minimal hübscher

  // Aktuelle Klickstelle finden
  var result = await featureOnPosition(e);
  var taxonId   = result.getId();
  var taxonName = result.get("name");
  var taxonMeta = result.get("meta");
  var taxonMeta_locale = localizedNameForMeta(taxonMeta)["locale"];

  // Debugging: Coleoptera
  // taxonId = 2114005;
  // taxonName = "Coleoptera";

  // Debugging: Asteroidea


  // Abbrechen, wenn außerhalb des klickbaren Bereichs
  if(!taxonId) {
    overlay.setPosition(undefined);
    $('#popup').css("visibility", "hidden"); // eventuell geöffnete Overlays schließen
    return;
  } else {
    globId = taxonId;
  }

  // Globale Variablen zurücksetzen
  tree1 = "";
  rendered_tree1 = "";
  newick1 = {tax: "", cmn: "", lit: "", error: ""};
  tree2 = "";
  rendered_tree2 = "";
  newick2 = {tax: "", cmn: "", lit: "", error: ""};

  // Text zurücksetzen
  $('#popup-titleA').html(taxonMeta_locale);  // vorläufiger Text
  $('#popup-titleB-black').html(taxonName);
  $('#popup-titleB-grey').html("");
  $('#popup-titleC').html("");
  $('#inputContainer').val("");
  $("#imgSource").html("");

  $('#above_tree1').css("visibility", "hidden");  // Flexbox-Elemente können nicht mit .hide() und .show() behandelt werden
  $('#outer_tax1').html("");
  $('#inner_tax1').html("");
  $('#tree1').html(loaderLarge);
  $('#below_tree1').hide();

  $('#above_tree2').css("visibility", "hidden");  // Flexbox-Elemente können nicht mit .hide() und .show() behandelt werden
  $('#outer_tax2').html("");
  $('#inner_tax2').html("");
  $('#tree2').html(loaderLarge);
  $('#below_tree2').hide();

  $("#taxonomy").html(loaderLarge);
  $("#stats_childrenLabel").html("");
  $("#stats_speciesLabel").html("");
  $("#stats_childrenNo").html("");
  $("#stats_speciesNo").html("");


  // POPUP SICHTBAR POSITIONIEREN
  $('#popup').css("visibility", "visible");
  overlay.setPosition(e.coordinate);

  // taxonId = 119400;     // Test auf Artebene mit Galanthus nivalis

  // Vollname und Vernakulärname laden
  var result = await loadNames(taxonId);
  var shortName  = result["name"];
  var fullName   = result["name_full"];
  var commonName = result["name_cmn"];
  var prevId     = parseInt(result["prev_id"]);
  var phylum     = result["phylum"];
  var child_no   = parseInt(result["child_no"]);
  var sibling_no = parseInt(result["sibling_no"]);
  var species_no = parseInt(result["species_no"]);
  var phylum     = result["phylum"];
  var parent_name = result["parent_name"];

  // Hotfix für Domänenebene (oberhalb dieser Ebene gibt es noch Hilfsebenen in der Datenbank, es soll aber auf Life verlinkt werden)
  // dies könnte auch in der Datenbank direkt geändert werden (Unikonta etc. müssten dann in der Tabelle "taxonomy" direkt auf Life verlinken)
  // ich finde die aktuelle Datenbankstruktur aber recht informativ
  if(taxonMeta == 1) {
    prevId = 0;
    parent_name = "Life";
    sibling_no = 4;       // Archaea, Bacteria, Unikonta, Bikonta
  }

  // Link mit Phylum erstellen
  var phylum_link;
  if(!phylum) {
    phylum_link = "";
  } else {
    phylum_link = phylum + "-";
  }
  var at_link = (phylum_link + shortName).replace(/\s/g, "_").replace("?", "").replace("#","").replace("!","").replace("@","");

  // [TODO] ??: linkObj = link.silentAt(at_link);

  // Hash ändern
  var defaultURL = "https://lifegate.idiv.de/"
  var linkObj    = defaultURL + "#@" + at_link;

  var name_part1 = shortName;
  var name_part2 = util.stringIntersection(shortName, fullName);

  // Name zu Popup hinzufügen, falls noch aktuell
  if(globId != taxonId) {
    return;
  } else {
    $('#popup-titleB-black').html(name_part1);
    $('#popup-titleB-grey').html(" " + name_part2);


    $('#popup-titleC').html(commonName);
    $('#inputContainer').val(linkObj);
  }


  // BILDQUELLEN FINDEN
  var perspective = map.get("perspective");   // keine Bildautoren für Karte und Schema
  if(perspective != "Karte" && perspective != "range" && perspective != "scheme") {
    perspective = "def";      // [TODO]: einheitliche Benennung Karte/range
                              // [TODO]: prüfen, ob Bildautoren korrekt für Spezialansichten
    loadImageSource(taxonId, perspective).then(async function(result) {

      // Abbrechen, wenn leeres Ergebnis
      if(result.length < 1) {
        return;
      }


      let author = result["author"];
      let link   = result["link"];
      let flag   = result["flag"];
      let title = $("#source_img").text();

      let flag_txt = "";
      if(flag == "p") {
        flag_txt = "<i>(" + $("#source_private").text()  + ")</i>";
      } else if(flag == "i") {
        flag_txt = "<i>(" + $("#source_inactive").text() + ")</i>";
      }

      let img_txt = "";
      if(author.length > 1) {
        if(link.length > 1) {  // https://freecodecamp.org/news/how-to-use-html-to-open-link-in-new-tab/ (in neuem Tab öffnen, noreferrer um Phishing zu verhindern)
          img_txt = `${title}: <a href=${link} target="_blank" rel="noopener noreferrer">${author}</a> ${flag_txt}`;
        } else {
          img_txt = `${title}: ${author} ${flag_txt}`;
        }
      }

      if(globId != taxonId) {
        return;
      } else {
        // Bildquelle zu Popup hinzufügen, falls noch aktuell
        $("#imgSource").html(img_txt);
      }

    }).catch((error) => {
      console.log("Error when trying to fetch image source:", error);
    });
  }

  // SYNONYME FINDEN
  if(taxonMeta >= 7) {    // nur in der Artebene
    loadSynonyms(taxonId).then(async function(result) {

      if(globId != taxonId) {
        return;
      }
      $("#syno_list").html("");


      var list_text = "";
      let length = result.length;
      for(let i = 0; i < length; i++) {
        var template_black  = $('#syno_black').clone();    // notwendig, damit das Template nicht jedes mal verändert wird
        var template_gray   = $('#syno_gray').clone();        // dadurch ist addClass("...") für jedes Einzelelement wirksam

        let curr_nameShort    = result[i]["syno_short"];
        let curr_nameFull     = result[i]["syno"] ;

        let curr_txtBlack    = curr_nameShort;
        let curr_txtGray     = util.stringIntersection(curr_nameShort, curr_nameFull);

        let curr_templateBlack = template_black.html(curr_txtBlack);
        let curr_templateGray  = template_gray.html(curr_txtGray);
            list_text = list_text + "<li>" + curr_templateBlack.prop("outerHTML") + curr_templateGray.prop("outerHTML") + "</li>";

      }

      if(list_text == "") {
        list_text= "—";
      } else {
        list_text = "<ul>" + list_text + "</ul>";
      }

      // Zeige Synonymliste, sofern noch aktuell
      if(globId != taxonId) {
        return;
      }
      $("#syno_list").append(list_text);

      // wenn Tab geöffnet, führe nach Laden Panning durch
      let activeTab = findActiveTab();
      if(activeTab == 4) {
         overlay.panIntoView();
       }

    })
}

// INNERE PHYLOGENIE ZEICHNEN
if(taxonMeta < 7) {  // nur oberhalb der Artebene

  // Taxon hat keine Nachfolger
  if(child_no <= 0) {
    newick1["tax"] = "";
    newick1["cmn"] = "";
    newick1["error"] = error_noData;

    // Entferne Baum, falls noch aktuell
    if(globId != taxonId) {
      return;
    }
    removeTree_inner();

  // Taxon hat einen Nachfolger (kein Baum in der Datenbank)
  } else if(child_no == 1) {
    newick1["tax"] = "";
    newick1["cmn"] = "";

    // Nachfolger finden
    findDescendants(taxonId).then(async function(result) {

      if(result.length < 1) {
        newick1["error"] = error_noData;
      } else {
        let descendant = result[0]["name"];
        newick1["error"] = error_inner + " " + descendant;
      }

      // Zeige Baum, falls noch aktuell
      if(globId != taxonId) {
        return;
      }
      removeTree_inner();   // zeigt Fehlermeldung (inkl. Kindtaxon) an

    }).catch((error) => {

      if(globId != taxonId) {
        return;
      }
      newick1["error"] = error_noData;
      console.log("Error when trying to find descendants:", error);
      removeTree_inner();   // zeigt Fehlermeldung an
    });



// Taxon hat zwei Nachfolger (kein Baum in der Datenbank, kann aber erstellt werden)
} else if (child_no == 2) {

    // Nachfolger finden
    findDescendants(taxonId).then(async function(result) {

      if(result.length < 2) {
        newick1["error"] = error_noData;
      } else {

        // Einfachen Baum erstellen
        let taxon0 = result[0];
        let taxon1 = result[1];
        let resultNewick        = simple2Newick(taxon0, taxon1);
            newick1["name"]     = shortName;
            newick1["name_cmn"] = commonName;
            newick1["target"]   = shortName;
            newick1["tax"]      = "(" + resultNewick["tree"]  + ")";;
            newick1["cmn"]      = "(" + resultNewick["tree2"] + ")";;
            newick1["error"]    = "";
            newick1["flag"]     = "";

        // Baum verorten, wenn Daten verfügbar
        let outerMeta = taxonMeta;
        let innerMeta = taxonMeta+1;

        // Ausnahmebehandlung für Unterfamilien
        if(outerMeta == 5 && subFam_inner.includes(parseInt(taxonId))) {
          innerMeta = 5.2;
        }

        if(globId != taxonId) {
          return;
        }
        locateTree_inner(outerMeta, innerMeta, taxonMeta);


        // Phylogenie erstmalig zeichnen
        let activeTab = findActiveTab();
        if(activeTab == 1 && globId == taxonId) {    // Zeichne Baum nur, wenn Tab aktiv
         await renderTree_inner(newick1);
         overlay.panIntoView();
       }

      }
    });
/*
    }).catch((error) => {
      newick1["error"] = error_noData;
      console.log("Error when trying to find descendants:", error);
    });
*/

  // Taxon hat mehr als zwei Nachfolger (Baum sollte in der Datenbank sein)
  } else {
  loadNewick(taxonId, shortName, phylum, taxonMeta).then(async function(result) {
    let flag = "";

    // Zu zeichnende Newick-Datei finden
    if(result["tree"]) {
      flag = result["flag"];  // Ausnahmebehandlung für Zwischenebenen

      newick1["tax"]      = "(" + result["tree"]  + ")";   // Außenlinie anhängen
      newick1["cmn"]      = "(" + result["tree2"] + ")";   // Außenlinie anhängen
      newick1["name"]     = result["name"];
      newick1["name_cmn"] = result["name_cmn"];
      newick1["target"]   = shortName;                    // Taxon im Fokus
      newick1["error"]    = "";                           // kein Fehler
      // console.log("newick", newick);
    } else {
      newick1["tax"] = "";
      newick1["cmn"] = "";
      newick1["error"] = error_noData;
    }

    // Literaturquelle für aktuelles Newick
    if(result["lit"]) {
      newick1["lit"] = bibliography(result["lit"], ";");
    } else {
      newick1["lit"] = "";
    };

    // Phylogenie erstmalig zeichnen
    let activeTab = findActiveTab();
     if(newick1["tax"].length > 2) {

       // Baum verorten, wenn Daten verfügbar
       let outerMeta = taxonMeta;
       let innerMeta = taxonMeta+1;

       // Ausnahmebehandlung für Unterfamilien
       if(outerMeta == 5 && subFam_inner.includes(parseInt(taxonId))) {
         innerMeta = 5.2;
       }
       if(globId != taxonId) {
         return;
       }
      locateTree_inner(outerMeta, innerMeta);

       /* frühere (komplizierte) Ausnahmebehandlung
       let intermediate = findIntermediateLevels(outerMeta, innerMeta, "inner", flag);
           outerMeta = intermediate["outerMeta"];
           innerMeta = intermediate["innerMeta"];
      */


      // Zeichne Baum nur, wenn Tab aktiv
      if(activeTab == 1 && globId == taxonId) {
       await renderTree_inner(newick1);
       overlay.panIntoView();
     }
   // Wenn keine Daten über Baum verfügbar, zeige Fehlermeldung
   } else {
     if(globId != taxonId) {
       return;
     }
     removeTree_inner();
   }

   }).catch((error) => {
     if(globId != taxonId) {
       return;
     }
     newick1["error"] = error_noData;
     removeTree_inner();
     console.log("Error when trying to render inner newick:", error);
   });
 }
}


  // ÄUSSERE PHYLOGENIE ZEICHNEN
  // Anzahl Geschwister nicht bekannt
  if(!parent_name || sibling_no == 0) {
    newick1["tax"] = "";
    newick1["cmn"] = "";
    newick1["error"] = error_noData;
    removeTree_outer();   // zeigt Fehlermeldung an

  // Taxon hat keine Adelphotaxa (kein Baum in der Datenbank)
  } else if(sibling_no == 1) {
    newick2["tax"] = "";
    newick2["cmn"] = "";
    newick2["error"] = error_outer + " " + parent_name;
    removeTree_outer(); // zeigt Fehlermeldung (inkl. Elterntaxon) an

  // Taxon hat ein Adelphotaxon (kein Baum in der Datenbank, kann aber erstellt werden)
} else if(sibling_no == 2) {

    // Geschwister finden
    findDescendants(prevId).then(async function(result) {

      if(result.length < 2) {
        newick2["error"] = error_noData;
        removeTree_outer();
      } else {

        // Einfachen Baum erstellen
        let taxon0 = result[0];
        let taxon1 = result[1];
        let resultNewick        = simple2Newick(taxon0, taxon1);
            newick2["name"]     = parent_name;
            newick2["name_cmn"] = parent_name;
            newick2["target"]   = shortName;
            newick2["tax"]      = "(" + resultNewick["tree"]  + ")";;
            newick2["cmn"]      = "(" + resultNewick["tree2"] + ")";;
            newick2["error"]    = "";

        // Baum verorten, wenn Daten verfügbar
        let outerMeta = taxonMeta-1;
        let innerMeta = taxonMeta;

        // Ausnahmebehandlung für Unterfamilien
        if(innerMeta == 6 && subFam_outer.includes(paseInt(prevId))) {
          outerMeta = 5.2;
        }
        locateTree_outer(outerMeta, innerMeta);

        // Phylogenie erstmalig zeichnen
        let activeTab = findActiveTab();
        if(activeTab == 2) {    // Zeichne Baum nur, wenn Tab aktiv
         await renderTree_outer(newick2);
         overlay.panIntoView();
       }

      }
    });

  // Taxon hat mehrere Geschwister (Baum sollte in der Datenbank sein)
  } else {
  loadNewick(prevId, parent_name, phylum, taxonMeta-1).then(async function(result) {
    // console.log("newicks", result);
    // console.log("lit, tree", result["lit"], result["tree"]);

    // Zu zeichnende Newick-Datei finden
    let flag = "";
    if(result["tree"]) {
      flag = result["flag"];    // Ausnahmebehandlung für Zwischenebenen

      newick2["tax"]      = "(" + result["tree"]  + ")";   // Außenlinie anhängen
      newick2["cmn"]      = "(" + result["tree2"] + ")";   // Außenlinie anhängen
      newick2["name"]     = result["name"];
      newick2["name_cmn"] = result["name_cmn"];
      newick2["target"]   = shortName;                    // Taxon im Fokus
      newick2["error"]    = "";                           // kein Fehler
      // console.log("newick", newick);
    } else {
      newick2["tax"] = "";
      newick2["cmn"] = "";
      newick2["error"] = error_noData;
    }

    // Literaturquelle für aktuelles Newick
    if(result["lit"]) {
      newick2["lit"] = bibliography(result["lit"], ";");
    } else {
      newick2["lit"] = "";
    };

    // Phylogenie erstmalig zeichnen
    var activeTab = findActiveTab();
     if(newick2["tax"].length > 2) {

       // Baum verorten, wenn Daten verfügbar
       let outerMeta = taxonMeta-1;
       let innerMeta = taxonMeta;

       // Ausnahmebehandlung für Unterfamilien
       if(innerMeta == 6 && subFam_outer.includes(parseInt(prevId))) {
         outerMeta = 5.2;
       }
       if(globId != taxonId) {
         return;
       }
       locateTree_outer(outerMeta, innerMeta);

       /*
       let intermediate = findIntermediateLevels(outerMeta, innerMeta, "outer", flag);
           outerMeta = intermediate["outerMeta"];
           innerMeta = intermediate["innerMeta"];
       locateTree_outer(outerMeta, innerMeta);
       */

      // Zeichne Baum nur, wenn Tab aktiv
      if(activeTab == 2 && globId == taxonId) {
       await renderTree_outer(newick2);
       overlay.panIntoView();
     }
   // Wenn keine Daten über Baum verfügbar, zeige Fehlermeldung
   } else {
     if(globId != taxonId) {
       return;
     }
     removeTree_outer();
   }
 })
   .catch((error) => {
     if(globId != taxonId) {
       return;
     }
     newick1["error"] = error_noData;
     removeTree_outer();
     console.log("Error when trying to render outer newick:", error);
   });
 }

   // TAXONOMISCHE KLASSIFIKATION ZEICHNEN
   loadTaxonomy(taxonId).then(async function(result) {
     if(globId != taxonId) {
       return;
     }
     $("#taxonomy").html("");

     // Templates
     var template_top  = $('#taxoTemplate_top').clone();    // notwendig, damit das Template nicht jedes mal verändert wird
     var template_else = $('#taxoTemplate').clone();        // dadurch ist addClass("...") für jedes Einzelelement wirksam

     let length = result.length;
     for(let i = 0; i < length; i++) {
       let item = result[i];

       // erhalte benötigte Variablenwerte
       let name          = item["name"];
       let name_cmn      = item["name_cmn"];
       let meta          = item["level"];
       let tax           = localizedNameForMeta(meta);
       let tax_level     = tax['path'];
       let txt_tax       = tax['locale'];

       let optimizedLink, viewZoom, coord_x, coord_y, url_name, txt_url, autoZoomLogic;
       if(item["x"] && item["y"] && item["fsize"]) {
         optimizedLink = optimizeLink(meta, item["fsize"], util.textWidth(item["name"]), item["x"], item["y"]);
         viewZoom = optimizedLink["viewZoom"];   // bester ViewZoom aus aktueller Perspektive
         coord_x  = optimizedLink["x"];          // ggf. verschobene Koordinaten
         coord_y  = optimizedLink["y"];
         autoZoomLogic = optimizedLink["logic"];
         url_name = name.replace(/\s/g, "_").replace("?", "").replace("#","").replace("!","").replace("@","");
         txt_url  = `#${tax_level}-${viewZoom}-${coord_x}-${coord_y}@${url_name},zoom=${autoZoomLogic},view=photo!`;
       }

       // Domäne manuell bearbeiten
       if(meta == 1) {
         if(name == "Unikonta") { name = "Eukaryota/Unikonta"; }
         if(name == "Bikonta")  { name = "Eukaryota/Bikonta"; }
       }

       // Vorlage bearbeiten
       let txt_name;
       if(name_cmn.length > 1) {
         txt_name = `<b><i>${name}</i></b> (${name_cmn})`;
       } else {
         txt_name = `<b><i>${name}</i></b>`;
       }

       // Passende Vorlage auswählen (mit oder ohne Pfeil)
       let current_template;
       if(i == 0) {
         current_template = template_top;
       } else {
         current_template = template_else;
       }

       // Links hinzufügen
       if(i < length - 1) {
         if(txt_url !== undefined) {
           txt_name = `<a href='${txt_url}'>${txt_name}</a>`
          }

      // aktuelles Taxon hervorheben
       } else {
         current_template.find(".segment").addClass("secondary");
         current_template.find(".icon").addClass("red");
       }

       // Texte hinzufügen
       current_template.find(".taxonCategory_rankLabel").html(txt_tax);       // taxonomischen Rang befüllen
       current_template.find(".taxonCategory_taxonLabel").html(txt_name);     // Namen des Taxons anzeigen

       // Fertige Tabelle ins Popup laden
      $("#taxonomy").append(current_template.html());

      /* ------- */

      // Anzeige enthaltener Untertaxa
      let max_meta = result[length-1]["level"];
      let next_meta = parseInt(max_meta)+1;
      let next_meta_txt = "";
      let species_meta_txt = "";
      let next_meta_stats = "";
      let species_meta_stats = "";
      let lang =  document.documentElement.lang;

      if(next_meta <= 6) {
        next_meta_txt = localizedStatsForMeta(next_meta)["locale"] + ":";
        next_meta_stats = Number(child_no).toLocaleString(lang);
      }

      if(next_meta <= 7) {
        species_meta_txt = localizedStatsForMeta(7)["locale"] + ":";
        species_meta_stats = Number(species_no).toLocaleString(lang);
      }


      // Statistiken einblenden, wenn vorhanden
      if(!isNaN(Number(child_no))) {
        $("#stats_childrenLabel").html(next_meta_txt);
        $("#stats_speciesLabel").html(species_meta_txt);
      }

      if(!isNaN(Number(species_no))) {
        $("#stats_childrenNo").html(next_meta_stats);
        $("#stats_speciesNo").html(species_meta_stats);
      }


      // wenn Tab geöffnet, führe nach Laden Panning durch
      let activeTab = findActiveTab();
      if(activeTab == 3) {
         overlay.panIntoView();
       }

     }
   })
   .catch((error) => {
     if(globId != taxonId) {
       return;
     }
     $("#taxonomy").html("");
      console.log("Error when trying to load taxonomy", error);
    });
}

// 2er-Newicks selbst erstellen
function simple2Newick(taxon0, taxon1) {

  let tx_tax0 = taxon0["name"];
  let tx_tax1 = taxon1["name"];
  let tx_cmn0;
  let tx_cmn1;

  if(taxon0["name_cmn"].length > 1) {
    tx_cmn0 = `${taxon0.name_cmn}~${taxon0.name}`
  } else {
    tx_cmn0 = taxon0["name"];
  }

  if(taxon1["name_cmn"].length > 1) {
    tx_cmn1 = `${taxon1.name_cmn}~${taxon1.name}`
  } else {
    tx_cmn1 = taxon1["name"];
  }

  let resultNewick = { tree: "", tree2: "" };
      resultNewick["tree"]      = `('${tx_tax0}','${tx_tax1}')`;
      resultNewick["tree2"]      = `('${tx_cmn0}','${tx_cmn1}')`;

  return resultNewick;
}

// Bibliographie erstellen
function bibliography(text, separator) {
  let bibliography = "";
  let combination_text = $('#tree_litAssembly').text();

  let elems = text.split(separator);
  let elems_length = elems.length;
  for(let j=0; j < elems_length; j++) {
    bibliography = bibliography + "<li>" + String(elems[j]) + "</li>";
    // console.log("bibliography", j, bibliography, elems[j]);
  }

  bibliography = "– " + combination_text + " " + "<ul>" + bibliography + "</ul>";
  return bibliography;
}



// Inneren oder äußeren Zoom ggf. auf Zwischenebenen korrigieren
// [TODO]: Falls weitere Zwischenebenen dazu kommen, kann das einfach verallgemeinert werden
/*
function findIntermediateLevels(outerMeta, innerMeta, mode, flag) {

  let intermediateMeta;
  // metaZoom anpassen, wenn flag gesetzt
  if(flag == "subfamily") {
    intermediateMeta = 5.2;

    if(mode == "inner") {
      outerMeta = parseInt(Math.floor(intermediateMeta));
      innerMeta = intermediateMeta;
    } else if(mode == "outer") {
      outerMeta = intermediateMeta;
      innerMeta = parseInt(Math.ceil(intermediateMeta));
    }
  }

  // Ergebnis ausgeben
  let result = { outerMeta: outerMeta, innerMeta: innerMeta }
  return result;

}
*/

// taxonomische Ebenen über dem Baum verorten
function locateTree(outerMeta, innerMeta, phylogeny) {

  // Meta-Zoom in Sprache der Benutzeroberfläche
  let outerMeta_locale = "";
  let innerMeta_locale = "";


  if(innerMeta <= util.maxGlobalMeta()) {
    innerMeta_locale = localizedNameForMeta(innerMeta)['locale'];
  }
  if(outerMeta >= util.minGlobalMeta()) {
    outerMeta_locale = localizedNameForMeta(outerMeta)['locale'];
  }



  let container = 1;
  if(phylogeny == "outer") {
    container = 2;
  }

  let outer_text = "";
  let inner_text = "";

  if(outerMeta_locale != "") {
    outer_text = "◀ " + outerMeta_locale;
  }

  if(innerMeta_locale != "") {
    inner_text = innerMeta_locale + " ▶";
  }

  $('#outer_tax'+container).text(outer_text);
  $('#inner_tax'+container).text(inner_text);
}

const locateTree_outer = function(outerMeta_locale, innerMeta_locale) { return locateTree(outerMeta_locale, innerMeta_locale, "outer"); };
const locateTree_inner = function(outerMeta_locale, innerMeta_locale) { return locateTree(outerMeta_locale, innerMeta_locale, "inner"); };

async function renderTree(newick, phylogeny) {
    // Zu zeichnenden Baum festlegen
    var container_all = "#tree1";
    var container_svg = "#tree_container";
    var container_above ="#above_tree1";
    var container_below = "#below_tree1";
    var container_lit = "#lit1";

    if(phylogeny == "outer") {
      container_all = "#tree2";
      container_svg = "#tree2_container";
      container_above ="#above_tree2";
      container_below = "#below_tree2";
      container_lit = "#lit2";
    }

    // Optionen aus UI-Buttons finden
    var active_newick;
    var alignTips = false;
    var radial    = false;
    var mode      = "tax";
    var spacing = 'fit-to-size'
    var width;

    // Bereite Optionen vor und wähle Newick aus
    mode = treeMode();

    if(mode == "cmn") {
      $('.tree').css("overflow-x", "auto");
      active_newick = newick["cmn"];
      width = 450;
      spacing = 'fixed-size';
    } else {
      $('.tree').css("overflow-x", "hidden");
      active_newick = newick["tax"];
      width = 450;
      spacing = 'fit-to-size';
    }

    if($('.phyloBtn_justify').hasClass("active")) {
      alignTips = true;
    }

    /*
    if($('.phyloBtn_radial').hasClass("active")) {
      radial = true;
    }
    */

    // Baum zeichnen
    var current_tree         = new phylotree(active_newick);
    var current_renderedTree = current_tree.render({
                 container: container_svg,
                 'width': width,
                 'max-radius': 200,
                 'zoom': false,
                 'show-scale': false,
                 'left-offset': 10,
                 'align-tips': alignTips,
                 'selectable': false,
                 'collapsible': false,
                 'hide': false,
                 'brush': false,
                 'restricted-selectable': 'none',
                 'left-right-spacing': 'fit-to-size',
                 'draw-size-bubbles' : false
               });
    // current_renderedTree.steps = 0;

    // Globale Variablen aktualisieren
    if(phylogeny != "outer") {
      tree1          = current_tree;
      rendered_tree1 = current_renderedTree;
    } else {
      tree2          = current_tree;
      rendered_tree2 = current_renderedTree;
    }

    tree_steps = 0;


    // SVG ermitteln
    await util.sleep(150);           // versuche schwankende CSS-Breiten zu vermeiden
    var svg = current_renderedTree.show();

    // console.log("root_node", tree.getRootNode());
    /*
    if(radial) {
        rendered_tree.radial(true);
        rendered_tree.options['top-bottom-spacing'] = 'fit-to-size';
        rendered_tree.update()
    } else {
      rendered_tree.radial(false);
      rendered_tree.options['top-bottom-spacing'] = 'fixed-step';
      rendered_tree.update()
    }
    */

    // console.log("tree", tree);
    // console.log("rendered", rendered_tree);


   // console.log("svg", svg);

   // Elemente befüllen
   $(container_all).html(""); // Ladeanzeige entfernen
   $(container_all).append(svg);
   $(container_lit).html(newick["lit"]);
   $(container_above).css("visibility", "visible");
   $(container_below).show();


   // Formatiere Text nachträglich (für Modus mit Anzeige von Vernakulärnamen)
   // -> https://stackoverflow.com/questions/38130781/how-to-change-text-elements-in-d3
   if(phylogeny != "outer") {
     if(mode == "cmn") {
       restyleTree1_cmn(container_all, newick);
     } else {
       restyleTree1_tax(container_all, newick);
     }
  } else {
    if(mode == "cmn") {
      restyleTree2_cmn(container_all, newick);
    } else {
      restyleTree2_tax(container_all, newick);
    }
  }
}

const renderTree_outer = function(newick) { return renderTree(newick, "outer"); };
const renderTree_inner = function(newick) { return renderTree(newick, "inner"); };


function renderTree_auto() {
    let activeTab = findActiveTab();

    if(activeTab == 1) {
      if(newick1["tax"].length > 2) {
        removeTree_outer();
        renderTree_inner(newick1);
      } else {
        removeTree_inner();
      }
    } else if(activeTab == 2) {
      if(newick2["tax"].length > 2) {
        removeTree_inner();
        renderTree_outer(newick2);
      } else {
        removeTree_outer();
      }
    } else {
      removeTree_all();
    }
}


function removeTree_auto() {
  let activeTab = findActiveTab();

  if(activeTab != 1) {
    removeTree_inner();
  }
  if(activeTab != 2) {
    removeTree_outer();
  }
}

// Bäume entfernen
function removeTree_all() {
  tree_steps = 0;
  removeTree_inner();
  removeTree_outer();
}

function removeTree_inner() {
  tree1 = undefined;
  rendered_tree1 = undefined;

  $('#tree1').html("");    // vorherige Anzeige entfernen

  if(newick1["tax"].length > 2 && newick1["error"].length < 2) {
    $('#tree1').html(loaderLarge);                         // Ladeanzeige einblenden
  } else {
    let errorText = newick1["error"];
    $('#tree1').html("<i>" + errorText + "</i>");     // Fehlermeldung einblenden
  }

}

function removeTree_outer() {
  tree2 = undefined;
  rendered_tree2 = undefined;

  $('#tree2').html("");    // vorherige Anzeige entfernen

  if(newick2["tax"].length > 2 && newick2["error"].length < 2) {
    $('#tree2').html(loaderLarge);                     // Ladeanzeige einblenden
  } else {
    let errorText = newick2["error"];
    $('#tree2').html("<i>" + errorText + "</i>");     // Fehlermeldung einblenden
  }
}


// Phylogenie nachträglich für Vernakulärnamen umgestalten
function restyleTree1_cmn(container, newick) {
  let steps = tree_steps;
  let fsize = 12;

  if(steps < 0) {
    fsize = Math.max(4, fsize+steps/2);
  }
  let fsize_txt = fsize+"px";


  // SVG-Kreise entfernen
  d3.selectAll(container + " svg circle")
    .attr("r", 0); // Radius 0 setzen


  // alle SVG-Texte überprüfen
  d3.selectAll(container + " svg text").each(function(d, i) {
    var curr_text = d.data.name;
    var split = curr_text.split("~");
    var split_length = split.length;

    for(let j=0; j < split_length; j++) {
      // nur wissenschaftlicher Name
      if(split_length <= 1) {
        d3.select(this)
          .text(split[j])
          .attr("font-style", "italic")
          .attr("font-weight", 200)
          .style("font-size", fsize_txt);
      }

      // Vernakulär und wissenschaftlicher Name
      else if(j == 0) {
        d3.select(this)
         .text(split[j])
         .attr("font-weight", 700)
         .style("font-size", fsize_txt)
       } else {
         d3.select(this)
           .append("tspan")
           .text( " (" + split[j] + ")")
           .attr("font-style", "italic")
           .attr("font-weight", 200)
           .style("font-size", fsize_txt)
       }
    }
  });

if(newick["name_cmn"].length > 1) {
  d3.select(".root-node").append("text")
    .attr("text-anchor", "start")
    .attr("transform", "translate(-20,-5)")
    .style("font-size", fsize_txt)
    .style("fill", "darkred")
    .style("stroke", "#fff")
    .style("stroke-width", "3px")
    .style("paint-order", "stroke")
    .text(newick["name_cmn"])

  } else {
    d3.select(".root-node").append("text")
      .attr("text-anchor", "start")
      .attr("transform", "translate(-20,-5)")
      .style("font-size", fsize_txt)
      .style("fill", "darkred")
      .style("stroke", "#fff")
      .style("stroke-width", "3px")
      .style("paint-order", "stroke")
      .text(newick["name"])
      .attr("font-weight", 200)
      .attr("font-style", "italic")
      .raise()
  }
}

function restyleTree1_tax(container, newick) {
  let steps = tree_steps;
  let fsize = 12;

  if(steps < 0) {
    fsize = Math.max(4, fsize+steps/2);
  }
  let fsize_txt = fsize+"px";

  // SVG-Kreise entfernen
  d3.selectAll(container + " svg circle")
    .attr("r", 0); // Radius 0 setzen

  // Text zu Wurzel hinzufügen
  d3.select(".root-node").append("text")
    .attr("text-anchor", "start")
    .attr("transform", "translate(-20,-5)")
    .style("font-size", fsize_txt)
    .style("fill", "darkred")
    .style("stroke", "#fff")
    .style("stroke-width", "3px")
    .style("paint-order", "stroke")
    .text(newick["name"])
    .raise()
  }

function restyleTree2_cmn(container, newick) {
    let steps = tree_steps;
    let fsize = 12;

    if(steps < 0) {
      fsize = Math.max(4, fsize+steps/2);
    }
    let fsize_txt = fsize+"px";

    // SVG-Kreise entfernen
    d3.selectAll(container + " svg circle")
      .attr("r", 0); // Radius 0 setzen

    // Alle Texte untersuchen
    d3.selectAll(container + " svg text").each(function(d, i) {
      var curr_text = d.data.name;
      var split = curr_text.split("~");
      var split_length = split.length;
      var color;


      for(let j=0; j < split_length; j++) {

        // Ausgewähltes Taxon einfärben
        color = "black";  // herausfinden, wo wissenschaftlicher Name steht
        if(split_length <= 1 && split[0].trim() == newick["target"] ||
            split_length > 1 && split[1].trim() == newick["target"]) {
          color = "darkred";
        }
        // console.log("restyle2", split[0].trim(), newick["target"].trim(), color)

        // nur wissenschaftlicher Name
        if(split_length <= 1) {
          d3.select(this)
            .text(split[j])
            .attr("font-style", "italic")
            .attr("font-weight", 200)
            .style("fill", color)
            .style("font-size", fsize_txt);
        }

        // Vernakulär- und wissenschaftlicher Name
        else if(j == 0) {
          d3.select(this)
           .text(split[j])
           .attr("font-weight", 700)
           .style("font-size", fsize_txt)
           .style("fill", color)
         } else {
           d3.select(this)
             .append("tspan")
             .text( " (" + split[j] + ")")
             .attr("font-style", "italic")
             .attr("font-weight", 200)
             .style("font-size", fsize_txt)
             .style("fill", color)
         }
      }
    });

  if(newick["name_cmn"].length > 1) {
    d3.select(".root-node").append("text")
      .attr("text-anchor", "start")
      .attr("transform", "translate(-20,-5)")
      .style("font-size", fsize_txt)
      .style("fill", "black")
      .style("stroke", "#fff")
      .style("stroke-width", "3px")
      .style("paint-order", "stroke")
      .text(newick["name_cmn"])

    } else {
      d3.select(".root-node").append("text")
        .attr("text-anchor", "start")
        .attr("transform", "translate(-20,-5)")
        .style("font-size", fsize_txt)
        .style("fill", "black")
        .style("stroke", "#fff")
        .style("stroke-width", "3px")
        .style("paint-order", "stroke")
        .text(newick["name"])
        .attr("font-weight", 200)
        .attr("font-style", "italic")
        .raise()
    }
  }


function restyleTree2_tax(container, newick) {
    let steps = tree_steps;
    let fsize = 12;

    if(steps < 0) {
      fsize = Math.max(4, fsize+steps/2);
    }
    let fsize_txt = fsize+"px";

    // SVG-Kreise entfernen
    d3.selectAll(container + " svg circle")
      .attr("r", 0); // Radius 0 setzen


      d3.selectAll(container + " svg text").each(function(d, i) {
        var curr_text = d.data.name;
        var split = curr_text.split("~");
        var split_length = split.length;
        var color, fweight;


        for(let j=0; j < split_length; j++) {
          // Ausgewähltes Taxon einfärben

          color = "black";
          fweight = 200;
          if(curr_text.trim() == newick["target"].trim()) {
            color = "darkred";
            fweight = 700;
          }
        // console.log("curr_text", curr_text.trim(), newick["name"].trim(), color);

          // nur wissenschaftlicher Name
          d3.select(this)
              .text(curr_text)
              .attr("font-weight", fweight)
              .style("fill", color)
              .style("font-size", fsize_txt);
        }

        d3.select(".root-node").append("text")
          .attr("text-anchor", "start")
          .attr("transform", "translate(-20,-5)")
          .style("font-size", fsize_txt)
          .style("fill", "black")
          .style("stroke", "#fff")
          .style("stroke-width", "3px")
          .style("paint-order", "stroke")
          .text(newick["name"])
          .raise()
    });
}

function treeMode() {
  let mode;
  if($('.phyloBtn_cmn').hasClass("active")) {
    mode = "cmn";
  } else {
    mode = "tax";
  }
  return mode;
}


// OPTIONEN FÜR PHYLOGENIEN
// Blattknoten ausrichten
$('.phyloBtn_justify').click( function() {
  if(!$('.phyloBtn_justify').hasClass("active")) {
    $(".phyloBtn_left").removeClass("active");
    $(".phyloBtn_justify").addClass("active");

    renderTree_auto();
    // overlay.panIntoView();
  }
});


// Blattknoten abgestuft
$('.phyloBtn_left').click( function() {
  if(!$('.phyloBtn_left').hasClass("active")) {
    $(".phyloBtn_justify").removeClass("active");
    $(".phyloBtn_left").addClass("active");

    renderTree_auto();
    // overlay.panIntoView();
  }
});


// Phylogenie mit Vernakulärnamen anzeigen
$('.phyloBtn_cmn').click(async function() {

  if(!$('.phyloBtn_cmn').hasClass("active")) {
    $(".phyloBtn_tax").removeClass("active");
    $(".phyloBtn_cmn").addClass("active");
  }

  renderTree_auto();
  // overlay.panIntoView();

});

// Phylogenie ohne Vernakulärnamen anzeigen
$('.phyloBtn_tax').click(async function() {

  if(!$('.phyloBtn_tax').hasClass("active")) {
    $(".phyloBtn_cmn").removeClass("active");
    $(".phyloBtn_tax").addClass("active");
  }

  renderTree_auto();
  // overlay.panIntoView();

});

$(".phyloBtn_enlarge").on ("click", function (e) {
  let steps = 2;

  let tree, rendered_tree;
  let horizontal;
  let newick;
  let container;

  let activeTab = findActiveTab();
  if(activeTab == 1) {
    tree = tree1;
    newick = newick1;
    container = "#tree1";
  } else if(activeTab == 2) {
    tree = tree2;
    newick = newick2;
    container = "#tree2";
  } else {
    return;
  }

  horizontal = tree.display.spacing_x.bind(tree.display);
  tree_steps = tree_steps + steps;
  horizontal(horizontal() + steps).update();

    let mode = treeMode();
    if(mode == "cmn") {
      restyleTree1_cmn(container, newick);
    } else {
      restyleTree1_tax(container, newick);
    }
});


$(".phyloBtn_reduce").on ("click", function (e) {
  let steps = 2;

  let tree, rendered_tree;
  let horizontal;
  let newick;
  let container;

  let activeTab = findActiveTab();
  if(activeTab == 1) {
    tree = tree1;
    newick = newick1;
    container = "#tree1";
  } else if(activeTab == 2) {
    tree = tree2;
    newick = newick2;
    container = "#tree2";
  } else {
    return;
  }

  horizontal = tree.display.spacing_x.bind(tree.display);
  tree_steps = tree_steps - steps;
  horizontal(horizontal() - steps).update();

    let mode = treeMode();
    if(mode == "cmn") {
      restyleTree1_cmn(container, newick);
    } else {
      restyleTree1_tax(container, newick);
    }
});

// Bild speichern
$(".phyloBtn_save").on ("click", function (e) {

      let container;
      let activeTab = findActiveTab();
      if(activeTab == 1) {
        container = "#tree1";
      } else if(activeTab == 2) {
        container = "#tree2";
      }

     phylo.datamonkey_save_image('svg', container);
   });

// Literatur
$('#phyloBtn_lit1')
  .popup({
    popup: '#lit_container1',
    hoverable: true,
    position: 'bottom-left'
  });


$('#phyloBtn_lit2')
    .popup({
      popup: '#lit_container2',
      hoverable: true,
      position: 'bottom-left'
  });




// [TODO]: weiter für benutzerdefinierte Popups
// Tooltips (test)
$('#container_autoZoomMode')
  .popup({
    popup: '#test_popup',
    hoverable: true,
    distanceAway: 10,
    lastResort: 'bottom center'
  });





// Tooltips der Nutzeroberfläche
  $('#resetButton')
    .popup({
      distanceAway: 10
      });

  $('#btn_auto_design')
      .popup({
        distanceAway: 10
      });

  $('#btn_fix_design')
    .popup({
        distanceAway: 10
      });




  $('#hud-help')
    .popup({
        popup: '#help_container',
        hoverable: true,
        position: 'right center',
        forcePosition: true
    });


  $('#hud-layers')
    .popup({
      on: "manual"
    });

  $('#hud-layers').mouseenter(function() {
    if(!$('#layer_options').is(':visible')) {
      $('#hud-layers').popup('show');
    }
  });

  $('#hud-layers').mouseleave(function() {
      $('#hud-layers').popup('hide');
  });

  $('#hud-layers').click(function() {
    $('#hud-layers').popup('hide');
  });


  // Buttons (+/-) werden von Openlayers angelegt, müssen daher hier beschriftet werden
  var plus_txt = $('#tooltip_plus').html();
  var minus_txt  = $('#tooltip_minus').html();

  $('.ol-zoom-out')
    .popup({
      content: minus_txt,
      variation: "inverted small"
    });

  $('.ol-zoom-in')
    .popup({
      content: plus_txt,
      variation: "inverted small"
    });



// Link abrufen
$('#linkBtn').click(function() {
  if($('#footer_permaLink').css("visibility") == "hidden") {
    expandLink();
  } else {
    collapseLink();
  }
});

function expandLink() {
    $('#imgSource').css("visibility", "hidden");
    $("#footer_imgSource").addClass("flexRemove");
    $('#footer_permaLink').css("visibility", "visible");
    $('.footer_linkBtn').addClass("flexBorder");
    var textBox = document.getElementById("inputContainer");
    textBox.select();
}

function collapseLink() {
  $('#footer_permaLink').css("visibility", "hidden");
  $("#footer_imgSource").removeClass("flexRemove");
  $('#imgSource').css("visibility", "visible");
  $('.footer_linkBtn').removeClass("flexBorder");
}


$('#copyToClipboard').click(function() {
  // -> https://stackoverflow.com/a/48643203
  var textBox = document.getElementById("inputContainer");
  textBox.select();
  document.execCommand("copy");
});




/*
$('#footer_linkBtn').click(async function() {

  if(!$('.phyloBtn_tax').hasClass("active")) {
    $(".phyloBtn_cmn").removeClass("active");
    $(".phyloBtn_tax").addClass("active");
  }

  if(newick["tax"].length > 1) {
    await renderTree(newick);
    overlay.panIntoView();
  } else {
    removeTree();
  }
*/

// Lineare Darstellung
/*
$('.phyloBtn_linear').click( function() {
  if(!$('.phyloBtn_linear').hasClass("active")) {
    $(".phyloBtn_radial").removeClass("active");
    $(".phyloBtn_linear").addClass("active");

    if(rendered_tree !== undefined) {
      rendered_tree.options['top-bottom-spacing'] = 'fixed-step';
      rendered_tree.options['left-right-spacing'] = 'fit-to-size';
      rendered_tree.radial(false);
      rendered_tree.update()
    }
  }
});

// Radiäre Darstellung
$('.phyloBtn_radial').click( function() {
  if(!$('.phyloBtn_radial').hasClass("active")) {
    $(".phyloBtn_linear").removeClass("active");
    $(".phyloBtn_radial").addClass("active");

    if(rendered_tree !== undefined) {
      rendered_tree.options['top-bottom-spacing'] = 'fit-to-size';
      rendered_tree.options['left-right-spacing'] = 'fit-to-size';
      rendered_tree.radial(true);
      rendered_tree.update()
    }
  }
});
*/




// verhindere Fehlermeldung bei Klick auf Knoten
$('#tree1').on("click", function(e) {
  e.preventDefault();
  e.stopPropagation();
});


// DATENBANKABFRAGEN
// hilfreiche Erklärung zu Promises in Ajax-Abfragen
// -> https://taniarascia.com/how-to-promisify-an-ajax-call/
function loadNames(taxonId) {
  return new Promise((resolve, reject) => {
    var lang = document.documentElement.lang;

    $.ajax({
      type: "POST",
      url: "php/names.php",
      data: { id: taxonId, lang: lang },

      success: function(output) {
        // console.log("output in names", output);
        var result = JSON.parse(output);
        resolve(result);

      },
      error: function(error) {
        reject(error);
      }
    })
  })
}


function loadNewick(taxonId, taxonName, taxonPhylum, rootMeta) {
  let lang = document.documentElement.lang;
  let phylum_root   = "";
  let phylum_branch = "";

  let phylum_level = util.getMetaForPath("phylum");
  if(rootMeta == phylum_level) {
    phylum_branch = taxonName;
  } else if(rootMeta > phylum_level) {
    phylum_root = taxonPhylum;
    phylum_branch = taxonPhylum;
  }

  // phylum_root wird gar nicht gebraucht (eindeutig durch id in newick)
  // aber für die Anschaulichkeit (oben) ganz nett

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/newick.php",
      data: { id: taxonId, lang: lang, phylum_root: phylum_root, phylum_branch: phylum_branch },

      success: function(output) {
        // console.log("output in newick", output);
        let result = JSON.parse(output.toString('utf8'));
        resolve(result);

      },
      error: function(error) {
        reject(error);
      }
    })
  })
}


function loadTaxonomy(taxonId) {
  var lang = document.documentElement.lang;

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/path.php",
      data: { id: taxonId, lang: lang },

      success: function(output) {
        // console.log("output in taxonomy", output);
        var result = JSON.parse(output.toString('utf8'));
        resolve(result);
      },

      error: function(error) {
        reject(error);
      }
    })
  })
}

function findDescendants(taxonId) {
  var lang = document.documentElement.lang;

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/descendants.php",
      data: { id: taxonId, lang: lang },

      success: function(output) {
        // console.log("output in descendants", output);
        var result = JSON.parse(output.toString('utf8'));
        resolve(result);
      },

      error: function(error) {
        reject(error);
      }
    })
  })
}


function loadImageSource(taxonId, perspective) {

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/imageSource.php",
      data: { id: taxonId, perspective: perspective },

      success: function(output) {
        // console.log("output in imageSource", output);
        var result = JSON.parse(output.toString('utf8'));
        resolve(result);

      },
      error: function(error) {
        reject(error);
      }
    })
  })
}

function loadSynonyms(taxonId) {

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "php/synonyms.php",
      data: { id: taxonId },

      success: function(output) {
        // console.log("output in synonyms", output);
        var result = JSON.parse(output.toString('utf8'));
        resolve(result);

      },
      error: function(error) {
        reject(error);
      }
    })
  })
}


/*
 * Finde in UI-Sprache übersetzten Namen für Metazoom. (Könnte auch in util, aber da von index.html abhängig, besser hier).
 */
function localizedNameForMeta(meta) {
  var tax_level     = util.getPathForMeta(meta) || 'unknown';           // Pfad für den Link
  var tax_level_txt = $(`#txt_${tax_level}`).text();
  return {'path': tax_level, 'locale': tax_level_txt };
}


/*
 * Finde in UI-Sprache übersetzten Namen für Statistiken eines Metazooms.
 */
function localizedStatsForMeta(meta) {
  var stats_level     = util.getPathForMeta(meta) || 'unknown';           // Pfad für den Link
  var stats_level_txt = $(`#stats_${stats_level}`).text();
  return {'path': stats_level, 'locale': stats_level_txt };
}
